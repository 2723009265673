import {Injectable, PLATFORM_ID} from "@angular/core";
import {ScriptStore} from "@app/scripts/script.store";
import {isPlatformServer} from "@angular/common";
import {Capacitor} from "@capacitor/core";
import {AppTrackingTransparency} from "capacitor-plugin-app-tracking-transparency";

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => {
        promises.push(this.loadScript(script));
      }
    );
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {

      if (!isPlatformServer(PLATFORM_ID)) {
        if (document.getElementById(name)) {
          resolve({script: name, loaded: true, status: 'Already Loaded'});
        } else {
          let script = document.createElement('script')
          script.setAttribute('src', this.scripts[name].src)
          script.setAttribute('type', 'text/javascript')
          script.setAttribute('id', name)

          if (name === 'maps') {
            script.setAttribute('defer', true)
          }

          try {
            document.getElementsByTagName('head')[0].appendChild(script);
          } catch ($error) {
            console.error($error);
            this.scripts[name].loaded = false;
          }
        }
      }

    });
  }

  loadBaseScript() {
    this.load('lottie', 'maps').then().catch(error => console.log(error));
  }

  loadAnalyticsScript() {
    if (JSON.parse(localStorage.getItem('cookiePreferences'))) {

      if (Capacitor.getPlatform() === 'ios') {

        AppTrackingTransparency.getStatus().then(async response => {

          if (response.status === 'authorized') {
            this.load('tag_manager_script').then().catch(error => console.log(error));

          } else if (response.status !== 'denied') {

            const response = await AppTrackingTransparency.requestPermission();
            if (response.status === 'authorized') {
              this.load('tag_manager_script').then().catch(error => console.log(error));
            }

          }
        });
      } else {

        this.load('tag_manager_script').then().catch(error => console.log(error));

      }
    }
  }


}
