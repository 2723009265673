import {Component, OnInit} from '@angular/core';
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {FooterService} from "@app/services/footer.service";

@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public footerExtraInfoArray: { title: string, link: string }[];

  constructor(
    public navigationRoute: NavigationRouteService,
    private footerService: FooterService
  ) {
  }

  ngOnInit() {
    this.footerService.getFooterExtraInfo().subscribe({
      next: extraInfo => {
        this.footerExtraInfoArray = extraInfo
      }
    })
  }

  eraseCookie() {
    localStorage.removeItem('cookiePreferences')
  }
}
