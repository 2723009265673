import {Component, Input} from '@angular/core';

@Component({
  selector: 'fit-spinner-light',
  template: '<lottie-player src="/assets/lottie/loading-light.json" [style.width]="size" style.margin="0 auto" autoplay loop></lottie-player>',
  styles: [''],
})
export class FitSpinnerLightComponent {

  @Input() size: string = '50px';

  constructor() {
  }

}
