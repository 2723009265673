import {Component, OnInit} from '@angular/core';
import {BannerService} from "@app/services/banner.service";
import {BannerModel} from "@app/models/banner.model";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'wide-partner-component',
  templateUrl: './wide-partner.component.html',
  styleUrls: ['./wide-partner.component.scss'],
})
export class WidePartnerComponent implements OnInit {

  public config = {
    spaceBetween: 30,
    slidesPerGroup: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 1,
    /*loop: true,*/
    /*breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },*/
  }
  public bannerArray: BannerModel[];
  public isOpen: number[] = [];

  constructor(
    private bannerService: BannerService,
    public modalController: ModalController
  ) {
  }

  ngOnInit() {
    this.bannerService.getBanner('partner', 'wide', 'pop-up').subscribe({
      next: bannerArray => {
        this.bannerArray = bannerArray
      }
    })
  }

  setIsOpen(isOpen: boolean, id) {
    if (isOpen) {
      this.isOpen.push(id)
    } else {
      this.isOpen.splice(this.isOpen.indexOf(id), 1)
    }
  }
}
