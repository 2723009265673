import {Component, Input, OnInit} from '@angular/core';
import {FileModel} from "@app/models/file.model";
import {ImageModel} from "@app/models/image.model";
import getVideoId from 'get-video-id';

@Component({
  selector: 'app-fit-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {

  @Input() imageArray: ImageModel[];
  @Input() videoArray: FileModel[];
  @Input() name: string;

  public isImageModal: boolean = false;
  public imageOpened: ImageModel;

  constructor() {
  }

  ngOnInit() {
    /*if (this.videoArray?.length > 0) {
      this.videoArray.forEach(video => {
        video.name = video.name.replace('/watch?v=', '/embed/')
      })
    }*/
  }

  setImageOpen(isOpen: boolean, image?: ImageModel) {
    this.isImageModal = isOpen;
    this.imageOpened = image;
    if (!isOpen) {
      this.imageOpened = null;
    }
  }

  getVideoId(URL: string) {
    return getVideoId(URL).id;
  }


}
