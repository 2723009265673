import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {RecommendationModel} from "@app/models/recommendation.model";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  getLatestRecommendation(sort, limit, page): Observable<RecommendationModel[]> {
    return this.http.get(API_URL + '/latest_rec', this.globalService.getParams({
      sort, limit, page
    })).pipe(map((data: RecommendationModel[]) => {
      return data
    }));
  }

  /*getLatestPromo(lat, lng, radius, sort, limit, page): Observable<any> {
    return this.http.get(API_URL + '/latest_promo', this.globalService.getParams({
      lat,
      lng,
      radius,
      sort,
      limit,
      page
    })).pipe(map(data => {
      return data;
    }));
  }*/


}
