import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'review-average-component',
  template: `
    <span class="my-auto" itemprop="aggregateRating" itemscope
          Itemtype="https://schema.org/AggregateRating">
                    <b class="mr-2 ion-color-medium size-23">
                      <span itemprop="ratingValue">{{average_vote}}</span>/<span
                      itemprop="reviewCount">5</span>
                      <span *ngIf="average_vote === 5">
                        Perfetto
                      </span>
                      <span
                        *ngIf="average_vote >= 4 && average_vote < 5">
                        Ottimo
                      </span>
                      <span
                        *ngIf="average_vote >= 2 && average_vote < 4">
                        Buono
                      </span>
                      <span
                        *ngIf="average_vote >= 1 && average_vote < 2">
                        Discreto
                      </span>
                      <span
                        *ngIf="average_vote >= 0 && average_vote < 1">
                        Scadente
                      </span>
                    </b>
                  </span>
  `,
  styleUrls: ['./review-average.component.scss'],
})
export class ReviewAverageComponent {

  constructor() {
  }

  @Input() average_vote: number;


}
