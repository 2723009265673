import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() moreInfo: boolean = false;
  @Input() more_info_text: string;
  @Input() formArray: FormArray;
  @Input() formInputName: string;
  @Input() name: string = 'Nessun nome';
  @Input() nameGender: 'M' | 'F' = 'M';
  @Input() required: boolean = false;
  @Input() inputType = 'text';
  @Input() radioDirection = 'horizontal';
  @Input() radioOptions = [];
  @Input() isLableVisible: boolean = true;
  @Input() placeHolder: string;
  @Input() readOnly: boolean = false;

  //PASSWORD
  public passwordFieldType: 'password' | 'text' = 'password';
  public passwordRepeatFieldType: 'password' | 'text' = 'password';

  //AUTOCOMPLETE
  @ViewChild('addresstext') addresstext: any;

  @Output() emittData: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  autocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        fields: ["formatted_address", "geometry", "address_component"],
        componentRestrictions: {country: 'IT'},
        strictBounds: false,
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.emittData.emit(place);
    });
  }

  changePasswordFieldVisible() {
    if (this.passwordFieldType === "password") {
      this.passwordFieldType = "text";
    } else {
      this.passwordFieldType = "password";
    }
  }

  changePasswordRepeatFieldVisible() {
    if (this.passwordRepeatFieldType === "password") {
      this.passwordRepeatFieldType = "text";
    } else {
      this.passwordRepeatFieldType = "password";
    }
  }

  protected readonly JSON = JSON;
}
