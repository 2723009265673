import {Injectable, Input, NgZone} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {StorageService} from "@app/helper/storage.service";
import {MenuController, ModalController, Platform} from "@ionic/angular";
import {PagesPage} from "@app/pages/pages.page";
import {API_URL} from '@environments/environment';
import {CommunicationService} from "@app/helper/communication.service";
import {UserService} from "@app/services/user.service";
import {GlobalService} from "@app/services/global.service";
import {FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {OperatorPanelService} from "@app/services/operator-panel.service";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import {Capacitor} from '@capacitor/core';
import {UserModel} from "@app/models/user.model";
import {FacebookLogin, FacebookLoginResponse,} from '@capacitor-community/facebook-login';

@Injectable({providedIn: 'root'})
export class AuthService {

  @Input() pages: PagesPage
  name = null;
  token = null;
  email = null;
  username: string;
  /* AUTH */
  private user = new BehaviorSubject(null);
  user_obs = this.user.asObservable();
  /* USER DATA */
  private userInfo: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    public storage: StorageService,
    private menuController: MenuController,
    private communicationService: CommunicationService,
    private ngZone: NgZone,
    private modalCtrl: ModalController,
    private userService: UserService,
    private globalService: GlobalService,
    private operatorPanelService: OperatorPanelService,
    private platform: Platform
  ) {
    if (Capacitor.isNativePlatform()) {
      GoogleAuth.initialize();
    }
    let storedUser = localStorage.getItem('userInfo');
    if (storedUser) {
      this.setUserStorage(JSON.parse(localStorage.getItem('userInfo')), false);
    }
  }

  public get getToken() {
    const user = this.user.value;
    return user?.token
  }

  setUserStorage(userData, storeUser: boolean = false) {
    if (storeUser) {
      localStorage.setItem('userInfo', JSON.stringify(userData));
    }
    this.user.next(userData);
  }

  invalidateToken() {
    const refresh_token = this.getToken
    return this.http.post(API_URL + '/login_invalidate', {
      refresh_token
    }).pipe(map(data => {
      this.handleAuthentication(data);
    }));
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(API_URL + '/login_check', {
      email,
      password
    }).pipe(map(user => {
      this.handleAuthentication(user);
      return user
    }));
  }

  sign_up(name: string, email: string, password: string, rec_token: string, will_be_operator: boolean): Observable<any> {
    return this.http.post(API_URL + '/sign_up', {
      name,
      email,
      password,
      rec_token,
      will_be_operator
    }).pipe(map(user => {
      /*
            this.handleAuthentication(user);
      */
      return user;
    }));
  }

  sign_up_social(token: string, social: string) {
    return this.http
      .post<any>(
        API_URL + '/sign_up_social',
        {
          token,
          social
        }
      )
      .pipe(
        tap(res => {
          this.handleAuthentication(res);
        })
      );
  }

  async logout() {
    this.router.navigate([''], {replaceUrl: true}).then(() => {
      this.handleLogout();
    });
  }

  async logoutSamePage() {
    this.handleLogout();
  }

  async facebookLogin() {

    if (Capacitor.getPlatform() === 'web') {

      const auth = getAuth();
      auth.useDeviceLanguage();

      let provider = new FacebookAuthProvider();
      provider.addScope('public_profile');
      provider.addScope('email');
      provider.setCustomParameters({
        'display': 'popup'
      });

      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = FacebookAuthProvider.credentialFromResult(result);
          this.sign_up_social(credential.accessToken, 'facebook').subscribe({
              next: result => {
                this.handleSocialAuthentication(result);
              }, error: err => {
                this.communicationService.errorToast(err).then(() => {
                  console.warn(err)
                });
              }
            }
          )
        })
        .catch(async (error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const credential = FacebookAuthProvider.credentialFromError(error);

          if (error.code === 'auth/account-exists-with-different-credential') {
            this.sign_up_social(credential.accessToken, 'facebook').subscribe({
                next: result => {
                  this.handleSocialAuthentication(result);
                }, error: err => {
                  this.communicationService.errorToast(err).then(() => {
                    console.warn(err)
                  });
                }
              }
            )

          }
        });


    } else {

      const FACEBOOK_PERMISSIONS = [
        'email',
        'public_profile'
      ];
      const result = (<FacebookLoginResponse><unknown>(
        await FacebookLogin.login({permissions: FACEBOOK_PERMISSIONS})
      ));

      if (result.accessToken) {
        // Login successful.
        this.sign_up_social(result.accessToken.token, 'facebook').subscribe({
            next: result => {
              this.handleSocialAuthentication(result);
            }, error: err => {
              this.communicationService.errorToast(err).then(() => {
                console.warn(err)
              });
            }
          }
        )
      }
    }
  }

  async googleLogin() {
    if (Capacitor.getPlatform() === 'web') {
      const auth = getAuth();
      let provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      provider.setCustomParameters({
        prompt: 'select_account' // Per far scegliere ad ogni login l'account
      });

      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          let credential = GoogleAuthProvider.credentialFromResult(result);
          this.sign_up_social(credential.idToken, 'google').subscribe({
              next: result => {
                this.handleSocialAuthentication(result);
              }, error: err => {
                this.communicationService.errorToast(err).then(() => {
                  console.warn(err)
                });
              }
            }
          )
        }).catch(warn => {
        console.warn(warn)
      });
    } else {
      const googleUser = await GoogleAuth.signIn();
      this.sign_up_social(googleUser.authentication.idToken, 'google').subscribe({
          next: result => {
            this.handleSocialAuthentication(result);
          }, error: err => {
            this.communicationService.errorToast(err).then(() => {
              console.warn(err)
            });
          }
        }
      )
    }
  }

  updateProfileImage() {
    this.userService.getUserProfileImage('optimized', 'profile-image').subscribe();
    this.userService.getUserProfileImage('thumbnail', 'profile-image').subscribe();
  }

  forwardNextUser(user: UserModel) {
    this.user.next(user);
    this.updateProfileImage();
  }

  handleLogout() {
    const cookie = localStorage.getItem('cookiePreferences');
    const position = localStorage.getItem('notificationPermissionAsked');
    localStorage.clear();
    localStorage.setItem('cookiePreferences', cookie);
    localStorage.setItem('notificationPermissionAsked', position);
    this.user.next(null);
    this.invalidateToken();
    this.userService.user_image.next(null);
    this.userService.user_thumb.next(null);
    this.operatorPanelService.changeOperatorData(null).then();
    this.operatorPanelService.changeActiveSportClub(null).then();
  }


  sendResetPasswordEmail(email, token) {
    return this.http.post(API_URL + '/password_reset_email', {
      email,
      token
    }).pipe(map(data => {
      return data;
    }));
  }

  resetPassword(reset_token, password, password_repeat) {
    return this.http.post(API_URL + '/password_reset', {
      reset_token,
      password,
      password_repeat
    }).pipe(map(data => {
      return data;
    }));
  }

  verifyRecaptcha(response) {
    return this.http.post(API_URL + '/verify_captcha', {
      response
    }).pipe(map((data: {
      action: string,
      challenge_ts: string
      hostname: string
      score: number
      success: boolean
    }) => {
      return data;
    }));
  }

  confirmUser(confirmation_code) {
    return this.http.post(API_URL + '/confirm_user', {
      confirmation_code
    }).pipe(map(data => {
      return data;
    }));
  }

  revertNextUser() {
    if (localStorage.getItem('userInfo')) {
      this.user.next(JSON.parse(localStorage.getItem('userInfo')));
      this.updateProfileImage();
    }
  }

  private handleAuthentication(user) {
    const userData = {
      id: user.id,
      token: user.token,
      user: user.user,
      role: user.role,
    }
    this.setUserStorage(userData, true)
    /*this.globalService.getTokenFromStorage();*/
    this.menuController.close().then();
    this.updateProfileImage();
  }

  private handleSocialAuthentication(user) {
    const userData = {
      id: user.id,
      token: user.token,
      user: user.user,
      role: user.role,
    }
    this.setUserStorage(userData, true)
    this.menuController.close().then();
    this.modalCtrl.dismiss().then();
    this.communicationService.successToast('Benvenuto ' + user.user).then();
    this.updateProfileImage();
  }

  /**
   * @description Controlla se l'utente è loggato o no
   */
  get checkIfUserLoggedIn(): boolean {
    return !this.tokenExpirationCheck(this.user.value?.token);
  }

  /**
   * @description Ritorna se il token dell'utente è valido o no
   * @param token Token dell'utente
   */
  tokenExpirationCheck(token: string) {
    const expiration = this.decodeJWTToken(token) ? this.decodeJWTToken(token).exp * 1000 : 0;
    return Date.now() >= expiration;
  }

  /**
   * @description Decodifica del token JWT
   * @param token Token da decodificare
   * @private
   */
  private decodeJWTToken(token: string) {
    return token !== undefined ? JSON.parse(atob(token.split('.')[1])) : null;
  }

  /**
   * @returns Header completo da aggiungere alla chiamata: headers + params
   */
  /*switchedUser() {
    return this.http.get(API_URL + '/check_user', this.globalService.setToken()).pipe(map(data => {
      return data;
    }));
  }
*/
  /*getUserValue() {
    console.log(this.user.value)
    return this.user.value;
  }*/

  /*refreshToken(refresh_token): Observable<any> {
    return this.http.post(API_URL + '/login_refresh', {
      refresh_token
    }).pipe(map(data => {
      return data;
    }));
  }*/

  /* SOCIAL LOGIN */

  /*isLoggedIn(): Observable<boolean> {
    return this.http.get(API_URL + '/is_logged_in', this.globalService.setToken()).pipe(map(data => {
      return !!data;
    }));
  }*/
}
