<div class="container px-0 mt-3">
  <hr class="bg-dark mt-5">
  <div class="text-center bg-white">
    <a href="https://it.nutrimuscle.com/#utm_source=fitadvisor&utm_medium=partenariat&utm_campaign=partfitadvisor202109"
       target="_blank" title="Pagina radio bruno">
      <img alt="Sponsor" src="https://ucarecdn.com/02c9320c-856b-4f5b-992c-12b5c8a19e11/nutrimuscle.jpg"
           class="img-fluid">
    </a>
  </div>
</div>
