import {Component, Input, OnInit} from '@angular/core';
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {SportClubModel} from "@app/models/sport-club.model";
import {PaymentComponent} from "@app/components/payment/payment.component";
import {ModalController} from "@ionic/angular";
import {OperatorPanelService} from "@app/services/operator-panel.service";
import {filter} from "rxjs";

@Component({
  selector: 'check-up-widget',
  templateUrl: './check-up-widget.component.html',
  styleUrls: ['./check-up-widget.component.scss'],
})
export class CheckUpWidgetComponent implements OnInit {

  @Input() disabled: Boolean;

  public sportClub: SportClubModel;

  constructor(
    public navigationRoute: NavigationRouteService,
    private modalCtrl: ModalController,
    public operatorPanelService: OperatorPanelService
  ) {
  }

  ngOnInit() {
    this.operatorPanelService.activeSportClub_obs.pipe(filter(x => x !== null)).subscribe({
      next: (sportClub: SportClubModel) => {
        this.sportClub = sportClub;
      },
      error: err => {
        console.error(err)
      }
    })
  }

  async showPaymentModal() {

    const modal = await this.modalCtrl.create({
      component: PaymentComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height',
      componentProps: {
        sportClubData: this.sportClub
      }
    });

    modal.onDidDismiss()
      .then(() => {
      });

    return modal.present();
  }

}
