import {Component} from '@angular/core';

@Component({
  selector: 'fit-spinner-dark',
  template: '<lottie-player src="/assets/lottie/loading-dark.json" style="width:50px;margin:0 auto;" autoplay loop></lottie-player>',
  styles: [''],
})
export class FitSpinnerDarkComponent {

  constructor() {
  }

}
