import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {UserModel} from "@app/models/user.model";
import {ImageModel} from "@app/models/image.model";
import {LessonModel} from "@app/models/lesson.model";

@Injectable({providedIn: 'root'})
export class UserService {

  user_thumb = new BehaviorSubject('assets/img/default/user.webp');
  user_thumb_obs = this.user_thumb.asObservable();
  user_image = new BehaviorSubject('assets/img/default/user.webp');
  user_image_obs = this.user_image.asObservable();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
  ) {
  }

  getAllUser(): Observable<string[]> {
    return this.http.get<any>(API_URL + '/all_user', this.globalService.setToken()).pipe(map((data: string[]) => {
      return data;
    }));
  }

  getUserData(): Observable<UserModel> {
    return this.http.get<any>(API_URL + '/user_data', this.globalService.setToken()).pipe(map((data: UserModel) => {
      return data;
    }));
  }

  getUserProfileImage(size, section): Observable<any> {
    return this.http.get<any>(API_URL + '/get_image', this.globalService.setTokenAndParams({
      size,
      section
    })).pipe(map((image: ImageModel) => {
      if (image) {
        if (size == 'thumbnail') {
          this.user_thumb.next(image?.image)
        } else if (size == 'optimized') {
          this.user_image.next(image?.image)
        }
        return image;
      } else {
        if (size == 'thumbnail') {
          this.user_thumb.next(null)
        } else if (size == 'optimized') {
          this.user_image.next(null)
        }
      }
    }));
  }

  getUserProfileImageRaw(section = 'profile-image'): Observable<any> {
    return this.http.get<any>(API_URL + '/get_image', this.globalService.setTokenAndParams({
      section
    })).pipe(map((image: ImageModel) => {
      return image;
    }));
  }

  deleteUserProfileImage(): Observable<any> {
    return this.http.delete<any>(API_URL + '/delete_profile_image').pipe(map(data => {
      return data;
    }));
  }

  getUserProfileImageById(size, section): Observable<any> {
    return this.http.get<any>(API_URL + '/get_image', this.globalService.setTokenAndParams({
      size,
      section
    })).pipe(map((image: ImageModel) => {
      if (size == 'thumbnail') {
        this.user_thumb.next(image.image)
      } else if (size == 'optimized') {
        this.user_image.next(image.image)
      }
    }));
  }

  saveUser(id: number, body): Observable<UserModel> {
    if (id > 0) {
      return this.http.put(API_URL + '/user/' + id, {
        body
      }, this.globalService.setToken()).pipe(map((data: UserModel) => {
        return data;
      }));
    } else {
      return this.http.post(API_URL + '/user/' + id, {
        body
      }, this.globalService.setToken()).pipe(map((data: UserModel) => {
        return data;
      }));
    }
  }

  getUser(query ?: string, sort?, group?, limit?, page?): Observable<{ elenco: UserModel[], totale: number }> {
    return this.http.get<any>(API_URL + '/user', this.globalService.setTokenAndParams({
      query,
      sort,
      group,
      limit,
      page
    })).pipe(map((data: { elenco: UserModel[], totale: number }) => {
      return data;
    }));
  }

  getUserHaveReservedLesson(id: number): Observable<FitterLessonModel> {
    return this.http.get<any>(API_URL + '/user_have_reserved_lesson/' + id, this.globalService.setToken()).pipe(map((data: FitterLessonModel) => {
      return data;
    }));
  }

  canUserReserveLesson(id: number): Observable<any> {
    return this.http.get<any>(API_URL + '/can_user_reserve/' + id, this.globalService.setToken()).pipe(map((data: FitterLessonModel) => {
      return data;
    }));
  }
  
}

export interface FitterLessonModel {
  id: number;
  confirmed: boolean;
  fitter_id: number;
  lesson_id: number;
  display: string;
  name: string;
  lesson: LessonModel;
}
