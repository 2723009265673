<!--<ion-menu side="end" content-id="main-content">
  <ion-content>
    <ion-toolbar class="container-fluid">
      <ion-buttons class="menu-button ms-5" *ngIf="!isAuthenticated">
        <ion-button class="text-white bold montserrat p-1 ion-bg-primary rounded" (click)="showModalLogin()">
          <ion-animated-icon slot="start" name="log-in" class="me-2 opacity-50"></ion-animated-icon>
          Accedi
        </ion-button>
        <ion-button class="text-white mx-4 bold montserrat p-1 ion-bg-secondary rounded" (click)="showModalSignup()">
          <ion-animated-icon slot="start" name="person-add" class="me-2 opacity-50"></ion-animated-icon>
          Iscriviti
        </ion-button>
      </ion-buttons>
      <ion-buttons *ngIf="isAuthenticated" slot="start">
        <ion-animated-icon name="chatbubbles" id="popover-chat-button-mobile" class="fit-animated-icon-header"></ion-animated-icon>
        &lt;!&ndash;
                    <ion-badge color="primary" class="fit-animated-icon-badge-chat">23</ion-badge>
        &ndash;&gt;
        <ion-popover trigger="popover-chat-button-mobile" side="bottom" alignment="center" [dismissOnSelect]="true">
          <ng-template>
            <ion-content>
              <ion-list>
                <ion-item-sliding>
                  <ion-item>
                    <ion-label>
                      <h2>HubStruck Notifications</h2>
                      <p>A new message in your network</p>
                    </ion-label>
                  </ion-item>

                  <ion-item-options side="start">
                    <ion-item-option>
                      <ion-animated-icon slot="animated-icon-only" name="heart"></ion-animated-icon>
                    </ion-item-option>
                  </ion-item-options>

                  <ion-item-options side="end">
                    <ion-item-option color="danger">
                      <ion-animated-icon slot="animated-icon-only" name="trash"></ion-animated-icon>
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
                <ion-item-sliding>
                  <ion-item>
                    <ion-label>
                      <h2>HubStruck Notifications</h2>
                      <p>A new message in your network</p>
                    </ion-label>
                  </ion-item>

                  <ion-item-options side="start">
                    <ion-item-option>
                      <ion-animated-icon slot="animated-icon-only" name="heart"></ion-animated-icon>
                    </ion-item-option>
                  </ion-item-options>

                  <ion-item-options side="end">
                    <ion-item-option color="danger">
                      <ion-animated-icon slot="animated-icon-only" name="trash"></ion-animated-icon>
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
              </ion-list>
            </ion-content>
          </ng-template>
        </ion-popover>
        <ion-animated-icon name="notifications" id="popover-notification-button-mobile"
                  class="fit-animated-icon-header"></ion-animated-icon>
        &lt;!&ndash;
                    <ion-badge color="secondary" class="fit-animated-icon-badge-notification">2</ion-badge>
        &ndash;&gt;
        <ion-popover trigger="popover-notification-button-mobile" side="bottom" alignment="center"
                     [dismissOnSelect]="true">
          <ng-template>
            <ion-content>
              <ion-list>
                <ion-item-sliding>
                  <ion-item>
                    <ion-label>
                      <h2>HubStruck Notifications</h2>
                      <p>A new message in your network</p>
                    </ion-label>
                  </ion-item>

                  <ion-item-options side="start">
                    <ion-item-option>
                      <ion-animated-icon slot="animated-icon-only" name="heart"></ion-animated-icon>
                    </ion-item-option>
                  </ion-item-options>

                  <ion-item-options side="end">
                    <ion-item-option color="danger">
                      <ion-animated-icon slot="animated-icon-only" name="trash"></ion-animated-icon>
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
                <ion-item-sliding>
                  <ion-item>
                    <ion-label>
                      <h2>HubStruck Notifications</h2>
                      <p>A new message in your network</p>
                    </ion-label>
                  </ion-item>

                  <ion-item-options side="start">
                    <ion-item-option>
                      <ion-animated-icon slot="animated-icon-only" name="heart"></ion-animated-icon>
                    </ion-item-option>
                  </ion-item-options>

                  <ion-item-options side="end">
                    <ion-item-option color="danger">
                      <ion-animated-icon slot="animated-icon-only" name="trash"></ion-animated-icon>
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
              </ion-list>
            </ion-content>
          </ng-template>
        </ion-popover>
      </ion-buttons>
      <ion-buttons *ngIf="isAuthenticated" slot="end">
        <ion-item id="popover-account-button-operator-panel-mobile" lines="none">
          <img class="fit-account-animated-icon fit-animated-icon-header" [defaultImage]="defaultImage" [lazyLoad]="userImage"
               [errorImage]="errorImage" alt="Immagine di profilo" src=""/>
          &lt;!&ndash;
                    <ion-animated-icon name="person" ></ion-animated-icon>
          &ndash;&gt;
        </ion-item>
        <ion-popover trigger="popover-account-button-operator-panel-mobile" side="bottom" alignment="start"
                     [dismissOnSelect]="true">
          <ng-template>
            <ion-content>
              <ion-list>
                <ion-menu-toggle>
                  <ion-item [button]="true" [detail]="false" [routerLink]="navigationRoute.navigate('profile')">
                    <ion-animated-icon slot="start" name="person"></ion-animated-icon>
                    Vedi profilo
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle *ngIf="user?.role !== 'ROLE_USER'">
                  <ion-item [button]="true" [detail]="false" [routerLink]="navigationRoute.navigate('operator-panel')">
                    <ion-animated-icon slot="start" name="bar-chart"></ion-animated-icon>
                    Pannello operatore
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                  <ion-item (click)="onLogout()" detail="false" [button]="true" lines="none"
                            id="nested-trigger-mobile">
                    <ion-animated-icon slot="start" name="log-out"></ion-animated-icon>
                    Esci
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>
            </ion-content>
          </ng-template>
        </ion-popover>
      </ion-buttons>
      <ion-menu-toggle slot="end">
        <ion-animated-icon class="mt-4" name="close"></ion-animated-icon>
      </ion-menu-toggle>
    </ion-toolbar>
  </ion-content>
</ion-menu>
<div class="ion-page" id="main-content">
  <ion-header class="bg-white">
    <ion-toolbar class="container p-md-0">
      <ion-title class="my-2 p-0">
        <img itemprop="logo" src="https://www.fitadvisor.it/images/portale/logo/logo-fitadvisor.svg"
             class="img-fluid pointer logo-fitadvisor" alt="FitAdvisor - Il portale dello sportivo"
             [routerLink]="navigationRoute.navigate( 'home')"
        />
      </ion-title>
      <ion-buttons slot="end" *ngIf="!isAuthenticated" class="ion-hide-md-down">
        <ion-button course="text-white bold montserrat p-1 ion-bg-primary rounded" (click)="showModalLogin()">
          Accedi
        </ion-button>
        <ion-button course="text-white mx-4 bold montserrat p-1 ion-bg-secondary rounded" (click)="showModalSignup()">
          Iscriviti
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end" *ngIf="isAuthenticated" course="ion-hide-md-down">
        <div *ngIf="isAuthenticated">
          <ion-buttons slot="end">
            <ion-animated-icon name="chatbubbles" id="popover-chat-button" course="fit-animated-icon-header"></ion-animated-icon>
            &lt;!&ndash;
                        <ion-badge color="primary" course="fit-animated-icon-badge-chat">23</ion-badge>
            &ndash;&gt;
            <ion-popover trigger="popover-chat-button" side="bottom" alignment="end" [dismissOnSelect]="true">
              <ng-template>
                <ion-content>
                  <ion-list>
                    <ion-item-sliding>
                      <ion-item>
                        <ion-label>
                          <h2>HubStruck Notifications</h2>
                          <p>A new message in your network</p>
                        </ion-label>
                      </ion-item>

                      <ion-item-options side="start">
                        <ion-item-option>
                          <ion-animated-icon slot="animated-icon-only" name="heart"></ion-animated-icon>
                        </ion-item-option>
                      </ion-item-options>

                      <ion-item-options side="end">
                        <ion-item-option color="danger">
                          <ion-animated-icon slot="animated-icon-only" name="trash"></ion-animated-icon>
                        </ion-item-option>
                      </ion-item-options>
                    </ion-item-sliding>
                    <ion-item-sliding>
                      <ion-item>
                        <ion-label>
                          <h2>HubStruck Notifications</h2>
                          <p>A new message in your network</p>
                        </ion-label>
                      </ion-item>

                      <ion-item-options side="start">
                        <ion-item-option>
                          <ion-animated-icon slot="animated-icon-only" name="heart"></ion-animated-icon>
                        </ion-item-option>
                      </ion-item-options>

                      <ion-item-options side="end">
                        <ion-item-option color="danger">
                          <ion-animated-icon slot="animated-icon-only" name="trash"></ion-animated-icon>
                        </ion-item-option>
                      </ion-item-options>
                    </ion-item-sliding>
                  </ion-list>
                </ion-content>
              </ng-template>
            </ion-popover>
            <ion-animated-icon name="notifications" id="popover-notification-button"
                      course="fit-animated-icon-header"></ion-animated-icon>
            &lt;!&ndash;
                        <ion-badge color="secondary" course="fit-animated-icon-badge-notification">2</ion-badge>
            &ndash;&gt;
            <ion-popover trigger="popover-notification-button" side="bottom" alignment="end" [dismissOnSelect]="true">
              <ng-template>
                <ion-content>
                  <ion-list>
                    <ion-item-sliding>
                      <ion-item>
                        <ion-label>
                          <h2>HubStruck Notifications</h2>
                          <p>A new message in your network</p>
                        </ion-label>
                      </ion-item>

                      <ion-item-options side="start">
                        <ion-item-option>
                          <ion-animated-icon slot="animated-icon-only" name="heart"></ion-animated-icon>
                        </ion-item-option>
                      </ion-item-options>

                      <ion-item-options side="end">
                        <ion-item-option color="danger">
                          <ion-animated-icon slot="animated-icon-only" name="trash"></ion-animated-icon>
                        </ion-item-option>
                      </ion-item-options>
                    </ion-item-sliding>
                    <ion-item-sliding>
                      <ion-item>
                        <ion-label>
                          <h2>HubStruck Notifications</h2>
                          <p>A new message in your network</p>
                        </ion-label>
                      </ion-item>

                      <ion-item-options side="start">
                        <ion-item-option>
                          <ion-animated-icon slot="animated-icon-only" name="heart"></ion-animated-icon>
                        </ion-item-option>
                      </ion-item-options>

                      <ion-item-options side="end">
                        <ion-item-option color="danger">
                          <ion-animated-icon slot="animated-icon-only" name="trash"></ion-animated-icon>
                        </ion-item-option>
                      </ion-item-options>
                    </ion-item-sliding>
                  </ion-list>
                </ion-content>
              </ng-template>
            </ion-popover>
            <ion-item id="popover-account-button-operator-panel" lines="none">
              <img course="fit-account-animated-icon fit-animated-icon-header" [defaultImage]="defaultImage" [lazyLoad]="userImage"
                   [errorImage]="errorImage"
                   alt="Immagine di profilo" src=""/>
              &lt;!&ndash;
                            <ion-animated-icon name="person" course="fit-account-animated-icon fit-animated-icon-header"></ion-animated-icon>
              &ndash;&gt;
            </ion-item>
            <ion-popover trigger="popover-account-button-operator-panel" side="bottom" alignment="end"
                         [dismissOnSelect]="true">
              <ng-template>
                <ion-content>
                  <ion-list>
                    <ion-item [button]="true" [detail]="false" [routerLink]="navigationRoute.navigate('profile')">
                      <ion-animated-icon slot="start" name="person"></ion-animated-icon>
                      Vedi profilo
                    </ion-item>
                    <ion-item *ngIf="user?.role !== 'ROLE_USER'"
                              [button]="true" [detail]="false" [routerLink]="navigationRoute.navigate('operator-panel')">
                      <ion-animated-icon slot="start" name="bar-chart"></ion-animated-icon>
                      Pannello operatore
                    </ion-item>
                    <ion-item (click)="onLogout()" detail="false" [button]="true" lines="none" id="nested-trigger">
                      <ion-animated-icon slot="start" name="log-out"></ion-animated-icon>
                      Esci
                    </ion-item>
                  </ion-list>
                </ion-content>
              </ng-template>
            </ion-popover>
          </ion-buttons>
        </div>

      </ion-buttons>
      <ion-menu-toggle slot="end" course="ion-hide-md-up">
        <ion-animated-icon course="mt-4" name="menu"></ion-animated-icon>
      </ion-menu-toggle>
    </ion-toolbar>
  </ion-header>

  <ion-content course="ion-padding">
    <ion-router-outlet>
    </ion-router-outlet>
  </ion-content>
</div>-->
<!--<fit-menu></fit-menu>
<div course="ion-page" id="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>Menu</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content course="ion-padding">
    <ion-router-outlet>
    </ion-router-outlet>
  </ion-content>
</div>-->

<div class="ion-page" id="main-content">
  <ion-content class="ion-padding">
    <ion-router-outlet>
    </ion-router-outlet>
  </ion-content>
</div>

