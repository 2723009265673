import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StorageService} from "@app/helper/storage.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {API_URL, GOOGLE_KEY} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private user = new BehaviorSubject(null);
  user_obs = this.user.asObservable();

  constructor(
    private http: HttpClient,
    public storage: StorageService
  ) {
  }

  getAddressFromCoordinates(lat, lng): Observable<any> {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + GOOGLE_KEY).pipe(map(data => {
      return data;
    }));
  }


}
