<div class="inner-content p-3">
  <ion-icon (click)="closeModal()" class="position-absolute top-0 end-0 m-2" color="primary" name="xmark"
            size="large"></ion-icon>
  <div class="p-4 ion-bg-primary-contrast fit-rounded">
    <h4 class="m-0 bold">
      {{notification.subject}}
    </h4>
    <p class="m-0 mt-3 ion-text-justify">
      {{notification.body}}
    </p>
  </div>
  <div class="p-2 ion-bg-primary-tint text-center opacity-50">
    <small>Inviata il {{notification.date_send}} </small>
  </div>
</div>
