import {Component, Input, OnInit} from '@angular/core';
import {modalController} from "@ionic/core";

@Component({
  selector: 'app-tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.scss'],
})
export class TutorialModalComponent implements OnInit {

  @Input() message: string = '';
  @Input() title: string = '';
  protected readonly modalController = modalController;

  constructor() {
  }

  ngOnInit() {
  }
}
