import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "@app/services/auth.service";
import {NgForm} from "@angular/forms";
import {ModalController, ToastController} from "@ionic/angular";
import {SignupComponent} from "@app/pages/auth/signup/signup.component";
import {CommunicationService} from "@app/helper/communication.service";
import {fadeInAnimation} from "@app/animations";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {Capacitor} from "@capacitor/core";
import {ChooseUserTypeModalComponent} from "@app/components/choose-user-type-modal/choose-user-type-modal.component";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInAnimation]
})
export class LoginComponent implements OnInit, OnDestroy {

  public defaultRemember: boolean = true;
  public isLoading: boolean = false;
  isPasswordVisible: string = 'password';
  /*BOOL*/
  public isRecoveryPasswordMode: boolean = false;
  private user: string = null;
  private email: string = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private toastController: ToastController,
    private modalCtrl: ModalController,
    private communicationService: CommunicationService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
  }

  ngOnInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
    this.isRecoveryPasswordMode = false;
  }

  async onSubmit(authForm: NgForm) {
    if (!authForm.valid) {
      return;
    }
    const email = authForm.value.email.trim();
    const password = authForm.value.password;
    this.isLoading = true;

    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        this.authService.verifyRecaptcha(token).subscribe({
          next: result => {
            if (result.success) {
              this.authService.login(email, password).subscribe({
                next: (user) => {
                  this.modalCtrl.dismiss()
                  this.communicationService.successToast('Benvenuto ' + user.user).then(() => {
                    this.isLoading = false;
                    authForm.resetForm();
                  })
                },
                error: () => {
                  this.isLoading = false;
                  authForm.resetForm();
                }
              })
            } else {
              this.communicationService.customErrorToast('RECAPTCHA_ERROR', 'danger').then()
            }
          }
        })
      });

  }

  protected readonly Capacitor = Capacitor;

  async onLoginChangeRegister() {
    await this.modalCtrl.dismiss();
    await this.showModalSignup();
  }

  closeModal() {
    this.modalCtrl.dismiss().then();
  }

  /* SOCIAL LOGIN */

  async showModalSignup() {
    const modal = await this.modalCtrl.create({
      component: SignupComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height',
    });
    const modalChooseUserType = await this.modalCtrl.create({
      component: ChooseUserTypeModalComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height',
    });
    this.isLoading = true

    modal.onDidDismiss().then(() => {
      this.isLoading = true;
      this.ngOnInit()
    });

    modalChooseUserType.onDidDismiss().then(data => {
      if (data.role === 'fitter') {
        return modal.present();
      } else if (data.role === 'operator') {
        this.router.navigate(['/registrazione-operatore']).then();
      }
    });

    return modalChooseUserType.present();
  }

  changeIsPasswordVisible() {
    if (this.isPasswordVisible === "password") {
      this.isPasswordVisible = "text";
    } else {
      this.isPasswordVisible = "password";
    }
  }

  setRecoveryPasswordMode(isMode: boolean) {
    this.isRecoveryPasswordMode = isMode;
  }

  onPasswordForgot(authForm: NgForm) {
    this.isLoading = true;

    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        this.authService.sendResetPasswordEmail(authForm.value.email.trim(), token).subscribe({
          next: () => {
            this.isLoading = false;
            this.communicationService.successToast('Email di recupero password inviata').then(() => {
              this.isRecoveryPasswordMode = false;
            })
          },
          error: err => {
            console.error(err);
            this.isLoading = false;
          }
        })
      });
  }

  public ngOnDestroy() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  onFacebookLogin(): void {
    this.authService.facebookLogin().then()
  }

  onGoogleLogin(): void {
    this.authService.googleLogin().then();
  }
}
