import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {PaymentComponent} from "@app/components/payment/payment.component";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {SportClubModel} from "@app/models/sport-club.model";
import {StripeComponent} from "@app/components/stripe/stripe.component";
import {OperatorModel} from "@app/models/operator.model";
import {OperatorPanelService} from "@app/services/operator-panel.service";
import {AuthService} from "@app/services/auth.service";

@Component({
  selector: 'app-turbo-function',
  templateUrl: './turbo-function.component.html',
  styleUrls: ['./turbo-function.component.scss'],
})
export class TurboFunctionComponent implements OnInit {

  @Input() sportClubData: SportClubModel;
  public operatorData: OperatorModel;

  constructor(
    public modalCtrl: ModalController,
    public navigationRoute: NavigationRouteService,
    private operatorPanelService: OperatorPanelService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    if (this.authService.checkIfUserLoggedIn) {
      this.operatorPanelService.getOperatorData().subscribe({
        next: (operatorData: OperatorModel) => {
          this.operatorData = operatorData
        }, error: err => {
          console.error(err)
        }
      })
    }
  }

  showPaymentModal() {
    this.modalCtrl.dismiss().then(async () => {
      const modal = await this.modalCtrl.create({
        component: PaymentComponent,
        canDismiss: true,
        backdropDismiss: false,
        mode: "ios",
        cssClass: 'auto-height',
        componentProps: {
          sportClubData: this.sportClubData
        }
      });

      modal.onDidDismiss().then();

      return modal.present();
    })
  }

  buyMediaPrime() {
    this.modalCtrl.dismiss().then(async () => {

      const modal = await this.modalCtrl.create({
        component: StripeComponent,
        backdropDismiss: false,
        canDismiss: true,
        mode: "ios",
        cssClass: "auto-height",
        componentProps: {
          isBuyingTurbo: false,
          itemForSale: {
            name: 'Web Media Prime',
            value: 'TURBO_WEB_MEDIA_PRIME',
            price: 59.78
          }
        }
      });

      return modal.present();
    })
  }
}
