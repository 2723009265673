<div class="d-flex flex-column ">
  <h1 class="m-0 bold mt-3">
    Vuoi scoprire di più sulla genetica sportiva ?
  </h1>
  <p class="mt-2 mb-3">Ricevi per e-mail la <b>GUIDA RAPIDA</b> sulla <b>genetica</b> applicata allo <b>sport</b>
    inserendola qui sotto.</p>
  <form (ngSubmit)="saveNewsLetter()" [formGroup]="newsletterForm" novalidate>
    <form-input [form]="newsletterForm" [isLableVisible]="false" formInputName="email"
                name="E-mail" required="true"></form-input>
    <div class="form-check my-4 d-flex gap-3">
      <input [checked]="true" [disabled]="true" class="form-check-input pointer opacity-100" id="privacy"
             type="checkbox">
      <label class="form-check-label pointer fit-content-width opacity-100" for="privacy">
        Ho letto la
        <a class="bold" href="https://www.fitadvisor.it/info/note-legali" rel="nofollow" target="_blank">Privacy
          Policy</a>
        e acconsento al trattamento dei miei dati personali per le finalità sopra descritte.
      </label>
    </div>
    <ion-button [disabled]="newsletterForm.invalid" class="ion-btn-primary mt-4" expand="block" type="submit">
      <span *ngIf="!isLoading && !isSent" @fadeIn>
        Ricevi la guida rapida
      </span>
      <span *ngIf="isLoading" @fadeIn>
        <fit-spinner-light></fit-spinner-light>
      </span>
      <span *ngIf="!isLoading && isSent" @fadeIn>
        <ion-icon class="size-23" name="check"></ion-icon>
      </span>
    </ion-button>
  </form>
</div>
