import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {SportModel} from "@app/models/sport.model";
import {SportFacilityModel} from "@app/models/sport-facility.model";

@Injectable({providedIn: 'root'})

export class SportService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  getAllSport(sort): Observable<SportModel[]> {
    return this.http.get<any>(API_URL + '/sport', this.globalService.getParams({
      sort
    })).pipe(map(sport => {
      return sport['elenco'];
    }));
  }

  getAllSportSearchByName(name: string, limit: number): Observable<any> {
    return this.http.get(API_URL + '/sport?limit=' + limit + '&page=1&query=' + name).pipe(map((SportFacilityArray: SportFacilityModel[]) => {
      return SportFacilityArray['elenco'];
    }));
  }

  getSportData(id): Observable<any> {
    return this.http.get(API_URL + '/sport/' + id).pipe(map(data => {
      return data;
    }));
  }

  getSportClubImage(size, section, id): Observable<any> {
    return this.http.get(API_URL + '/get_image', this.globalService.getParams({
      id, size, section
    })).pipe(map(data => {
      return data
    }));
  }

  getFederation(size, section, id): Observable<any> {
    return this.http.get(API_URL + '/get_image', this.globalService.getParams({
      size, section, id
    })).pipe(map(data => {
      return data;
    }));
  }

  getSportBySportClub(user_lat, user_long, radius, sort, sport_facility, sport_club): Observable<any> {
    return this.http.get(API_URL + '/get_sport_by_sport_club', this.globalService.getParams({
      user_lat,
      user_long,
      radius,
      sort,
      sport_facility,
      sport_club
    })).pipe(map(data => {
      return data;
    }));
  }

  getSportFacilityBySportClub(id: number): Observable<any> {
    return this.http.get(API_URL + '/get_sport_facility_by_sport_club', this.globalService.getParams({
      id
    })).pipe(map(data => {
      return data;
    }));
  }

  saveReservation(id: number, body: any): Observable<any> {
    return this.http.post(API_URL + '/fitter_lesson/' + id, {
      body
    }, this.globalService.setToken()).pipe(map(data => {
      return data;
    }));
  }
}
