import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {StorageService} from "@app/helper/storage.service";
import {BehaviorSubject, filter, map, Observable} from "rxjs";
import {API_URL, PAYPAL_DATA} from "@environments/environment";
import {Router} from "@angular/router";
import {ModalController} from "@ionic/angular";
import {CommunicationService} from "@app/helper/communication.service";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {UserModel} from "@app/models/user.model";
import {AuthService} from "@app/services/auth.service";
import {UserService} from "@app/services/user.service";
import {OperatorService} from "@app/services/operator.service";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {GlobalService} from "@app/services/global.service";
import {StripeService} from "ngx-stripe";
import {PaymentIntent} from "@stripe/stripe-js";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private access_token = new BehaviorSubject(null);
  access_token_obs = this.access_token.asObservable();

  constructor(
    private http: HttpClient,
    public storage: StorageService,
    public router: Router,
    private modalCtrl: ModalController,
    private communicationService: CommunicationService,
    private navigationService: NavigationRouteService,
    private authService: AuthService,
    private userService: UserService,
    private operatorService: OperatorService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private globalService: GlobalService,
    private stripeService: StripeService
  ) {
  }

  createAuthorizationHeader(headers: HttpHeaders): Promise<HttpHeaders> {
    return new Promise((resolve, reject) => {
      this.access_token_obs.subscribe({
        next: access_token => {
          if (access_token) {
            headers.append(
              'Authorization',
              'Bearer ' + access_token
            );
            resolve(headers);
          } else {
            this.http.get(API_URL + '/paypal_token_generate').subscribe({
              next: (data: any) => {
                headers = headers.append(
                  'Authorization',
                  'Bearer ' + data.access_token
                );
                if (headers) {
                  resolve(headers);
                  this.access_token.next(data.access_token)
                } else {
                  reject();
                }
              }
            });
          }
        }
      })
    });
  }

  activateSubscription(turbo_package) {
    let headers: HttpHeaders = new HttpHeaders();
    this.createAuthorizationHeader(headers).then((headers) => {
      this.http.post(PAYPAL_DATA.base_url + '/v1/billing/subscriptions', {
          plan_id: turbo_package.subscription_plan_id,
          quantity: 1,
          application_context: {
            brand_name: "Fitadvisor",
            user_action: "SUBSCRIBE_NOW",
            return_url: location.origin + "/grazie"
          }
        },
        {
          headers
        }).subscribe({
        next: (order: any) => {
          order.links.forEach(link => {
            if (link.rel == 'approve') {
              this.navigationService.goTo(link.href)
            }
          })
        }, error: err => {
          console.error(err)
          this.onPurchaseCancel()
        }
      });
    }).catch(() => {
      this.onPurchaseCancel()
    })
  }

  createOrder(turbo_package) {
    let headers: HttpHeaders = new HttpHeaders();
    this.createAuthorizationHeader(headers).then((headers) => {
      this.http.post(PAYPAL_DATA.base_url + '/v2/checkout/orders', {
          intent: "CAPTURE",
          purchase_units: [
            {
              items: [
                {
                  name: turbo_package.name,
                  quantity: "1",
                  unit_amount: {
                    currency_code: "EUR",
                    value: turbo_package.price
                  }
                }
              ],
              amount: {
                currency_code: "EUR",
                value: turbo_package.price,
                breakdown: {
                  item_total: {
                    currency_code: "EUR",
                    value: turbo_package.price
                  }
                }
              }
            }
          ],
          application_context: {
            brand_name: "Fitadvisor",
            return_url: location.origin + "/grazie"
          }
        },
        {
          headers
        }).subscribe({
        next: (order: any) => {
          order.links.forEach(link => {
            if (link.rel == 'approve') {
              this.navigationService.goTo(link.href)
            }
          })
        }, error: err => {
          console.error(err)
          this.onPurchaseCancel()
        }
      });
    }).catch(() => {
      this.onPurchaseCancel()
    })
  }

  async getOrderDetail(order_id): Promise<any> {
    let headers: HttpHeaders = new HttpHeaders();
    return new Promise((resolve, reject) => {
      this.createAuthorizationHeader(headers).then((headers) => {
        this.http.get(PAYPAL_DATA.base_url + '/v2/checkout/orders/' + order_id, {
          headers: headers
        }).subscribe({
          next: (order: any) => {
            if (order.status == 'APPROVED') {
              return this.http.post(PAYPAL_DATA.base_url + '/v2/checkout/orders/' + order.id + '/capture', {},
                {
                  'headers': headers
                }).subscribe({
                next: () => {
                },
                error: err => {
                  reject();
                  console.error(err)
                },
                complete: () => {
                  this.onPurchaseSuccess(order.id, 'paypal')
                  resolve(order);
                }
              });
            } else if (order.status == 'COMPLETED') {
              resolve(order);
            } else {
              this.onPurchaseError(order.id, 'paypal')
            }
          }, error: err => {
            reject();
            console.error(err)
            this.onPurchaseCancel()
          }
        })
      }).catch(() => {
        reject();
        this.onPurchaseCancel()
      })
    })
  }

  terminateAuthTokenPaypal(access_token) {
    let body = `token_type_hint=ACCESS_TOKEN&token=${access_token}`;
    return this.http.post(PAYPAL_DATA.base_url + '/v1/oauth2/token/terminate',
      body
    ).pipe(map(data => {
      return data;
    }));
  }

  sendPaymentEmail(email, company_name?, vat_identification?, address?, proprietor?, payed?, turboPackage?, price?, payment_id ?): Observable<any> {
    if (address && proprietor) {
      return this.http.post(API_URL + '/check_up_payment', {
        email, company_name, vat_identification, address, proprietor, payed, turboPackage, price, payment_id
      }).pipe(map(data => {
        return data;
      }));
    } else {
      const address = 'Indirizzo non specificato'
      const proprietor = 'Proprietario non specificato'
      return this.http.post(API_URL + '/check_up_payment', {
        email, company_name, vat_identification, address, proprietor, payed, turboPackage, price, payment_id
      }).pipe(map(data => {
        return data;
      }));
    }
  }

  sendPaymentEmailPaypal(email, company_name, vat_identification, address, proprietor, payed, turboPackage, price, payment_id, paypal, payment_type): Observable<any> {
    return this.http.post(API_URL + '/check_up_payment', {
      email,
      company_name,
      vat_identification,
      address,
      proprietor,
      payed,
      turboPackage,
      price,
      paypal,
      payment_type,
      payment_id
    }).pipe(map(data => {
      return data;
    }));
  }

  getAllTurboPackage() {
    return this.http.get(API_URL + '/turbo').pipe(map(data => {
      return data;
    }));
  }

  savePaymentData(selectedTurboPackage, payed, payment_id?: string, paypal ?: boolean, payment_type ?: string) {
    const paymentForm = JSON.parse(localStorage.getItem('paymentForm'));
    const company_name = paymentForm.sportClubName
    const vat_identification = paymentForm.sportClubVatIdentifier
    const address = paymentForm.sportClubAddress
    const proprietor = paymentForm.sportClubProprietor
    const turboPackage = selectedTurboPackage.name
    const price = selectedTurboPackage.price

    this.getUserEmail().then(email => {
      if (payed == true) {
        this.recaptchaV3Service.execute('importantAction')
          .subscribe((token) => {
            this.operatorService.setOperatorTurbo(selectedTurboPackage.id, payment_id, email, token).subscribe()
            localStorage.removeItem('paymentForm')
            localStorage.removeItem('checkUpAnswer')
            localStorage.removeItem('checkUpAnswer2nd')
            localStorage.removeItem('selectedTurboPackage')
            localStorage.removeItem('sportClub')
            localStorage.removeItem('userEmail')
          });
        if (paypal) {
          this.sendPaymentEmailPaypal(email, company_name, vat_identification, address, proprietor, payed, turboPackage, price, payment_id, paypal, payment_type).subscribe()
        } else {
          this.sendPaymentEmail(email, company_name, vat_identification, address, proprietor, payed, turboPackage, price, payment_id).subscribe()
        }
      } else {
        this.sendPaymentEmail(email, company_name, vat_identification, address, proprietor, payed, turboPackage, price).subscribe()
      }
    })

  }

  getUserEmail(): Promise<string> {
    return new Promise((resolve) => {
      this.authService.user_obs.pipe(filter(x => x !== null)).subscribe({
        next: (user: UserModel) => {
          const isAuthenticated = !this.authService.tokenExpirationCheck(user?.token);
          if (isAuthenticated) {
            this.userService.getUserData().subscribe({
              next: (userData: UserModel) => {
                resolve(userData?.email);
              }
            })
          } else {
            resolve(localStorage.getItem('userEmail'));
          }
        }
      })
    })
  }

  onPurchaseSuccess(payment_id, payment_type) {
    const selectedTurboPackage = JSON.parse(localStorage.getItem('selectedTurboPackage'))
    this.savePaymentData(selectedTurboPackage, true, payment_id, true, payment_type)
    this.communicationService.successLongToast('Acquisto andato a buon fine, grazie').then();
  }

  onPurchaseError(payment_id, payment_type) {
    const selectedTurboPackage = JSON.parse(localStorage.getItem('selectedTurboPackage'))
    this.savePaymentData(selectedTurboPackage, false, payment_id, true, payment_type)
  }

  onPurchaseCancel() {
    this.savePaymentData(JSON.parse(localStorage.getItem('selectedTurboPackage')), false)
  }

  // STRIPE

  /**
   * @description Crea la sessione di pagamento singolo con Stripe
   * @param item Oggetto da acquistare
   * @param price Prezzo della transazione
   */
  /*createCheckOutSession(item: string, price: number) {
    if (this.authService.checkIfUserLoggedIn) {
      /!*this.http.post(API_URL + '/create-checkout-session', {
        item,
        price
      }, this.globalService.setToken()).pipe(switchMap((session: any) => {
        localStorage.setItem('paymentSessionId', session.id)
        return this.stripeService.redirectToCheckout({sessionId: session.id})
      })).subscribe(result => {
        if (result.error) {
          alert(result.error.message);
        }
      });*!/
      this.http.post(API_URL + '/create-checkout-session', {
        item,
        price
      }, this.globalService.setToken()).subscribe({
          next: async payment_intent => {
            console.log(payment_intent)
            await this.onCreatePaymentSheet(payment_intent);
          },
          error: err => {
            this.communicationService.errorToast(err).then();
          }
        }
      );
    } else {
      this.http.post(API_URL + '/create-checkout-session', {
        item,
        price
      }).subscribe({
          next: async payment_intent => {
            console.log(payment_intent)
            await this.onCreatePaymentSheet(payment_intent);
          },
          error: err => {
            this.communicationService.errorToast(err).then();
          }
        }
      );
    }

  }*/

  /*
    async onCreatePaymentSheet(payment_intent: any) {
      if (Capacitor.getPlatform() === 'web') {

        await Stripe.createPaymentSheet({
          paymentIntentClientSecret: payment_intent.paymentIntent,
          customerId: payment_intent.customer,
          customerEphemeralKeySecret: payment_intent.ephemeralKey,
          merchantDisplayName: 'Fitadvisor SRL',
          countryCode: 'IT',
          withZipCode: true
        })

        const result = await Stripe.presentPaymentSheet();
        console.log(result)
        if (result.paymentResult === PaymentSheetEventsEnum.Completed) {

          this.onPurchaseSuccess('', '');

        } else if (result.paymentResult === PaymentSheetEventsEnum.Failed) {

          this.onPurchaseError('', '');

        } else {

          this.onPurchaseCancel()

        }

      } else {

        await Stripe.createPaymentSheet({
          paymentIntentClientSecret: payment_intent.paymentIntent,
          customerId: payment_intent.customer,
          customerEphemeralKeySecret: payment_intent.ephemeralKey,
          merchantDisplayName: 'Fitadvisor SRL',
          enableGooglePay: true,
          enableApplePay: true,
          countryCode: 'IT',
          withZipCode: true
        })

        const result = await Stripe.presentPaymentSheet();
        console.log(result)
        if (result.paymentResult === PaymentSheetEventsEnum.Completed) {

          this.onPurchaseSuccess('', '');

        } else if (result.paymentResult === PaymentSheetEventsEnum.Failed) {

          this.onPurchaseError('', '');

        } else {

          this.onPurchaseCancel()

        }

      }

      /!*const isAvailable = Stripe.isGooglePayAvailable();
  console.log(isAvailable)
  if (isAvailable === undefined) {
    // disable to use Google Pay
    return;
  }
  await Stripe.createGooglePay({
    paymentIntentClientSecret: payment_intent.paymentIntent,

    // Web only. Google Pay on Android App doesn't need
    paymentSummaryItems: [{
      label: 'Product Name',
      amount: 1099.00
    }],
    merchantIdentifier: 'merchant.com.getcapacitor.stripe',
    countryCode: 'IT',
    currency: 'EUR',
  });

  const result = await Stripe.presentGooglePay();

  console.log(result)

  if (result.paymentResult === GooglePayEventsEnum.Completed) {
    // Happy path
  }*!/


    }
  */

  /**
   * @description Crea un intent di pagamento singolo con Stripe
   * @param item Oggetto da acquistare
   * @param price Prezzo della transazione
   */
  createPaymentIntent(item: string, price: number): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      API_URL + '/create-payment-intent',
      {
        item,
        price
      }
    );
  }

  /**
   * @description Salvataggio pagamento
   * @param token ReCaptcha token da controllare a back-end
   * @param product Nome del prodotto acquistato
   * @param amount Prezzo oggetto acquistato
   * @param completed Transazione completata ?
   * @param method Metodo di pagamento
   * @param proprietor
   * @param email
   * @param address
   * @param payment_code
   */
  onStipePayment(token: string, product: string, amount: number, completed: boolean, method: string, proprietor: string, email: string, address: string, payment_code?: string) {
    return this.http.post(API_URL + '/save-payment', {
      token,
      product,
      amount,
      completed,
      method,
      proprietor,
      email,
      address,
      payment_code
    }).pipe(map(data => {
      return data;
    }));
  }

  /*onPaymentMethod(ev: PaymentRequestPaymentMethodEvent) {
    this.createPaymentIntent()
      .pipe(
        switchMap((pi) => {
          return this.stripeService
            .confirmCardPayment(
              pi.client_secret,
              {payment_method: ev.paymentMethod.id},
              {handleActions: false}
            )
            .pipe(
              switchMap((confirmResult) => {
                if (confirmResult.error) {
                  // Report to the browser that the payment failed,
                  // prompting it to re-show the payment interface,
                  // or show an error message and close the payment.
                  ev.complete('fail');
                  return of({
                    error: new Error('Error Confirming the payment'),
                  });
                } else {
                  // Report to the browser that the confirmation was
                  // successful, prompting it to close the browser
                  // payment method collection interface.
                  ev.complete('success');
                  // Let Stripe.js handle the rest of the payment flow.
                  return this.stripeService.confirmCardPayment(
                    pi.client_secret
                  );
                }
              })
            );
        })
      )
      .subscribe((result) => {
        if (result.error) {
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
        }
      });
  }*/

}
