import {Component, Input, OnInit} from '@angular/core';
import {FitterModel} from "@app/models/fitter.model";
import {SportService} from "@app/pages/sport/sport.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SportModel} from "@app/models/sport.model";
import {FitterService} from "@app/services/fitter.service";
import {CommunicationService} from "@app/helper/communication.service";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'fitter-modal',
  templateUrl: './fitter-modal.component.html',
  styleUrls: ['./fitter-modal.component.scss'],
})
export class FitterModalComponent implements OnInit {

  @Input() fitterData: FitterModel
  public fitterForm: FormGroup;

  public years: number[] = [
    1925, 1926, 1927, 1928, 1929, 1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938, 1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023
  ]

  public allSportData: SportModel[];

  public isFitterLoading: boolean = false;
  public isEditAddressVisible: boolean = false;

  constructor(
    private sportService: SportService,
    private formBuilder: FormBuilder,
    private fitterService: FitterService,
    private communicationService: CommunicationService,
    private modalCtrl: ModalController,
  ) {
    this.fitterForm = this.formBuilder.group({
      name: [null, Validators.required],
      surname: [null, Validators.required],
      sex: ['M', Validators.required],
      birth_year: [null, Validators.required],
      city_name: [null, Validators.required],
      state_code: [null, Validators.required],
      country_name: [null, Validators.required],
      favorite_exercise: [''],
      sport_id: [null],
    })
  }

  ngOnInit() {
    this.sportService.getAllSport('[{"property": "name", "direction": "ASC"}]').subscribe({
      next: (allSport) => {
        this.allSportData = allSport
      }
    })
    if (this.fitterData) {
      this.fitterForm.patchValue(this.fitterData);
      this.fitterForm.get('sport_id').patchValue(this.fitterData?.sport?.id);
    }
  }

  async dismissModal() {
    await this.modalCtrl.dismiss()
  }

  saveFitter() {
    this.isFitterLoading = true;
    let id = 0;
    if (this.fitterData) {
      id = this.fitterData.id
    }
    this.fitterService.saveFitter(id, this.fitterForm.value).subscribe({
      next: () => {
        if (this.fitterData) {
          this.communicationService.toast('edit', 'success').then(async () => {
            await this.modalCtrl.dismiss();
            this.ngOnInit()
          })
        } else {
          this.communicationService.toast('fitter', 'success').then(async () => {
            await this.modalCtrl.dismiss();
            this.ngOnInit()
          })
        }
        this.isFitterLoading = false;
      }, error: err => {
        console.error(err)
        this.isFitterLoading = false;
      }
    })
  }

  newAddressEmitted(addressForm) {
    this.fitterForm.patchValue({
      state_code: addressForm.state_code || null,
      city_name: addressForm.city_name || null,
      country_name: addressForm.country_name || null,
    })
    this.fitterForm.markAsDirty()
  }

}
