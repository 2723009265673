<div class="inner-content">
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button (click)="closeModal()" class="ion-btn-tertiary fit-shadow" size="small">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="d-flex justify-content-center gap-3 flex-column p-4">
    <img alt="" class="align-self-center" src="assets/img/request/welcome.svg" width="400">
    <div class="mx-4 text-center">
      <h1 class="m-0 bold "> {{'PERMITS_REQUEST.FIRST_ACCESS.TITLE' | translate}} </h1>
    </div>
    <div class="text-center">
      <em>{{'PERMITS_REQUEST.FIRST_ACCESS.LOGIN' | translate }}</em>
    </div>
    <div class="row gap-3 g-0">
      <div class="col p-0">
        <ion-button (click)="onFacebookLogin()" class="facebook-bg-color-button" expand="block"
                    title="Disabilitato momentaneamente">
          <ion-icon name="facebook"></ion-icon>
        </ion-button>
      </div>
      <div class="col p-0">
        <ion-button (click)="onGoogleLogin()" class="google-bg-color-button" expand="block">
          <ion-icon name="google"></ion-icon>
        </ion-button>
      </div>
      <div class="col-12 p-0">
        <ion-button (click)="showLogin()" class="ion-btn-primary" expand="block">
          <ion-icon class="me-3" name="envelope"></ion-icon>
          E-mail
        </ion-button>
      </div>
    </div>
    <div class="text-center">
      <em>{{'GENERAL.OR' | translate}}</em>
    </div>
    <div class="row mt-2 g-0">
      <div class="col-md-12">
        <ion-button (click)="onLoginChangeRegister()" class="ion-btn-secondary" expand="block">
          Registrati
        </ion-button>
      </div>
    </div>
  </div>
</div>
