<div class="container p-0">
  <div *ngIf="Capacitor.getPlatform() === 'web'" class="mt-3 app-banner p-5">
    <h2 class="bold montserrat my-auto fs-1 text-white text-shadow-dark">Scarica l'app di <br> Fitadvisor</h2>
    <h5 class="text-white text-shadow-dark">
      Porta Fitadvisor sempre con te grazie alla nuova applicazione.
      <br>
      <span class="bold">
        Scaricala ora!
      </span>
    </h5>
    <div class="mt-5 d-flex gap-3">
      <ion-button class="ion-btn-primary"
                  href="https://play.google.com/store/apps/details?id=io.fitadvisor.app&pcampaignid=web_share"
                  target="_blank">
        <ion-icon class="me-2" name="google-play"></ion-icon>
        Scarica per Android
      </ion-button>
      <ion-button class="ion-btn-secondary"
                  href="https://apps.apple.com/it/app/fitadvisor/id6464032136?platform=iphone"
                  target="_blank">
        <ion-icon class="me-2" name="app-store-ios"></ion-icon>
        Scarica per Iphone
      </ion-button>
    </div>
  </div>
  <div class="row p-0 g-0 gap-3 mt-3">
    <div *ngIf="bannerMediaArray?.length > 0" class="col-lg bg-white">
      <h2 class="bold montserrat my-auto size-23 p-3">Media partner</h2>
      <div *ngFor="let mediaPartner of bannerMediaArray" class="p-2 p-lg-4 text-center d-flex justify-content-center">
        <div [ngSwitch]="mediaPartner.interaction_type">
          <div *ngSwitchCase="'pop-up'">
            <!--<a title="Pagina radio bruno" [id]="'open-modal' + banner.id">
              <img alt="sponsor banner" class="object-fit-contain"
                   [src]="'assets/img/partner/' + banner.image +'.webp'">
            </a>
            <ion-modal [trigger]="'open-modal' + banner.id" cssClass="auto-height" mode="ios">
              <ng-template>
                <div class="inner-content">
                  <ion-icon name="xmark" class="fs-2 position-absolute end-0 m-2 opacity-25 z-index-99 pointer"
                            (click)="modalController.dismiss()"></ion-icon>
                  <div class="modal-body p-4">
                    <div class="d-flex gap-4 align-items-center">
                      <h5 class="m-0 fw-bolder fs-3 ">{{banner.title}}</h5>
                    </div>
                    <hr class="bg-dark my-4">
                    <p class="fs-6 m-0">{{banner.text}}</p>
                    <div class="d-flex justify-content-center justify-content-md-end mt-4">
                      <ion-button [href]="banner.link" [title]="banner.link_text" class="ion-btn-primary"
                                  data-toggle="tooltip" mode="md" target="_blank" (click)="modalController.dismiss()">
                        {{banner.link_text}}
                      </ion-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ion-modal>-->
          </div>
          <div *ngSwitchCase="'external_link'">
            <a [href]="mediaPartner.link" [title]="mediaPartner?.title" data-toggle="tool-tip" rel="nofollow"
               target="_blank">
              <img [alt]="mediaPartner?.title" [src]="'assets/img/partner/media/' + mediaPartner.image +'.webp'"
                   class="object-fit-contain" height="300">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bannerArray?.length > 0" class="col-lg bg-white">
      <h2 class="bold montserrat my-auto size-23 p-3">Partner</h2>
      <div *ngFor="let mediaPartner of bannerArray" class="p-2 p-lg-4 text-center d-flex justify-content-center">
        <div [ngSwitch]="mediaPartner.interaction_type">
          <div *ngSwitchCase="'pop-up'">
            <a (click)="setIsOpen(true, mediaPartner.id)" [title]="mediaPartner?.title" data-toggle="tool-tip">
              <img [alt]="mediaPartner?.title" [src]="'assets/img/partner/' + mediaPartner.image +'.webp'"
                   class="object-fit-contain"
                   height="300">
            </a>
            <ion-modal (willDismiss)="setIsOpen(false, mediaPartner.id)" [isOpen]="isOpen.includes(mediaPartner.id)"
                       cssClass="auto-height" mode="ios">
              <ng-template>
                <div class="inner-content">
                  <ion-icon (click)="setIsOpen(false, mediaPartner.id)"
                            class="fs-2 position-absolute end-0 m-2 opacity-25 z-index-99 pointer"
                            name="xmark"></ion-icon>
                  <div class="modal-body p-4">
                    <div class="d-flex gap-4 align-items-center">
                      <h5 class="m-0 fw-bolder fs-3 ">{{mediaPartner.title}}</h5>
                    </div>
                    <hr class="bg-dark my-4">
                    <p class="fs-6 m-0">{{mediaPartner.text}}</p>
                    <div class="d-flex justify-content-center justify-content-md-end mt-4 mb-5">
                      <ion-button (click)="modalController.dismiss()" [href]="mediaPartner.link"
                                  [title]="mediaPartner.link_text"
                                  class="ion-btn-primary" data-toggle="tooltip" mode="md" target="_blank">
                        {{mediaPartner.link_text}}
                      </ion-button>
                    </div>
                    <newsletter-form>
                    </newsletter-form>
                  </div>
                </div>
              </ng-template>
            </ion-modal>
          </div>
          <!--<div *ngSwitchCase="'external_link'">
            <a [href]="mediaPartner.link" rel="nofollow" target="_blank" data-toggle="tool-tip"
               [title]="mediaPartner?.title">
              <img [alt]="mediaPartner?.title" [src]="'assets/img/partner/media/' + mediaPartner.image +'.webp'">
            </a>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

