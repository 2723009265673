import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {StorageService} from "@app/helper/storage.service";
import {map, Observable} from "rxjs";
import {API_URL} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private token: string;

  constructor(
    private storage: StorageService,
    private http: HttpClient,
  ) {
  }

  /**
   * @param data - parametri da aggiungere alla get
   * @returns Header completo da aggiungere alla chiamata: headers + params
   */
  getParams(data: Record<string, any>): { params: HttpParams } {
    let params = new HttpParams();
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        if (value instanceof Array) {
          params = params.set(key, JSON.stringify(value));
        } else {
          params = params.set(key, value?.toString());
        }
      }
    });
    return {params: params};
  }

  /**
   * @param body - parametri da aggiungere alla get
   * @returns Header completo da aggiungere alla chiamata: headers + params
   */
  setTokenAndParams(body: Record<string, any>): { headers: HttpHeaders, params: HttpParams } {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token
    );

    if (localStorage.getItem('impersonateUser')) {
      headers = headers.append(
        'X-Switch-User',
        String(localStorage.getItem('impersonateUser'))
      );
    }

    headers = headers.append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    headers = headers.append('Accept', 'application/json');

    let params = new HttpParams();
    Object.entries(body).forEach(([key, value]) => {
      //console.log(value);
      params = params.set(key, value?.toString());
    });
    return {headers: headers, params: params};
  }

  /**
   * @param body - parametri da aggiungere alla get
   * @returns Header completo da aggiungere alla chiamata: headers + params
   */
  setParams(body: Record<string, any>): { params: HttpParams } {
    let params = new HttpParams();
    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        if (value instanceof Array) {
          params = params.set(key, JSON.stringify(value));
        } else {
          params = params.set(key, value?.toString());
        }
      }
    });
    return {params: params};
  }

  setParamsAndObsResponse(body: Record<string, any>): { params: HttpParams, observe: 'response' } {
    let params = new HttpParams();
    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        params = params.set(key, value?.toString());
      }
    });
    return {params: params, observe: 'response'};
  }

  /**
   * @returns Header completo da aggiungere alla chiamata: headers + params
   */
  setToken(): { headers: HttpHeaders } {
    let headers: HttpHeaders = new HttpHeaders();

    if (localStorage.getItem('impersonateUser')) {
      headers = headers.append(
        'X-Switch-User',
        String(localStorage.getItem('impersonateUser'))
      );
    }

    headers = headers.append(
      'Authorization',
      'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token
    );
    headers = headers.append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    headers = headers.append('Accept', 'application/json');
    return {headers: headers};
  }

  /**
   * @returns Header completo da aggiungere alla chiamata: headers + params
   */
  setTokenAndProgress(): {
    headers: HttpHeaders,
    reportProgress: boolean,
    responseType?: "arraybuffer" | "blob" | "json" | "text"
  } {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token
    );

    if (localStorage.getItem('impersonateUser')) {
      headers = headers.append(
        'X-Switch-User',
        String(localStorage.getItem('impersonateUser'))
      );
    }

    headers = headers.append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    headers = headers.append('Accept', 'application/json');
    return {headers: headers, reportProgress: true, responseType: "json"};
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  // 👇️ format as "DD-MM-YYYY hh:mm:ss"
  formatDate(raw_date: Date, datetime?: boolean, format_reversed?: boolean) {
    const date = this.checkIfDateElseTransform(raw_date)
    if (datetime) {
      if (format_reversed) {
        return (
          [
            date?.getFullYear(),
            this.padTo2Digits(date?.getMonth() + 1),
            this.padTo2Digits(date?.getDate()),
          ].join('-') +
          ' ' +
          [
            this.padTo2Digits(date?.getHours()),
            this.padTo2Digits(date?.getMinutes()),
            this.padTo2Digits(date?.getSeconds()),
          ].join(':')
        );
      } else {
        return (
          [
            this.padTo2Digits(date?.getDate()),
            this.padTo2Digits(date?.getMonth() + 1),
            date?.getFullYear(),
          ].join('-') +
          ' ' +
          [
            this.padTo2Digits(date?.getHours()),
            this.padTo2Digits(date?.getMinutes()),
            this.padTo2Digits(date?.getSeconds()),
          ].join(':')
        );
      }
    } else {
      if (format_reversed) {
        return (
          [
            date?.getFullYear(),
            this.padTo2Digits(date?.getMonth() + 1),
            this.padTo2Digits(date?.getDate()),
          ].join('-')
        );
      } else {
        return (
          [
            this.padTo2Digits(date?.getDate()),
            this.padTo2Digits(date?.getMonth() + 1),
            date?.getFullYear(),
          ].join('-')
        );
      }
    }
  }

  checkIfDateElseTransform(raw_date) {
    if (typeof raw_date === 'object' && raw_date !== null && 'getDate' in raw_date) {
      return raw_date
    } else {
      return new Date(raw_date)
    }
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  invertColor(hex) {
    if (hex?.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex?.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex?.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);

    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }

  padZero(str, len?) {
    len = len || 2;
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  isDateInPast(dateToCheck: Date): boolean {
    const today = new Date();
    // Imposta l'ora di entrambe le date a mezzanotte per confrontare solo le date e non gli orari
    today.setHours(0, 0, 0, 0);
    dateToCheck.setHours(0, 0, 0, 0);

    return dateToCheck < today;
  }

  requestContacts(id: number, type: string, token: string): Observable<any> {
    return this.http.post<any>(API_URL + '/request_contacts/' + id, {
      type,
      token
    }).pipe(map(res => {
      return res;
    }));
  }

  requestOperatorRechargePocketMoney(id: number, type: string, token: string): Observable<any> {
    return this.http.post<any>(API_URL + '/request_operator_recharge_pocket/' + id, {
      type,
      token
    }).pipe(map(res => {
      return res;
    }));
  }


}
