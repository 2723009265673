import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Injectable, Input} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {AuthService} from "@app/services/auth.service";
import {CommunicationService} from "@app/helper/communication.service";
import {UserModel} from "@app/models/user.model";

@Injectable({providedIn: 'root'})
export class AuthGuard {

  /* USER */
  @Input() user: UserModel;
  isAuthenticated: boolean = false;
  userSub: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private communicationService: CommunicationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem('userInfo')) {
      if (!this.authService.tokenExpirationCheck(JSON.parse(localStorage.getItem('userInfo')).token)) {
        return true;
      } else {
        this.router.navigate(['']).then(() => {
          this.communicationService.customErrorToast('EXPIRED_JWT_TOKEN', 'danger').then();
        });
        return false;
      }
    } else {
      this.router.navigate(['']).then(() => {
        this.communicationService.customErrorToast('NOT_LOGGED_IN', 'danger').then();
      });
      return false;
    }

    /*canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.checkAuthentication();

      if (!this.isAuthenticated) {
        console.log('errpre')
      }
      if (this.isAuthenticated) {
        console.log('va bene')
      }

      if (!this.isAuthenticated) {
        this.communicationService.warnToast('Login necessario').then();
        this.authService.logout().then(() => {
          return this.router.createUrlTree(['']);
        });
      }
      return true;
    }

    checkAuthentication() {
      this.authService.user_obs.subscribe(user => {
        console.log(user)
      })
    }*/

    /*  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this.authService.checkAuthentication().then(res => {
          if (!res) {
            this.communicationService.warnToast(' Autenticazione necessaria').then();
            this.authService.logout().then(() => {
              return this.router.createUrlTree(['']);
            });
          }
        });
        return true;
      }*/

  }
}
