import {Inject, Pipe, PipeTransform, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from "@angular/common";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";

@Pipe({
  name: 'kwDate',
  pure: false
})
export class KwDatePipe implements PipeTransform {

  /*
    private lang: string = navigator.language || window.navigator.language;
  */
  private lang: string = 'it-IT';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (!isPlatformServer(PLATFORM_ID)) {
      this.lang = window.navigator.language.slice(0, 2)
    }
  }

  transform(value: any, pattern: string = 'LLLL', language: string = this.lang): any {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const zonedDate = utcToZonedTime(new Date(value), timeZone)
    return format(zonedDate, pattern);
  }

}
