<!--
<ion-toolbar color="secondary">
  <ion-buttons slot="end">
    <ion-button slot="icon" (click)="closeModal()">
      <ion-icon color="light" name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title class="montserrat text-white text-wrap">
    Registrati
  </ion-title>
</ion-toolbar>
-->
<div class="inner-content p-3">
  <!--<div class="mb-4 text-center">
    <img alt="FitAdvisor" height="20" src="assets/img/logo/logo.png">
  </div>-->
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button (click)="closeModal()" class="ion-btn-tertiary fit-shadow" size="small">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div *ngIf="Capacitor.getPlatform() !== 'ios'" class="row mb-3 gap-2 g-0">
    <div class="col-12 col-sm my-auto">
      <ion-button (click)="onFacebookLogin()" class="facebook-bg-color-button" expand="block">
        <ion-icon class="me-3" name="facebook" slot="start"></ion-icon>
        Facebook
      </ion-button>
    </div>
    <div class="col-12 col-sm my-auto">
      <ion-button (click)="onGoogleLogin()" class="google-bg-color-button" expand="block">
        <ion-icon class="me-3" name="google" slot="start"></ion-icon>
        Google
      </ion-button>
    </div>
  </div>

  <div *ngIf="Capacitor.getPlatform() !== 'ios'" class="text-center">
    <em>oppure registrati tramite email</em>
  </div>

  <div class="p-2 mt-3 bg-light border-light rounded">
    <form (ngSubmit)="onSubmit()" [formGroup]="signUpForm" novalidate>
      <div class="mb-2">
        <div class="form-group">
          <label
            [class.text-danger]="signUpForm.get('name').invalid && signUpForm.get('name').touched"
            class="my-2 ">
            Nome utente <span class="text-danger"> *</span>
          </label>
          <div class="position-relative">
            <input
              [class.is-invalid]="signUpForm.get('name').invalid && (signUpForm.get('name').dirty || signUpForm.get('name').touched)"
              class="form-control"
              formControlName="name"
              placeholder="Nome utente" type="text"/>
          </div>
          <small
            *ngIf="signUpForm.get('name').hasError('required') && (signUpForm.get('name').dirty || signUpForm.get('name').touched)"
            class="text-danger p-0 mt-1">
            È obbligatorio inserire un nome utente
          </small>
          <small
            *ngIf="signUpForm.get('name').hasError('pattern') && (signUpForm.get('name').dirty || signUpForm.get('name').touched)"
            class="text-danger p-0 mt-1">
            Lunghezza minima 5 caratteri e una massima di 20. <br>
            Il nome utente può contenere solo numeri ( 0-9 ), lettere ( A-z ) e underscore ( _ ).<br>
            Il primo carattere deve essere una lettera. <br>
          </small>
        </div>
      </div>
      <div class="mb-2">
        <div class="form-group">
          <label
            [class.text-danger]="signUpForm.get('email').invalid && signUpForm.get('email').touched"
            class="my-2 ">
            E-mail <span class="text-danger"> *</span>
          </label>
          <input
            [class.is-invalid]="signUpForm.get('email').invalid && (signUpForm.get('email').dirty || signUpForm.get('email').touched)"
            class="form-control" formControlName="email" placeholder="E-mail" type="text"/>
          <small
            *ngIf="signUpForm.get('email').hasError('required') && (signUpForm.get('email').dirty || signUpForm.get('email').touched)"
            class="text-danger p-0 mt-1">
            È obbligatorio inserire una e-mail
          </small>
          <small
            *ngIf="signUpForm.get('email').hasError('pattern') && (signUpForm.get('email').dirty || signUpForm.get('email').touched)"
            class="text-danger p-0 mt-1">
            E-mail mal formattata, controlla che sia scritta correttamente
          </small>
        </div>
      </div>
      <div class="mb-2">
        <div class="form-group">
          <label
            [class.text-danger]="signUpForm.get('password').invalid &&  signUpForm.get('password').touched"
            class="my-2 ">
            Password <span class="text-danger"> *</span>
          </label>
          <div class="position-relative">
            <input
              [class.is-invalid]="signUpForm.get('password').invalid && (signUpForm.get('password').dirty || signUpForm.get('password').touched)"
              class="form-control bg-img-none" placeholder="Password"
              formControlName="password"
              name="password" type="{{isPasswordVisible}}"/>
            <ion-icon (click)="changeIsPasswordVisible()" *ngIf='isPasswordVisible === "password"'
                      name="eye"></ion-icon>
            <ion-icon (click)="changeIsPasswordVisible()" *ngIf='isPasswordVisible === "text"'
                      name="eye-slash"></ion-icon>
          </div>
          <small
            *ngIf="signUpForm.get('password').hasError('required') && (signUpForm.get('password').dirty || signUpForm.get('password').touched)"
            class="text-danger p-0 mt-1">
            La password è obbligatoria
          </small>
          <small
            *ngIf="signUpForm.get('password').hasError('pattern') && (signUpForm.get('password').dirty || signUpForm.get('password').touched)"
            class="text-danger p-0 mt-1">
            La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera maiuscola (A-Z),
            una
            lettera minuscola (a-z) un numero (0-9) e un carattere speciale (!_?£$-;.).
          </small>
        </div>
      </div>
      <div class="mb-2">
        <div class="form-group">
          <label
            [class.text-danger]="signUpForm.get('password_repeat').invalid &&  signUpForm.get('password_repeat').touched"
            class="my-2 ">
            Ripeti password <span class="text-danger"> *</span>
          </label>
          <div class="position-relative">
            <input
              [class.is-invalid]="signUpForm.get('password_repeat').invalid && (signUpForm.get('password_repeat').dirty || signUpForm.get('password_repeat').touched)"
              class="form-control bg-img-none" placeholder="Ripeti password"
              formControlName="password_repeat"
              type="{{isRepeatPasswordVisible}}"/>
            <ion-icon (click)="changeIsRepeatPasswordVisible()"
                      *ngIf='isRepeatPasswordVisible === "password"'
                      name="eye"></ion-icon>
            <ion-icon (click)="changeIsRepeatPasswordVisible()" *ngIf='isRepeatPasswordVisible === "text"'
                      name="eye-slash"></ion-icon>
          </div>
          <small
            *ngIf="signUpForm.get('password_repeat').hasError('required') && (signUpForm.get('password_repeat').dirty || signUpForm.get('password_repeat').touched)"
            class="text-danger p-0 mt-1">
            È obbligatorio ripetere la password
          </small>
          <small
            *ngIf="(this.signUpForm.get('password').value !== this.signUpForm.get('password_repeat').value) && (signUpForm.get('password_repeat').dirty || signUpForm.get('password_repeat').touched)"
            class="text-danger p-0 mt-1">
            Le password non coincidono
          </small>
        </div>
      </div>
      <div class="form-check my-4 d-flex gap-3">
        <input class="form-check-input pointer" formControlName="will_be_operator" id="will_be_operator"
               type="checkbox">
        <label class="form-check-label pointer fit-content-width" for="will_be_operator">
          Sono un operatore
        </label>
        <ion-icon class="opacity-25 pointer" id="click-info-operator" name="circle-info"></ion-icon>
        <ion-popover class="centered-popover" mode="md" trigger="click-info-operator" triggerAction="click">
          <ng-template>
            <ion-content class="ion-padding">
              Un operatore è un proprietario o rappresentante di una Asd,
              Ssd, Ssd arl,Srl, Sas, Srls, Centro sportivo, Palestra, Box di Crossfit, Maneggio, Centro
              Ippico, Studio ,
              Personal trainer, Boutique Fitness, Palazzetto dello sport, Campo sportivo o di una Palestra
              comunale
            </ion-content>
          </ng-template>
        </ion-popover>
      </div>
      <div class="form-check my-4 d-flex gap-3">
        <input [checked]="true" [disabled]="true" class="form-check-input pointer opacity-100" id="privacy"
               type="checkbox">
        <label class="form-check-label pointer fit-content-width opacity-100" for="privacy">
          Ho letto la
          <a class="bold" href="https://www.fitadvisor.it/info/note-legali" rel="nofollow" target="_blank">Privacy
            Policy</a>
          e acconsento al trattamento dei miei dati personali per le finalità sopra descritte.
        </label>
      </div>
      <div class="row mt-3 centered-middle pb-2">
        <div class="col-6">
          <ion-button type="submit" class="ion-btn-secondary" expand="block"
                      [disabled]="signUpForm.invalid || isLoading || (this.signUpForm.get('password').value !== this.signUpForm.get('password_repeat').value)">
            <span *ngIf="!isLoading">Registrati</span>
            <fit-spinner-light *ngIf="isLoading"></fit-spinner-light>
          </ion-button>
        </div>
      </div>
    </form>
  </div>

  <!--LOGIN-->
  <div class="text-center mt-2">
    <em>Se sei già registrato accedi</em>
  </div>
  <div class="col-12 mt-3">
    <ion-button class="ion-btn-tertiary" expand="block" (click)="onRegisterChangeLogin()">
      Accedi
    </ion-button>
  </div>
</div>

