<ng-template #t let-fill="fill" class="shoe-rating">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
    <svg id="master-artboard" viewBox="0 0 120 100" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40px"
         height="40px" fill="#3966bf"><g
      transform="matrix(3.2751935849383504, 0, 0, 3.2751935849383504, 0.8749678744899594, 21.023256995525745)"><path
      class="st0"
      d="M29.7,12.9l-0.2-0.2l-0.1,0.2c-0.8,1.6-3.6,2.5-5.8,2.5C20.8,15.4,7,15,5.2,14.9c-0.4,0-0.7,0-1.2,0&#10;    c-0.3,0-0.5,0-0.8,0c-0.2,0-0.4,0-0.6,0c-0.6,0-1.1-0.2-1.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.2,0-0.3,0.1&#10;    c-0.1,0.1-0.1,0.2-0.1,0.5c0,1.9,1,2,2.4,2c0.2,0,0.4,0,0.6,0c0.2,0,0.5,0,0.8,0c0.4,0,0.8,0,1.2,0c1.8,0.1,15.7,0.5,18.5,0.5&#10;    c1.4,0,3-0.4,4.2-1c1.4-0.7,2.2-1.7,2.2-2.8C30,13.4,29.9,13.1,29.7,12.9z"/><path
      class="st0"
      d="M2.4,14c0.2,0,0.4,0,0.6,0c0.2,0,0.5,0,0.8,0c0.4,0,0.8,0,1.1,0c1.8,0.1,15.5,0.5,18.3,0.5c1.4,0,3-0.4,4.2-1&#10;    c1.4-0.7,2.1-1.7,2.1-2.8c0-1.5-1.7-2-3.6-2.5c-1-0.3-2-0.6-2.9-1c-0.4-0.2-1-0.5-1.7-0.9c0,0,0,0,0-0.1c0-0.6-0.5-1.1-1.1-1.1&#10;    c-0.3,0-0.5,0.1-0.7,0.3c-0.3-0.1-0.5-0.3-0.8-0.4c0-0.6-0.5-1.1-1.1-1.1c-0.2,0-0.5,0.1-0.7,0.2c-0.2-0.1-0.5-0.2-0.7-0.4&#10;    c0,0,0-0.1,0-0.1c0-0.6-0.5-1.1-1.1-1.1c-0.3,0-0.6,0.1-0.8,0.3c-2.4-1.2-4.4-2.1-4.6-2.2C9.5,0.5,9.4,0.4,9.2,0.4&#10;    C9,0.4,8.8,0.5,8.7,0.7L7.4,2.5C7.2,2.7,7.1,3,7.2,3.2c0.1,0.5,0.6,0.8,1,1.1c0.2,0.1,0.4,0.3,0.4,0.3l0,0c-0.2,0.2-0.9,0.5-2,0.5&#10;    C6,5.2,5.4,5.1,4.8,4.8c-0.7-0.3-1-1-1.3-1.6C3.3,2.8,3.1,2.4,2.7,2.4c-0.3,0-0.6,0.3-1.1,0.9C-0.1,5.4,0,8.8,0,11.2&#10;    c0,0.3,0,0.5,0,0.8C0.1,13.8,1,14,2.4,14z M16.9,7.2c0,0,0.2-0.2,0.5-0.1c0.2,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0,0.3,0,0.3l-4.5,4.1&#10;    c0,0-0.2,0.2-0.5,0.2c-0.4,0-1.3,0-1.7,0c-0.3,0,0-0.2,0-0.2S16.9,7.2,16.9,7.2z M13.5,6.2c0,0,0.3-0.3,0.8-0.2&#10;    c0.2,0.1,0.7,0.2,1,0.3c0.3,0.1,0,0.4,0,0.4l-5.5,5c0,0-0.1,0.2-0.6,0.2c-0.5,0-1.4,0-1.8,0c-0.2,0,0-0.2,0-0.2L13.5,6.2z"/></g></svg>
    </span>
    <svg id="master-artboard" viewBox="0 0 120 100" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40px"
         height="40px" fill="#808080"><g
      transform="matrix(3.2751935849383504, 0, 0, 3.2751935849383504, 0.8749678744899594, 21.023256995525745)"><path
      class="st0"
      d="M29.7,12.9l-0.2-0.2l-0.1,0.2c-0.8,1.6-3.6,2.5-5.8,2.5C20.8,15.4,7,15,5.2,14.9c-0.4,0-0.7,0-1.2,0&#10;    c-0.3,0-0.5,0-0.8,0c-0.2,0-0.4,0-0.6,0c-0.6,0-1.1-0.2-1.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.2,0-0.3,0.1&#10;    c-0.1,0.1-0.1,0.2-0.1,0.5c0,1.9,1,2,2.4,2c0.2,0,0.4,0,0.6,0c0.2,0,0.5,0,0.8,0c0.4,0,0.8,0,1.2,0c1.8,0.1,15.7,0.5,18.5,0.5&#10;    c1.4,0,3-0.4,4.2-1c1.4-0.7,2.2-1.7,2.2-2.8C30,13.4,29.9,13.1,29.7,12.9z"/><path
      class="st0"
      d="M2.4,14c0.2,0,0.4,0,0.6,0c0.2,0,0.5,0,0.8,0c0.4,0,0.8,0,1.1,0c1.8,0.1,15.5,0.5,18.3,0.5c1.4,0,3-0.4,4.2-1&#10;    c1.4-0.7,2.1-1.7,2.1-2.8c0-1.5-1.7-2-3.6-2.5c-1-0.3-2-0.6-2.9-1c-0.4-0.2-1-0.5-1.7-0.9c0,0,0,0,0-0.1c0-0.6-0.5-1.1-1.1-1.1&#10;    c-0.3,0-0.5,0.1-0.7,0.3c-0.3-0.1-0.5-0.3-0.8-0.4c0-0.6-0.5-1.1-1.1-1.1c-0.2,0-0.5,0.1-0.7,0.2c-0.2-0.1-0.5-0.2-0.7-0.4&#10;    c0,0,0-0.1,0-0.1c0-0.6-0.5-1.1-1.1-1.1c-0.3,0-0.6,0.1-0.8,0.3c-2.4-1.2-4.4-2.1-4.6-2.2C9.5,0.5,9.4,0.4,9.2,0.4&#10;    C9,0.4,8.8,0.5,8.7,0.7L7.4,2.5C7.2,2.7,7.1,3,7.2,3.2c0.1,0.5,0.6,0.8,1,1.1c0.2,0.1,0.4,0.3,0.4,0.3l0,0c-0.2,0.2-0.9,0.5-2,0.5&#10;    C6,5.2,5.4,5.1,4.8,4.8c-0.7-0.3-1-1-1.3-1.6C3.3,2.8,3.1,2.4,2.7,2.4c-0.3,0-0.6,0.3-1.1,0.9C-0.1,5.4,0,8.8,0,11.2&#10;    c0,0.3,0,0.5,0,0.8C0.1,13.8,1,14,2.4,14z M16.9,7.2c0,0,0.2-0.2,0.5-0.1c0.2,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0,0.3,0,0.3l-4.5,4.1&#10;    c0,0-0.2,0.2-0.5,0.2c-0.4,0-1.3,0-1.7,0c-0.3,0,0-0.2,0-0.2S16.9,7.2,16.9,7.2z M13.5,6.2c0,0,0.3-0.3,0.8-0.2&#10;    c0.2,0.1,0.7,0.2,1,0.3c0.3,0.1,0,0.4,0,0.4l-5.5,5c0,0-0.1,0.2-0.6,0.2c-0.5,0-1.4,0-1.8,0c-0.2,0,0-0.2,0-0.2L13.5,6.2z"/></g></svg>
  </span>
</ng-template>
<ngb-rating [(rate)]="average_vote" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
