<ion-header>
  <ion-toolbar class="h-auto" color="secondary">
    <ion-buttons slot="end">
      <ion-button slot="icon" (click)="modalCtrl.dismiss()">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="montserrat text-white text-wrap">
      Scegli quale società sportiva vedere
    </ion-title>
  </ion-toolbar>
</ion-header>
<div class="inner-content">
  <div class="modal-body pb-3 px-4" @fadeIn *ngIf="sportClubArray; else sportClubName">
    <ion-button *ngFor="let sportClub of sportClubArray" class="ion-btn-primary mt-3" expand="block"
                (click)="chooseActiveSportClub(sportClub)">
      {{sportClub.name}}
    </ion-button>
  </div>
  <ng-template #sportClubName>
    <div class="modal-body py-3 px-4">
      <ngx-skeleton-loader class="my-2" [theme]="{ margin: '0', height: '45px'}">
      </ngx-skeleton-loader>
    </div>
  </ng-template>
</div>
