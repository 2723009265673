import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class FooterService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
  ) {
  }

  getFooterExtraInfo(): Observable<{ title: string, link: string }[]> {
    return this.http.get  <any>(API_URL + '/footer_extra_info').pipe(map((data: { title: string, link: string }[]) => {
      return data['elenco'];
    }));
  }

}
