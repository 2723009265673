// import the required animation functions from the angular animations module
import {animate, style, transition, trigger} from '@angular/animations';

export const slideInTopAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideInTop', [
    transition(':enter', [
      style({transform: 'translateY(-100%)'}),
      animate('400ms', style({transform: 'translateY(0%)'}))
    ]),
    transition(':leave', [
      animate('400ms', style({transform: 'translateY(-100%)'}))
    ])
  ]);
