import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from "@angular/router";
import {AuthService} from "../services/auth.service";
import {ERROR_TEXT} from "../models/error.model";
import {NavigationRouteService} from "./navigation-route.service";
import {CommunicationService} from "@app/helper/communication.service";
import {StorageService} from "@app/helper/storage.service";
import {isPlatformServer} from "@angular/common";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private refreshed_token: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    public navigationRoute: NavigationRouteService,
    private communicationService: CommunicationService,
    private storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      /*if (err.status === 403) {
        const code = err.error
        const lang = window.navigator.language.slice(0, 2)
        let msgUser = ERROR_TEXT[lang][code];
        console.warn(ERROR_TEXT[lang][code]);
        return throwError(msgUser);
      }*/

      /*if (!request.url.includes('login_check') &&
        !request.url.includes('sign_up') &&
        !request.url.includes('login_refresh') &&
        err.status === 401
      ) {
        this.handle401Error(request, next).then();
        return;
      }*/

      /*if (err.status == '403') {
        this.authService.logout().then(() => {
          this.navigationRoute.navigate('home');
          const code = err.error
          const lang = window.navigator.language.slice(0, 2)
          let msgUser = ERROR_TEXT[lang][code];
          this.communicationService.errorToast(msgUser).then(() => {
            return throwError(msgUser);
          })
        })
        return;
      }*/

      /*if (err.status === 401 || 0) {
        this.authService.logout().then(() => {
          this.navigationRoute.navigate('home');
          const code = err.error
          const lang = window.navigator.language.slice(0, 2)
          let msgUser = ERROR_TEXT[lang][code];
          this.communicationService.errorToast(msgUser).then(() => {
            return throwError(msgUser);
          })
        })
        return;
      }*/

      // if (err.status === 502) {
      //   this.navigationRoute.navigate('login');
      //   const code = err.error
      //   const lang = window.navigator.language.slice(0, 2)
      //   let msgUser = ERROR_TEXT[lang][code];
      //   console.warn(ERROR_TEXT[lang][code]);
      //
      //   return throwError(msgUser);
      // }

      const code = err?.error
      let lang = 'EN'
      if (!isPlatformServer(PLATFORM_ID)) {
        lang = window.navigator.language.slice(0, 2)
      }
      let msgUser = ERROR_TEXT[lang][code];
      console.error(err);

      if (err?.error === 'NOT_FITTER') {
        this.navigationRoute.navigate('profile')
      }
      if (err?.error === 'NOT_OPERATOR') {
        this.navigationRoute.navigate('profile')
      }

      if (msgUser) {
        this.communicationService.errorToast(msgUser).then()
        return throwError(msgUser);
      } else {
        return;
      }
    }))
  }

  /*
    private async handle401Error(request: HttpRequest<any>, next: HttpHandler) {
      console.log('inizio il refreshando')
      if (!this.isRefreshing) {
        console.debug('Refresho il JWT')
        this.isRefreshing = true;

        const user = await this.authService.getUserDataLocal
        console.debug(user)
        if (user) {
          this.authService.refreshToken(user.refresh_token).subscribe({
            next: (res: any) => {
              if (res.token) {
                this.refreshed_token = res.token;
                return this.isRefreshing = false;
              }
            }, error: err => {
              console.log(err)
            }, complete: () => {
            }
          })
        } else if (!user) {
          console.debug('no user')
          this.isRefreshing = false;
          this.authService.logout().then(() => {
            this.navigationRoute.navigate('home');
            this.communicationService.warnToast('Login richiesto').then(() => {
              return;
            })
          })
          return;
        }
      }
      if (this.refreshed_token) {
        console.debug('il token cè')
        request = request.clone({
          setHeaders: {Authorization: `Bearer ${this.refreshed_token}`}
        });
        return next.handle(request);
      }
      console.debug('no token')
    }
  */
}
