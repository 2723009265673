import {Component} from '@angular/core';

@Component({
  selector: 'fit-spinner-primary',
  template: '<lottie-player src="/assets/lottie/loading-primary.json" style="width:50px;margin:0 auto;" autoplay loop></lottie-player>',
  styles: [''],
})
export class FitSpinnerPrimaryComponent {

  constructor() {
  }

}
