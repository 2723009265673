import {ImageModel} from "@app/models/image.model";

export class SportFacilityModel {
  public id: number;
  public name: string;
  public address: string;
  public active: boolean;
  public civic_number: string;
  public cap: number;
  public phone: number;
  public email: string;
  public service: string;
  public slug: string;
  public rank_position: number;
  public lat: number;
  public lng: number;
  public hour: OpeningDays[];
  public description: string;
  public country: string;
  public country_name: string;
  public state: string;
  public state_code: string;
  public city: string;
  public city_name: string;
  public operator_id: number;
  public operator_name: string;
  public tot_review: number;
  public service_id: number[];
  public review_id: number[];
  public vote_avg: number;
  public vote_clean_avg: number;
  public vote_client_avg: number;
  public vote_equipment_avg: number;
  public image: ImageModel
  public sport_facility_category?: number;
  public place_type?: number;
  public fab_color?: string;
  public operator_sport_facility_id?: number;
  public confirmed?: boolean;
  public tot_sfs?: number;
  public distance?: number;
  public deleted: boolean;

  constructor(data: any = []) {
    if (data) {
      for (const key in data) {
        this[key] = data[key] ?? null;
      }
    }
  }

}

class OpeningDays {

  open: string;
  da: string[];
  a: string[];

  constructor(data: any = []) {
    if (data) {
      for (const key in data) {
        this[key] = data[key] ?? null;
      }
    }
  }

}
