import {Component} from '@angular/core';
import {PromoService} from "@app/services/promo.service";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {GeolocationService} from "@app/services/geolocation.service";
import {Promo_sportFacilitySportModel} from "@app/models/promo_sport-facility-sport.model";

@Component({
  selector: 'promo-component',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss'],
})
export class PromoComponent {

  public promo_data: Promo_sportFacilitySportModel[];
  private user_lat: any;
  private user_long: any;

  constructor(
    private promoComponentService: PromoService,
    public navigationRoute: NavigationRouteService,
    private geolocationService: GeolocationService
  ) {
    this.geolocationService.getUserPosition().then(position => {
      if (position) {
        this.user_lat = position.coords.latitude
        this.user_long = position.coords.longitude
      }
    })
  }

  onGetPromo() {
    const sort_promo = '[{"property": "id", "direction": "DESC"}]';
    this.promoComponentService.getLatestPromo(this.user_lat, this.user_long, 5, 1).subscribe({
      next: promoArray => {
        this.promo_data = promoArray
      }
    })
  }


}
