import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {NotificationService} from "@app/services/notification.service";

@Component({
  selector: 'request-position',
  templateUrl: './request-position.component.html',
  styleUrls: ['./request-position.component.scss'],
})
export class RequestPositionComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  dismissModal() {
    this.modalController.dismiss().then()
    localStorage.setItem('positionAccepted', String(false));
  }

  accept() {
    localStorage.setItem('positionAccepted', String(true));
    this.modalController.dismiss().then()
  }

}
