import {HTTP_INTERCEPTORS, HttpBackend, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module} from "ng-recaptcha";
import {RouteReuseStrategy, RouterModule} from "@angular/router";
import {JwtInterceptor} from "@app/helper/jwt.interceptor";
import {ErrorInterceptor} from "@app/helper/error.interceptor";
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from 'ng-lazyload-image';
import {NgProgressModule} from 'ngx-progressbar';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgChartsModule} from "ng2-charts";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as solidGauge from 'highcharts/modules/solid-gauge.src';
import {NgSelectModule} from "@ng-select/ng-select";
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {GoogleMapsModule} from "@angular/google-maps";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {AngularSplitModule} from "angular-split";
import {ComponentsModule} from "@app/components/components.module";
import {ColorPickerModule} from 'ngx-color-picker';
import {NgPipesModule} from 'ngx-pipes';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
// @ts-ignore
import * as Hammer from 'hammerjs';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from "@environments/environment";
import {FileSaverModule} from 'ngx-filesaver';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

export function playerFactory() {
  return player;
}

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, [
    'kwcalendar/translations/i18n/',
    'assets/i18n/'
  ]);
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  // @ts-ignore
  overrides = <any>{
    pan: {direction: Hammer.DIRECTION_ALL}
  }
}


// @ts-ignore
@NgModule(
  {
    declarations: [AppComponent],
    imports: [
      AppRoutingModule,
      HttpClientModule,
      ReactiveFormsModule,
      FormsModule,
      BrowserModule,
      RecaptchaModule,
      /*IonicModule.forRoot(),*/
      IonicModule.forRoot({
        mode: 'md'
      }),
      LazyLoadImageModule,
      NgProgressModule,
      NgxSkeletonLoaderModule.forRoot({
        animation: 'pulse',
        theme: {
          // Enabliong theme combination
          extendsFromRoot: true,
          'border-radius': '10px',
          'background-color': '#fff'
        },
      }),
      GoogleMapsModule,
      NgbModule,
      NgChartsModule,
      BrowserAnimationsModule,
      ChartModule,
      NgSelectModule,
      LottieModule.forRoot({player: playerFactory}),
      NgxDatatableModule,
      BrowserModule,
      AngularSplitModule,
      ComponentsModule,
      RouterModule.forRoot(routes, {enableTracing: false, initialNavigation: 'enabledBlocking'}),
      ColorPickerModule,
      RecaptchaV3Module,
      NgPipesModule,
      TranslateModule.forRoot({
        defaultLanguage: 'it',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
        }
      }),
      HammerModule,
      NgxStripeModule.forRoot(environment.stripe.pub_key),
      FileSaverModule,
      NgxMaterialTimepickerModule
    ],
    providers: [
      {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
      {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
      {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
      {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks},
      {provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Lft5QMoAAAAAPVx2C3bnF_Vk_VQ_Wd7Y7T0KpC3"},
      /*OLD*/
      /*
            {provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfXqPQlAAAAANdwweSls97Jeg04eZQU4Tkocs-n"},
      */
      {provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, solidGauge]},
      {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
      {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig},
    ],
    exports: [],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  }
)

export class AppModule {
}
