import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {WordpressService} from "@app/services/wordpress.service";
import {WPPostModel} from "@app/models/wp_post.model";
import {NavigationRouteService} from "@app/helper/navigation-route.service";

@Component({
  selector: 'component-news-home',
  templateUrl: './news-home.component.html',
  styleUrls: ['./news-home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsHomeComponent implements OnInit {

  public homepageNewsArray: WPPostModel[];

  public config = {
    spaceBetween: 30,
    slidesPerGroup: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    /*loop: true,*/
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  }

  constructor(
    private wordpressService: WordpressService,
    public navigationRoute: NavigationRouteService
  ) {
  }

  ngOnInit() {
    this.wordpressService.getPost(6, 'id', 'desc').subscribe({
      next: wpPostArray => {
        this.homepageNewsArray = wpPostArray
      }
    })
  }


}
