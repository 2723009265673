import {Component, Input, OnInit} from '@angular/core';
import {PayPalNamespace} from "@paypal/paypal-js";
import {ModalController} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CommunicationService} from "@app/helper/communication.service";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {Subscription} from "rxjs";
import {AuthService} from "@app/services/auth.service";
import {UserService} from "@app/services/user.service";
import {ScriptService} from "@app/services/script.service";
import {StorageService} from "@app/helper/storage.service";
import {SportClubModel} from "@app/models/sport-club.model";
import {PaymentService} from "@app/services/payment.service";
import {fadeInAnimation} from "@app/animations";
import {Router} from "@angular/router";
import {format} from "date-fns";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: [fadeInAnimation]
})
export class PaymentComponent implements OnInit {

  @Input() sportClubData: SportClubModel;

  /* USER */
  isAuthenticated: boolean;
  userSub: Subscription;
  public userInfo: any;
  public defaultNameSurname: string;

  public selectedTurboPackage: turboPackageModel;

  public turboPackageData = [];

  paymentForm: FormGroup

  /* TABS */
  public segment: string = 'single-purchase';
  public isPaymentAdviseOn: boolean = Boolean(localStorage.getItem('isPaymentAdviseOn'));
  public isPaymentLoading: boolean = false;
  /*PAYPAL*/
  private paypal: PayPalNamespace;
  private isLoading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private communicationService: CommunicationService,
    private navigationService: NavigationRouteService,
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private scriptService: ScriptService,
    private storage: StorageService,
    private paymentService: PaymentService,
    private router: Router
  ) {
    this.paymentForm = this.formBuilder.group({
      sportClubName: [null, Validators.required],
      sportClubVatIdentifier: ['', Validators.required],
      sportClubAddress: ['', Validators.required],
      sportClubProprietor: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.paymentService.getAllTurboPackage().subscribe(turboPackageArrayElenco => {
      const turboPackageArray: turboPackageModel[] = turboPackageArrayElenco['elenco']
      turboPackageArray.forEach(turboPackage => {
        /*if (this.router.url === '/check-up/fine' && turboPackage.id === 1) {
          this.turboPackageData.push(turboPackage)
        } else if (this.router.url !== '/check-up/fine' && turboPackage.id !== 1) {
          this.turboPackageData.push(turboPackage)
        }*/
        const date = format(new Date(), 'dd/MM/yyyy');
        const expiration_date = format(new Date(turboPackage.promo_expiration), 'dd/MM/yyyy')

        if (turboPackage.promo_expiration) {
          if (expiration_date >= date) {
            this.turboPackageData.push(turboPackage)
          }
        } else if (turboPackage.name !== 'TURBO WEB MEDIA PRIME') {
          this.turboPackageData.push(turboPackage)
        }

        this.turboPackageData = [...this.turboPackageData]
      })
      this.choosePackage(this.turboPackageData[0])
    })

    this.userSub = this.authService.user_obs.subscribe(user => {
      this.isAuthenticated = !!user;
    });

    let sportClub = JSON.parse(localStorage.getItem('sportClub'))
    if (!sportClub) {
      sportClub = this.sportClubData
    }
    if (sportClub) {
      this.paymentForm.get('sportClubName').setValue(sportClub.name)
      this.paymentForm.get('sportClubVatIdentifier').setValue(sportClub.vat_number)
      this.paymentForm.get('sportClubProprietor').setValue(sportClub.proprietor)
      if (sportClub?.cap) {
        this.paymentForm.get('sportClubAddress').setValue(sportClub.address + ' ' + sportClub.civic_number + ', ' + sportClub.cap + ' ' + sportClub.city_name + ' ' + sportClub.state_code)
      } else {
        this.paymentForm.get('sportClubAddress').setValue(sportClub.address)
      }
    }
  }

  choosePackage(turboPackage) {
    this.selectedTurboPackage = turboPackage
  }

  segmentChanged(e: any, segment?) {
    this.segment = e?.detail.value;
    if (segment) {
      this.segment = segment;
    }
    /*
        this.createPayPalButtons();
    */
  }

  createPayPalButtons() {
    this.isPaymentLoading = true
    const paymentForm = {
      company_name: this.paymentForm.get('sportClubName').value,
      vat_identification: this.paymentForm.get('sportClubVatIdentifier').value,
      address: this.paymentForm.get('sportClubAddress').value,
      proprietor: this.paymentForm.get('sportClubProprietor').value
    }
    localStorage.setItem('selectedTurboPackage', JSON.stringify(this.selectedTurboPackage))
    localStorage.setItem('paymentForm', JSON.stringify(paymentForm))
    if (this.segment === 'subscription') {
      this.paymentService.activateSubscription(this.selectedTurboPackage)
    } else if (this.segment === 'single-purchase') {
      this.paymentService.createOrder(this.selectedTurboPackage)
    }
  }

  submit() {
    if (this.paymentForm.invalid || this.paymentForm.errors) {
      this.paymentForm.markAllAsTouched()
      return this.communicationService.errorToast('Controlla di aver inserito tutti i campi').then()
    }
  }

  onModalDismiss() {
    localStorage.setItem('selectedTurboPackage', JSON.stringify(this.selectedTurboPackage))
    localStorage.setItem('paymentForm', JSON.stringify(this.paymentForm.value))
    this.paymentService.savePaymentData(this.selectedTurboPackage, false)
    this.modalCtrl.dismiss().then(() => {
      this.communicationService.warnToast('Ordine annullato').then()
    })
  }
}

export interface turboPackageModel {
  id: number,
  name: string,
  label: string,
  price: number,
  plan_id: string,
  promo_expiration: string
}
