import {Component, Input} from '@angular/core';


@Component({
  selector: 'fit-lottie',
  template: '<lottie-player src="/assets/lottie/{{name}}.json" style="width:100%;margin:0 auto;" autoplay loop></lottie-player> ',
  styles: [''],
})
export class FitLottieComponent {

  @Input() name: string = 'confetti';

  constructor() {
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
