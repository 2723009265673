<div class="container ion-bg-tertiary text-white p-4">
  <div class="mb-3">
    <img alt="Icona promozioni" class="w-75px" src="assets/img/home/promo.webp"/><br/>
    <h2 class="text-white bold montserrat">Scopri le promo in zona</h2>
  </div>
  <hr class="bg-white">
  <div class="row">
    <div class="col-12" *ngFor="let promo_item of promo_data">
      <div class="row g-0 gap-3">
        <div class="col-auto p-0 text-end">
          <div class="ion-bg-primary w-10px h-10px d-inline-block">
          </div>
        </div>
        <!--<div class="col"
             [routerLink]="navigationRoute.navigateSport(promo_item?.sport_id, 'sport', promo_item?.sport_facility_id)">
          <p class="text-white size-16 m-0"> {{promo_item?.sport_club_name}} </p>
          <h4 class="text-white bold size-16 m-0">{{promo_item?.sport_name}}</h4>
          <p class="italic mt-0 size-16 ion-color-secondary mb-3">{{promo_item?.promo_template.title}}</p>
        </div>-->
        <div class="col">
          <p class=" size-16 m-0 pointer"
             [routerLink]="'/profilo/operatore/' + promo_item?.operator_id"> {{promo_item?.sport_club_name}} </p>
          <a
            [routerLink]="'/impianti/' + promo_item?.sport_facility_slug + '/'+ promo_item.sport_facility_sport_id + '/'+ promo_item.sport_slug"
            class=" bold size-16 pointer m-0 ion-color-tertiary-contrast">{{promo_item?.sport_name}}</a>
          <p class="italic m-0 size-16 ion-color-secondary mb-3">{{promo_item?.promo_template.title}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
