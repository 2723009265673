import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FitterModel} from "@app/models/fitter.model";
import {OperatorModel} from "@app/models/operator.model";
import {CommunicationService} from "@app/helper/communication.service";
import {ModalController} from "@ionic/angular";
import {OperatorService} from "@app/services/operator.service";

@Component({
  selector: 'operator-modal',
  templateUrl: './operator-modal.component.html',
  styleUrls: ['./operator-modal.component.scss'],
})
export class OperatorModalComponent implements OnInit {

  public operatorForm: FormGroup;
  @Input() fitterData: FitterModel
  @Input() operatorData: OperatorModel

  public isFitterLoading: boolean = false;
  public isEditAddressVisible: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private modalCtrl: ModalController,
    private operatorService: OperatorService,
  ) {
    this.operatorForm = this.formBuilder.group({
      name: [null, Validators.required],
      city_name: [null, Validators.required],
      state_code: [null, Validators.required],
      country_name: [null, Validators.required],
      address: [null, Validators.required],
      phone: [null, Validators.required],
    })
  }

  ngOnInit() {
    if (this.operatorData) {
      this.operatorForm.patchValue(this.operatorData);
    }
    if (this.fitterData && !this.operatorData) {
      this.operatorForm.patchValue({
        name: this.fitterData.name + ' ' + this.fitterData.surname
      })
    }
  }

  newAddressEmitted(addressForm) {
    this.operatorForm.patchValue({
      address: addressForm.address + ', ' + addressForm.civic_number + ', ' + addressForm.city_name + ', ' + addressForm.state_code + ', ' + addressForm.country_name,
      state_code: addressForm.state_code || null,
      city_name: addressForm.city_name || null,
      country_name: addressForm.country_name || null,
    })
  }

  saveOperator() {
    this.isFitterLoading = true;
    this.operatorService.saveOperator(this.operatorData?.id, this.operatorForm.value).subscribe({
      next: () => {
        if (this.operatorData) {
          this.communicationService.toast('edit', 'success').then(async () => {
            await this.modalCtrl.dismiss();
            this.ngOnInit()
          })
        } else {
          this.communicationService.toast('operator', 'success').then(async () => {
            await this.modalCtrl.dismiss();
            this.ngOnInit()
          })
        }
        this.isFitterLoading = false;
        this.operatorForm.markAsPristine()
        this.operatorForm.markAsUntouched()
      }, error: err => {
        console.error(err)
        this.isFitterLoading = false;
      }
    })
  }

  async dismissModal() {
    await this.modalCtrl.dismiss()
  }


}
