<div class="inner-content p-3">
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button (click)="deny()" class="ion-btn-tertiary fit-shadow" size="small">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="d-flex justify-content-center gap-3 flex-column ">
    <img alt="" class="align-self-center" src="assets/img/request/app.svg" width="400">
    <div class="mx-4">
      <h1 class="m-0 bold "> {{'PERMITS_REQUEST.APP.TITLE' | translate:translateParams}} </h1>
    </div>
    <ion-button (click)="accept()" *ngIf="this.platform.is('android')" class="ion-btn-primary  m-4 mb-0">
      <ion-icon class="me-2" name="google-play"></ion-icon>
      {{'PERMITS_REQUEST.APP.ACCEPT' | translate:translateParams}}
    </ion-button>
    <ion-button (click)="accept()" *ngIf="this.platform.is('ios')" class="ion-btn-secondary  m-4 mb-0">
      <ion-icon class="me-2" name="app-store-ios"></ion-icon>
      {{'PERMITS_REQUEST.APP.ACCEPT' | translate:translateParams}}
    </ion-button>
    <ion-button (click)="deny()" class="ion-btn-light mx-4">
      {{'PERMITS_REQUEST.APP.DENY' | translate}}
    </ion-button>
    <!--<small (click)="deny()" class="opacity-75 text-center">
      {{'PERMITS_REQUEST.APP.DENY' | translate}}
    </small>-->
  </div>

</div>
