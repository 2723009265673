import {Component, OnInit} from '@angular/core';
import {AuthService} from "@app/services/auth.service";
import {SignupComponent} from "@app/pages/auth/signup/signup.component";
import {ModalController} from "@ionic/angular";
import {LoginComponent} from "@app/pages/auth/login/login.component";

@Component({
  selector: 'first-access-mobile-component',
  templateUrl: './first-access-mobile.component.html',
  styleUrls: ['./first-access-mobile.component.scss'],
})
export class FirstAccessMobileComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
  }

  /* SOCIAL LOGIN */

  onFacebookLogin(): void {
    this.authService.facebookLogin().then()
  }

  onGoogleLogin(): void {
    this.authService.googleLogin().then()
  }

  async showLogin() {
    await this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: LoginComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height'
    });
    return modal.present();
  }

  async onLoginChangeRegister() {
    await this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: SignupComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height'
    });
    return modal.present();
  }

  closeModal() {
    this.modalCtrl.dismiss().then()
  }

}
