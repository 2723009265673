import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'side-menu-trigger',
  templateUrl: './side-menu-trigger.component.html',
  styleUrls: ['./side-menu-trigger.component.scss'],
})
export class SideMenuTriggerComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
