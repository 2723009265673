import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {SportClubModel} from "@app/models/sport-club.model";
import {SportFacilityModel} from "@app/models/sport-facility.model";
import {SportFederationSportClubModel} from "@app/models/federation.model";

@Injectable({providedIn: 'root'})

export class SportClubService {

  private mySportClub = new BehaviorSubject<SportClubModel>(null);
  mySportClub_obs = this.mySportClub.asObservable();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  public get getMySportClubData() {
    return this.mySportClub.value
  }

  getBaseSportClub(query ?: string, sort?, group?, limit?, page?): Observable<{
    elenco: SportFacilityModel[],
    totale: number
  }> {
    return this.http.get<any>(API_URL + '/sportclub', this.globalService.setTokenAndParams({
      query,
      sort,
      group,
      limit,
      page
    })).pipe(map((data: { elenco: SportFacilityModel[], totale: number }) => {
      return data;
    }));
  }

  /*getAllSportClub(limit: number, property?: string, direction?: string, lat?: number, lng?: number, radius?: RangeValue): Observable<any> {
    let sort = '[{"property":"' + property + '", "direction":"' + direction + '"}]'
    return this.http.get(API_URL + '/sportclub', this.globalService.setParams({
      limit,
      lat,
      lng,
      radius,
      sort
    })).pipe(map((res) => {
      return res;
    }));
  }

  sfsSearch(max_results?: number, page?: number, property?: string, direction?: string, promo?: boolean, lat?: number, lng?: number, radius?: RangeValue, sport_array?: number[], sport_facility_array?: number[], sport_club_array?: number[], recommendation_id ?: number): Observable<any> {
    let sort = '[{"property":"' + property + '", "direction":"' + direction + '"}]'
    return this.http.get(API_URL + '/sportfacilitysport', this.globalService.getParams({
      max_results,
      page,
      sort,
      sport_array,
      promo,
      lat,
      lng,
      radius,
      sport_facility_array,
      sport_club_array,
      recommendation_id
    })).pipe(map(data => {
      return data;
    }));
  }*/

  getAllSportClubSearchByName(name: string, limit: number): Observable<any> {
    return this.http.get(API_URL + '/sportclub?limit=' + limit + '&page=1&query=' + name).pipe(map((SportFacilityArray: SportFacilityModel[]) => {
      return SportFacilityArray['elenco'];
    }));
  }

  searchSportClub(query): Observable<any> {
    return this.http.get(API_URL + '/sportclub', this.globalService.getParams({
      query
    })).pipe(map(data => {
      return data;
    }));
  }

  getSportClubData(sportClubId: number): Observable<any> {
    return this.http.get(API_URL + '/sportclub/' + sportClubId).pipe(map(data => {
      return data;
    }));
  }

  getFederationBySportClub(id: number): Observable<SportFederationSportClubModel[]> {
    return this.http.get(API_URL + '/sport_federation_by_sport_club/' + id).pipe(map((data: SportFederationSportClubModel[]) => {
      return data;
    }));
  }

  getSportBySportClub(id): Observable<string[]> {
    return this.http.get(API_URL + '/get_sport_by_sport_club/' + id).pipe(map((data: string[]) => {
      return data;
    }));
  }

  saveSportClub(body, id): Observable<any> {
    return this.http.post<any>(API_URL + '/sportclub/' + id, {body}, this.globalService.setToken()).pipe(map(res => {
      return res;
    }));
  }

  /*getSportBySportClub(user_lat, user_long, radius, sort, sport_facility, sport_club): Observable<any> {
    return this.http.get(API_URL + '/get_sport_by_sport_club', this.globalService.getParams({
      user_lat,
      user_long,
      radius,
      sort,
      sport_facility,
      sport_club
    })).pipe(map(data => {
      return data;
    }));
  }*/

  getSportFacilityBySportClub(id: number): Observable<any> {
    return this.http.get(API_URL + '/get_sport_facility_by_sport_club/' + id, this.globalService.getParams({
      id
    })).pipe(map(data => {
      return data;
    }));
  }

  getMySportClub(): Observable<any> {
    return this.http.get<any>(API_URL + '/mysportclub', this.globalService.setToken()).pipe(map((mySportClub: SportClubModel) => {
      return mySportClub;
    }));
  }

  getSportClubByOperatorId(id: number): Observable<any> {
    return this.http.get<any>(API_URL + '/sport_club_by_operator/' + id).pipe(map((id: number) => {
      return id;
    }));
  }

  getSportClubBySportFacilityId(id: number): Observable<{ name: string, id: number, operator_id: number }> {
    return this.http.get(API_URL + '/sport_club_by_sport_facility/' + id).pipe(map((data: {
      name: string,
      operator_id: number,
      id: number
    }) => {
      return data;
    }));
  }


}
