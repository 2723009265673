<ion-header>
  <ion-toolbar class="h-auto" color="primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()" slot="icon">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!fitterData" class="montserrat text-white">
      Diventa un fitter!
    </ion-title>
    <ion-title *ngIf="fitterData" class="montserrat text-white">
      Modifica le tue informazioni
    </ion-title>
  </ion-toolbar>
</ion-header>
<div class="inner-content p-3">
  <p *ngIf="!fitterData" class="m-0">
    Inserisci alcune informazioni per unirti a tutti i Fitter che sono già su
    Fitadvisor!
  </p>
  <form (ngSubmit)="saveFitter()" [formGroup]="fitterForm" novalidate>
    <div class="row my-2">
      <div class="col-md-6">
        <form-input [form]="fitterForm" formInputName="name" name="Nome" required="true">
        </form-input>
      </div>
      <div class="col-md-6">
        <form-input [form]="fitterForm" formInputName="surname" name="Cognome" required="true">
        </form-input>
      </div>
    </div>
    <h5 class="mb-2 mt-3">
      Sesso
      <span class="text-danger"> *</span>
    </h5>
    <div class="d-flex gap-2  mt-2">
      <div class="form-check m-0">
        <label class="form-check-label no-select pointer" for="M">
          Uomo
        </label>
        <input [defaultChecked]="fitterData?.sex == 'M'" class="form-check-input no-select" formControlName="sex"
               id="M"
               type="radio" value="M">
      </div>
      <div class="form-check m-0">
        <label class="form-check-label no-select pointer" for="F">
          Donna
        </label>
        <input [defaultChecked]="fitterData?.sex == 'F'" class="form-check-input no-select" formControlName="sex"
               id="F" type="radio"
               value="F">
      </div>
      <div class="form-check m-0">
        <label class="form-check-label no-select pointer" for="I">
          Intersex
        </label>
        <input [defaultChecked]="fitterData?.sex == 'I'" class="form-check-input no-select" formControlName="sex"
               id="I" type="radio"
               value="I">
      </div>
      <div class="form-check m-0">
        <label class="form-check-label no-select pointer" for="N">
          Preferisco non specificare
        </label>
        <input [defaultChecked]="fitterData?.sex == 'N'" class="form-check-input no-select" formControlName="sex"
               id="N" type="radio"
               value="N">
      </div>
    </div>
    <div class="row my-3">
      <div class="col-lg-6">
        <h5 class="mb-2 mt-3">
          Data di nascita
          <span class="text-danger"> *</span>
        </h5>
        <ng-select [clearable]="false"
                   [items]="years"
                   formControlName="birth_year"
                   notFoundText="Anno non presente in lista"
                   placeholder="Il tuo anno di nascita">
        </ng-select>
        <small
          *ngIf="fitterForm.get('birth_year').invalid && (fitterForm.get('birth_year').dirty || fitterForm.get('birth_year').touched)"
          class="fit-color-danger">
          Anno di nascita obbligatorio
        </small>
      </div>
      <div class="col-lg-6">
        <h5 class="mb-2 mt-3">
          Esercizio o attività preferita
        </h5>
        <input class="form-control" formControlName="favorite_exercise"
               placeholder="Scrivi un esercizio o un'attività"
               type="text" value="">
      </div>
    </div>
    <h5 class="mb-2 mt-3">
      Sport preferito
    </h5>
    <ng-select [items]="allSportData"
               bindLabel="name"
               bindValue="id"
               formControlName="sport_id"
               notFoundText="Non conosciamo ancora questo sport"
               placeholder="Cerca il tuo sport preferito">
    </ng-select>
    <div class="row my-3">
      <div class="col-lg-6">
        <h5 class="mb-2 mt-3">
          Provincia
          <span class="text-danger"> *</span>
        </h5>
        <input [readOnly]="true" class="form-control" formControlName="state_code" type="text" value="">
      </div>
      <div class="col-lg-6">
        <h5 class="mb-2 mt-3">
          Città
          <span class="text-danger"> *</span>
        </h5>
        <input [readOnly]="true" class="form-control" formControlName="city_name" type="text" value="">
      </div>
      <small class="mb-1 mt-2">Per modificare città e provincia cerca l'indirizzo completo e seleziona una fra
        le proposte</small>
      <address-component (emittedAddress)="newAddressEmitted($event)"></address-component>
    </div>
    <div class="mt-4 d-flex justify-content-center flex-column text-center">
      <div class="d-flex gap-3 justify-content-center align-items-center">
        <ion-button (click)="dismissModal()" class="ion-btn-light">
          Annulla
        </ion-button>
        <ion-button [disabled]="fitterForm.invalid" class="ion-btn-primary" type="submit">
            <span *ngIf="!isFitterLoading">
              Salva dati
            </span>
          <fit-spinner-light *ngIf="isFitterLoading"></fit-spinner-light>
        </ion-button>
      </div>
      <small *ngIf="fitterForm.invalid" class="fit-color-danger mt-3">
        Inserisci tutti i campi richiesti per continuare
      </small>
    </div>
  </form>
</div>
