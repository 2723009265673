<!--<ion-app [@routeAnimation]="prepareRoute(outlet)">
  <fit-header></fit-header>
  <ion-router-outlet #outlet="outlet">
  </ion-router-outlet>
</ion-app>-->

<div class="ion-page" id="main-content">
  <fit-header></fit-header>
  <ion-content class="ion-padding">
    <ion-router-outlet>
    </ion-router-outlet>
    <div
      class="position-absolute bottom-0 start-0 d-flex justify-content-center w-100 ion-color-primary-contrast p-3 gap-3 align-items-center flex-wrap">
      <div *ngIf="!localStorage.getItem('cookiePreferences')" @fadeIn
           class="fit-cookie ion-bg-tertiary-glass align-items-center justify-content-center fit-content flex-column flex-md-row d-flex p-3 ion-color-primary-contrast fit-rounded gap-3 gap-md-5">
        <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3">
          <ion-icon class="size-30" name="cookie-bite"></ion-icon>
          <p class="m-0">
            <b>
              {{'BANNER.COOKIE.TITLE' | translate}}
            </b>
            <br>
            {{'BANNER.COOKIE.TEXT' | translate}}
            <span class="text-decoration-underline pointer" data-toggle="tooltip"
                  [title]="'BANNER.COOKIE.INFO' | translate"> cookie</span>.
          </p>
        </div>
        <div class="d-flex gap-3">
          <ion-button (click)="denyCookie()" color="light" fill="clear">
            {{'GENERAL.DENY' | translate}}
          </ion-button>
          <ion-button (click)="acceptCookie()" class="ion-btn-light w-100">
            {{'GENERAL.ACCEPT' | translate}}
          </ion-button>
        </div>
      </div>
      <div *ngIf="!localStorage.getItem('notificationPermissionAsked')"
           @fadeIn
           class="fit-cookie ion-bg-tertiary-glass align-items-center justify-content-center fit-content flex-column flex-md-row d-flex p-3 ion-color-primary-contrast fit-rounded gap-3 gap-md-5">
        <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3">
          <ion-icon class="size-30" name="bell"></ion-icon>
          <p class="m-0 bold">
            {{'BANNER.REQUEST.TITLE' | translate}}
          </p>
        </div>
        <div class="d-flex gap-3">
          <ion-button (click)="acceptPermission()" class="ion-btn-light w-100">
            {{'GENERAL.CONTINUE' | translate}}
          </ion-button>
        </div>
      </div>
    </div>
    <ion-fab (click)="onImpersonationStop()" *ngIf="localStorage.getItem('impersonateUser')" data-toggle="tooltip"
             horizontal="end" slot="fixed" title="Disattiva impersonificazione" vertical="bottom">
      <ion-fab-button>
        <ion-icon name="user-secret"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>

