import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Router} from "@angular/router";

@Component({
  selector: 'app-choose-user-type-modal',
  templateUrl: './choose-user-type-modal.component.html',
  styleUrls: ['./choose-user-type-modal.component.scss'],
})
export class ChooseUserTypeModalComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  chooseUserType(isOperator: boolean = false) {
    if (isOperator) {
      this.modalCtrl.dismiss('', 'operator').then();
    } else {
      this.modalCtrl.dismiss('', 'fitter').then();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss().then(() => {
    });
  }

}
