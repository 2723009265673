export class OperatorModel {

  public id: number;
  public name: string;
  public address: string;
  public phone: number;
  public payment_method: string;
  public user_id: string;
  public city_id: string;
  public state_id: string;
  public country_id: string;
  public profile_id: number;
  public turbo_id: number;
  public turbo_name: string;
  public turbo_expiration: string;
  public expected_lead: number;
  public received_lead: number;
  public created: string;

  constructor(data: any = []) {
    if (data) {
      for (const key in data) {
        this[key] = data[key] ?? null;
      }
    }
  }

}

