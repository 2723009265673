import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, filter, map, Observable, switchMap} from "rxjs";
import {API_URL} from "@environments/environment";
import {GlobalService} from "@app/services/global.service";
import {OperatorModel} from "@app/models/operator.model";
import {SportClubModel} from "@app/models/sport-club.model";
import {SportFacilityModel} from "@app/models/sport-facility.model";
import {SfsInsideModel} from "@app/models/sfs.model";
import {ClassroomModel} from "@app/models/classroom.model";
import {LessonModel} from "@app/models/lesson.model";
import {WelfareModel} from "@app/models/welfare.model";

@Injectable({
  providedIn: 'root'
})
export class OperatorPanelService {

  private activeSportClub = new BehaviorSubject(null);
  activeSportClub_obs = this.activeSportClub.asObservable();
  isTurbo_obs = this.activeSportClub.asObservable();
  private operatorData = new BehaviorSubject(null);
  operatorData_obs = this.operatorData.asObservable();
  private isTurbo = new BehaviorSubject(null);
  private activeSportClubOverview = new BehaviorSubject(null);
  activeSportClubOverview_obs = this.activeSportClubOverview.asObservable();

  constructor(
    private http: HttpClient,
    public globalService: GlobalService,
  ) {
  }

  async changeActiveSportClub(ActiveSportClub: SportClubModel) {
    if (ActiveSportClub) {
      localStorage.setItem('activeSportClubId', String(ActiveSportClub.id))
      this.activeSportClub.next(ActiveSportClub);
    }
  }

  mySportClub(): Observable<any> {
    return this.http.get(API_URL + '/mysportclub', this.globalService.setToken()).pipe(map((sportClubArray: SportClubModel[]) => {
      if (sportClubArray?.length > 0) {
        if (Number(localStorage.getItem('activeSportClubId'))) {
          sportClubArray.forEach(sportClub => {
            if (sportClub.id == Number(localStorage.getItem('activeSportClubId'))) {
              this.activeSportClub.next(sportClub);
            }
          })
        } else {
          this.activeSportClub.next(sportClubArray[0]);
          localStorage.setItem('activeSportClubId', String(sportClubArray[0].id));
        }
        return sportClubArray;
      } else return null;
    }));
  }

  mySportClubCreateOrModify(body, id): Observable<any> {
    return this.http.post<any>(API_URL + '/sportclub/' + id, {body}, this.globalService.setToken()).pipe(map(res => {
      return res;
    }));
  }

  async changeOperatorData(operatorData) {
    await this.operatorData.next(operatorData);
  }

  getOperatorData(): Observable<any> {
    return this.http.get(API_URL + '/operator_data', this.globalService.setToken()).pipe(map((operatorData: OperatorModel) => {
      this.operatorData.next(operatorData);
      const current_date = new Date();
      const expiration_date = new Date(operatorData.turbo_expiration);
      this.isTurbo.next((!!operatorData.turbo_name && (current_date <= expiration_date)) || operatorData.profile_id !== 1);
      return operatorData;
    }));
  }

  getSportClubOverview(): Observable<any> {
    return this.activeSportClub_obs.pipe(filter(x => x !== null),
      switchMap(activeSportClub => {
        if (activeSportClub) {
          return this.http.get(API_URL + '/sport_club_overview/' + this.activeSportClub.value?.id, this.globalService.setToken()).pipe(map((data: {
            sport_facility: SportFacilityModel[],
            sport_facility_sport: SfsInsideModel[],
            classroom: ClassroomModel[],
            lesson: LessonModel[],
            profile_completition: number
          }) => {
            this.activeSportClubOverview.next(data);
            return data;
          }));
        }
      })
    );
  }

  mySportFacility(): Observable<OperatorSportFacility[]> {
    return this.http.get(API_URL + '/operator_sport_facility_by_operator', this.globalService.setToken()).pipe(map((operatorSportFacilityArray: OperatorSportFacility[]) => {
      return operatorSportFacilityArray;
    }));
  }

  mySportFacilityAddNew(sport_facility_id: number): Observable<any> {
    return this.http.post<any>(API_URL + '/operator_sport_facility/0', {sport_facility_id}, this.globalService.setToken()).pipe(map((status: Number) => {
      return status;
    }));
  }

  mySportFacilityDelete(id): Observable<any> {
    return this.http.delete<any>(API_URL + '/operator_sport_facility/' + id, this.globalService.setToken()).pipe(map(res => {
      return res;
    }));
  }

  /*checkTurboExpiration(operatorData: OperatorModel): boolean {
    /!*const current_date = new Date();
    const expiration_date = new Date(operatorData.turbo_expiration);
    /!*if (operatorData?.profile_id && operatorData?.profile_id !== 1) {
      return true;
    } else {
      if (operatorData?.expected_lead) {
        if (operatorData?.expected_lead <= operatorData?.received_lead) {
          return !!operatorData.turbo_name && (current_date <= expiration_date);
        } else {
          return true;
        }
      } else {
        return !!operatorData.turbo_name && (current_date <= expiration_date);
      }
    }*!/
    if (operatorData?.expected_lead) {
      if (operatorData?.expected_lead <= operatorData?.received_lead) {
        return !!operatorData.turbo_name && (current_date <= expiration_date);
      } else {
        return true;
      }
    } else {
      return !!operatorData.turbo_name && (current_date <= expiration_date);
    }*!/

    /!*
        console.log(operatorData)
    *!/

    this.activeSportClub.subscribe({
      next: sportClub => {
        if (sportClub) {
          console.log(sportClub)
        }
      }
    })

    console.log(operatorData)

    return false

  }*/

  getVisitStatistic(period: number): Observable<{ me: number, same_state: number }> {
    return this.http.get<any>(API_URL + '/visit_statistic', this.globalService.setTokenAndParams({
      period
    })).pipe(map((res: { me: number, same_state: number }) => {
      return res;
    }));
  }

  sendWelfarePDF(body, token) {
    return this.http.post<any>(API_URL + '/operator_welfare/' + body['id'], {
      body, token
    }, this.globalService.setToken()).pipe(map(res => {
      return res;
    }));
  }

  getWelfare(sport_club_id: number): Observable<WelfareModel> {
    return this.http.get<any>(API_URL + '/welfare_by_sport_club/' + sport_club_id, this.globalService.setToken()).pipe(map((res: WelfareModel) => {
      return res;
    }));
  }
}

export interface OperatorSportFacility {
  id: number,
  active: boolean,
  confirmed: boolean,
  operator_type_id: number,
  operator_id: number,
  operator_name?: string,
  sport_facility_id: number,
  sport_facility_name?: string,
}
