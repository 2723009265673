<ion-header>
  <ion-toolbar class="h-auto" color="secondary">
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()" slot="icon">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!operatorData" class="montserrat text-white text-wrap">
      Diventa un Operatore o Aggiungi una società!
    </ion-title>
    <ion-title *ngIf="operatorData" class="montserrat text-white text-wrap">
      Modifica i tuoi dati operatore
    </ion-title>
  </ion-toolbar>
</ion-header>
<div class="inner-content p-3">
  <form (ngSubmit)="saveOperator()" [formGroup]="operatorForm" novalidate>
    <label class="bold ion-color-secondary mb-2">Nome del titolare<sup>*</sup></label>
    <input class="form-control" formControlName="name" name="name" placeholder="Il tuo nome" type="text"
           value="">
    <small
      *ngIf="operatorForm.get('name').invalid && (operatorForm.get('name').dirty || operatorForm.get('name').touched)"
      class="fit-color-danger">
      Nome titolare obbligatorio
    </small>
    <div class="my-2">
      <label class="bold ion-color-secondary mb-2">Telefono<sup>*</sup></label>
      <input class="form-control" formControlName="phone" name="phone" placeholder="Il tuo numero di telefono"
             type="tel" value="">
      <small
        *ngIf="operatorForm.get('name').invalid && (operatorForm.get('name').dirty || operatorForm.get('name').touched)"
        class="fit-color-danger">
        Telefono obbligatorio
      </small>
    </div>
    <div class="row my-3">
      <small class="my-2">Per modificare città e provincia cerca l'indirizzo fiscale completo e seleziona una
        fra le proposte</small>
      <address-component (emittedAddress)="newAddressEmitted($event)"
                         [showFields]="false"></address-component>
      <div *ngIf="operatorForm.get('address').value" class="row mt-3">
        <div class="col-12">
          <label class="bold ion-color-secondary mb-2">Indirizzo fiscale<sup>*</sup></label>
          <input [readOnly]="true" class="form-control" formControlName="address" type="text" value="">
        </div>
        <div class="col-lg-3 my-2">
          <label class="bold ion-color-secondary mb-2">Provincia<sup>*</sup></label>
          <input [readOnly]="true" class="form-control" formControlName="state_code" type="text" value="">
        </div>
        <div class="col-lg-9 my-2">
          <label class="bold ion-color-secondary mb-2">Città<sup>*</sup></label>
          <input [readOnly]="true" class="form-control" formControlName="city_name" type="text" value="">
        </div>
        <div class="col-12">
          <label class="bold ion-color-secondary mb-2">Regione<sup>*</sup></label>
          <input [readOnly]="true" class="form-control" formControlName="country_name" type="text" value="">
        </div>
      </div>
    </div>
    <div class="mt-4 d-flex justify-content-center flex-column text-center">
      <div class="d-flex gap-3 justify-content-center align-items-center">
        <ion-button (click)="dismissModal()" class="ion-btn-light">
          Annulla
        </ion-button>
        <ion-button [disabled]="operatorForm.invalid" class="ion-btn-secondary" type="submit">
                    <span *ngIf="!isFitterLoading">
                      Salva dati
                    </span>
          <fit-spinner-light *ngIf="isFitterLoading"></fit-spinner-light>
        </ion-button>
      </div>
      <small *ngIf="operatorForm.invalid" class="fit-color-danger mt-3">
        Inserisci tutti i campi richiesti per continuare
      </small>
    </div>
  </form>
</div>
