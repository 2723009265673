import {Component, Input, OnInit} from '@angular/core';
import {MenuController, ModalController, Platform, ToastController} from "@ionic/angular";
import {AuthService} from "@app/services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UserModel} from "@app/models/user.model";
import {UserService} from "@app/services/user.service";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {CommunicationService} from "@app/helper/communication.service";
import {StorageService} from "@app/helper/storage.service";
import {ScriptService} from "@app/services/script.service";


@Component({
  selector: 'app-pages',
  templateUrl: './pages.page.html',
  styleUrls: ['./pages.page.scss'],
})
export class PagesPage implements OnInit {

  /* USER DATA */
  @Input() user: UserModel;
  isAuthenticated: boolean = false;
  defaultImage = 'assets/img/default/user.webp';
  errorImage = 'assets/img/default/user.webp';

  userImage: string;
  userThumbImage: string;


  styles: Partial<CSSStyleDeclaration> = {
    margin: '0 auto'
  };

  constructor(
    private menu: MenuController,
    private platform: Platform,
    public router: Router,
    private storage: StorageService,
    private toastCtrl: ToastController,
    private authService: AuthService,
    private toastController: ToastController,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private userService: UserService,
    public navigationRoute: NavigationRouteService,
    private communicationService: CommunicationService,
    private scriptService: ScriptService
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
  }

}
