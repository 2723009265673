<div class="">
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button (click)="dismiss()" class="ion-btn-secondary fit-shadow" size="small">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="row">
    <div class="col-lg-6 ion-bg-tertiary blocchi text-white my-0 ps-5">
      <h3 class="text-white bold">Sei uno sportivo?</h3>
      <p class="mt-4">
        Se anche tu sei convito che lo sport non sia solo un gesto atletico, unisciti alla community.
        Condividi la tua passione e accumula medaglie
        per ogni contributo che lasci!
      </p>
      <div class=" me-3 mt-5">
        <ion-button (click)="chooseUserType(false)" class="bold w-100" color="secondary">
          Iscriviti
        </ion-button>
      </div>
    </div>
    <div class="col-lg-6 ion-bg-primary blocchi text-white my-0 ps-5">
      <h3 class="text-white bold">Sei un titolare?</h3>
      <p class="mt-4">Ogni giorno aiutiamo i nostri utenti a trovare&nbsp;il corso&nbsp;pi&ugrave; adatto&nbsp;rispetto
        all&#39;obiettivo&nbsp;o
        allo&nbsp;sport&nbsp;scelto.&nbsp;Inserendo i tuoi&nbsp;corsi&nbsp;possiamo proporre&nbsp;anche i
        tuoi!
      </p>

      <p class="bold">Non hai vincoli o obblighi di abbonamenti</p>

      <div class=" me-3 mt-5">
        <ion-button (click)="chooseUserType(true)" class="bold w-100" color="secondary">
          Iscriviti
        </ion-button>
      </div>
    </div>
  </div>
</div>
