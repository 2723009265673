import {Component, OnInit} from '@angular/core';
import {ModalController, Platform} from "@ionic/angular";
import {Router} from "@angular/router";

@Component({
  selector: 'app-get-app-modal',
  templateUrl: './get-app-modal.component.html',
  styleUrls: ['./get-app-modal.component.scss'],
})
export class GetAppModalComponent implements OnInit {

  public translateParams = {plat: this.platform.is('android') ? 'Android' : this.platform.is('ios') ? 'Iphone' : "android"};

  constructor(
    public platform: Platform,
    private modalCtrl: ModalController,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  accept() {

    if (this.platform.is('android')) {
      window.open('market://details?id=io.fitadvisor.app&pcampaignid=web_share', '_blank')
    } else if (this.platform.is('ios')) {
      window.open('itms-apps://apps.apple.com/it/app/fitadvisor/id6464032136?platform=iphone', '_blank')
    }
    this.modalCtrl.dismiss().then(() => {
      localStorage.setItem('appDownload', String(true))
    })
  }

  deny() {
    this.modalCtrl.dismiss().then(() => {
      localStorage.setItem('appDownload', String(false))
    })
  }

}
