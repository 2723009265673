import {Component} from '@angular/core';

@Component({
  selector: 'fit-loading',
  template: `
    <lottie-player src="{{src}}" style="width:100%;margin:0 auto;" autoplay loop></lottie-player>
    <h6 class="display-6 bold text-white text-center">
      {{phrase}}
    </h6>`,
  styles: [''],
})
export class FitLoadingComponent {

  private lottieArray: string[] = [
    'bike',
    'skate',
    'pushup',
  ]
  private phraseArray: string[] = [
    'Stiamo gonfiando i palloni',
    'Stiamo lucidando i pattini',
    'Stiamo pesando i pesi',
    'Stiamo pensando a nuovi sport',
  ]

  phrase: string = this.phraseArray[this.randomInteger(0, this.phraseArray.length - 1)]

  src = '/assets/lottie/loading/' + this.lottieArray[this.randomInteger(0, this.lottieArray.length - 1)] + '.json'

  constructor() {
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
