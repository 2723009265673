<div class="inner-content p-3">
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button (click)="modalController.dismiss()" class="ion-btn-tertiary fit-shadow" size="small">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="d-flex justify-content-center gap-3 flex-column ">
    <h1 class="m-0 bold ">
      {{ title | translate }}
    </h1>
    <p [innerHTML]="message | translate " class="m-0">
    </p>
    <ion-button (click)="modalController.dismiss()" class="ion-btn-primary my-3 mb-0">
      {{ 'GENERAL.UNDERSTOOD' | translate }}
    </ion-button>
  </div>

</div>
