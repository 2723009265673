import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@app/services/auth.service";
import {ModalController} from "@ionic/angular";
import {LoginComponent} from "@app/pages/auth/login/login.component";
import {CommunicationService} from "@app/helper/communication.service";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {Capacitor} from "@capacitor/core";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {

  token: string | undefined;
  public isLoading: boolean = false;

  /*FORM*/
  public signUpForm: FormGroup;
  public isPasswordVisible: 'password' | 'text' = 'password';
  public isRepeatPasswordVisible: 'password' | 'text' = 'password';
  public isLoadingButton: boolean = false;

  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController,
    private communicationService: CommunicationService,
    public navigationRoute: NavigationRouteService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: FormBuilder,
  ) {
    this.signUpForm = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required, Validators.pattern('^[A-Za-z]\\w{4,19}$')])],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      password: [null, Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^\\da-zA-Z]).{8,}$')])],
      password_repeat: [null, Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^\\da-zA-Z]).{8,}$')])],
      will_be_operator: [false],
    })
  }

  ngOnInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  async onSubmit() {
    this.isLoading = true;

    if (this.signUpForm.invalid) {
      this.communicationService.errorToast('Errore, riprova più tardi').then(() => {
        this.isLoading = false;
      });
      return;
    }
    if (this.signUpForm.get('password').value != this.signUpForm.get('password_repeat').value) {
      this.communicationService.errorToast('Le password non coincidono').then();
      return;
    }

    const name = this.signUpForm.get('name').value;
    const email = this.signUpForm.get('email').value.trim();
    const password = this.signUpForm.get('password').value;
    const will_be_operator = this.signUpForm.get('will_be_operator').value;

    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        this.authService.sign_up(name, email, password, token, will_be_operator).subscribe({
            next: () => {
              this.modalCtrl.dismiss();
              this.communicationService.notify("COMMUNICATION.SIGNUP.SUCCESS", 'success', 'top', 10000).then(() => {
                this.isLoading = false;
                this.signUpForm.reset();
              });
            },
            error: err => {
              this.communicationService.errorToast(err).then(() => {
                this.isLoading = false;
                this.signUpForm.reset();
              });
            }
          }
        );
      });
  }

  async onRegisterChangeLogin() {
    await this.modalCtrl.dismiss();
    await this.showModalLogin();
  }

  async showModalLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height'
    });
    return modal.present();
  }

  closeModal() {
    this.modalCtrl.dismiss().then();
  }

  /* SOCIAL LOGIN */

  onFacebookLogin(): void {
    this.authService.facebookLogin()
  }

  onGoogleLogin(): void {
    this.authService.googleLogin().then()
  }

  /* PASSWORD TOGGLE */

  changeIsPasswordVisible() {
    if (this.isPasswordVisible === "password") {
      this.isPasswordVisible = "text";
    } else {
      this.isPasswordVisible = "password";
    }
  }

  changeIsRepeatPasswordVisible() {
    if (this.isRepeatPasswordVisible === "password") {
      this.isRepeatPasswordVisible = "text";
    } else {
      this.isRepeatPasswordVisible = "password";
    }
  }

  public ngOnDestroy() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  protected readonly Capacitor = Capacitor;
}
