<div class="fit-slider">
  <swiper-container [config]="config" [hidden]="!recommendationArray" fitSwiper>
    <swiper-slide *ngFor="let recommendation of recommendationArray">
      <div class="row g-0">
        <div class="col-3 my-auto">
          <img [defaultImage]="defaultImage" [lazyLoad]="recommendation?.profile_image?.image"
               [errorImage]="defaultImage" alt="Immagine utente anonimo"
               class="rounded-circle" src="" width="100px"/>
        </div>
        <div class="col-8 text-start size-16 mt-2">
          <p class="ps-3 m-0">
            <a [routerLink]="'/profilo/fitter/' + recommendation.fitter.id" class="bold hover-underline-dark"
               data-toggle="tooltip"
               title="Nome fitter">
              {{recommendation.fitter.name}}
            </a>
            ha raccomandato
            <a
              [routerLink]="'/impianti/' + recommendation?.sport_facility_slug + '/'+ recommendation.sport_facility_sport_id + '/'+ recommendation.sport.slug"
              class="hover-underline-dark bold" data-toggle="tooltip"
              title="Attività raccomandata">
              {{recommendation.sport.name}}
            </a>
            presso
            <a
              [routerLink]="'/impianti/' + recommendation?.sport_facility_id + '/'+ recommendation?.sport_facility_slug"
              class="bold hover-underline-dark" data-toggle="tooltip"
              title="Luogo dove si svolge l'attività">
              {{recommendation.sport_facility_name}}
            </a>
            {{recommendation.rec_for.name}}
          </p>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  <div class="swiper-pagination mt-4"></div>
</div>

<swiper-container *ngIf="!recommendationArray" [config]="config" fitSwiper>
  <swiper-slide *ngFor="let recommendation of this.globalService.generateFake(6)">
    <div class="row g-0">
      <div class="col-3 my-auto">
        <ngx-skeleton-loader
          [theme]="{ margin: '0', height: '91px', width: '91px', 'background-color': '#eaeef6', 'border-radius': '50%'}">
        </ngx-skeleton-loader>
      </div>
      <div class="col-8 text-start size-16 mt-2">
        <ngx-skeleton-loader [theme]="{ margin: '0', height: '15px', width: '190px', 'background-color': '#eaeef6'}">
        </ngx-skeleton-loader>
        <br>
        <ngx-skeleton-loader [theme]="{ margin: '0', height: '15px', width: '250px', 'background-color': '#eaeef6'}">
        </ngx-skeleton-loader>
        <br>
        <ngx-skeleton-loader [theme]="{ margin: '0', height: '15px', width: '200px', 'background-color': '#eaeef6'}">
        </ngx-skeleton-loader>
        <br>
        <ngx-skeleton-loader [theme]="{ margin: '0', height: '15px', width: '140px', 'background-color': '#eaeef6'}">
        </ngx-skeleton-loader>
        <br>
        <ngx-skeleton-loader [theme]="{ margin: '0', height: '15px', width: '50px', 'background-color': '#eaeef6'}">
        </ngx-skeleton-loader>
        <br>
      </div>
    </div>
  </swiper-slide>
</swiper-container>

