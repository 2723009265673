import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {BannerModel} from "@app/models/banner.model";

@Injectable({providedIn: 'root'})
export class BannerService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
  ) {
  }

  getBanner(type, size, interaction_type?): Observable<BannerModel[]> {
    return this.http.get  <any>(API_URL + '/partner_banner', this.globalService.setParams({
      type,
      size,
      interaction_type
    })).pipe(map((data: BannerModel[]) => {
      return data;
    }));
  }

}
