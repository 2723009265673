import {Component, Input, OnInit} from '@angular/core';
import {NotificationModel} from "@app/models/notification.model";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {

  @Input() notification: NotificationModel

  constructor(
    private modalCtrl: ModalController
  ) {
  }

  async closeModal() {
    await this.modalCtrl.dismiss()
  }

  ngOnInit() {
  }

}
