export class SportModel {
  public id: number;
  public name: string;
  public description: string;
  public slug: string;
  public sport_category_id: number;
  public sport_category_name: string;

  constructor(data: any = []) {
    if (data) {
      for (const key in data) {
        this[key] = data[key] ?? null;
      }
    }
  }

}
