export class NotificationModel {
  id: number;
  subject: string;
  body: string;
  channel: string;
  params: any[];
  readed: boolean;
  date_send: Date;
  user_id: number;
  user_txt: string;
  sender_id: number;
  sender_txt: string;
  display: string;
}
