<!--<div class="row mt-5 text-center justify-content-center">
  <div class="col-12 border-bottom">
    <ion-item lines="none" color="none">
      <ion-input accept="image/*" type="file" [value]="isClear"
                 (change)="onImageSelect($event)"></ion-input>
      <ion-icon name="close" (click)="onDeleteQueue()"></ion-icon>
    </ion-item>
  </div>
</div>-->

<!--<ion-modal>
  <ng-template>
    <ion-content class="ion-padding">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
      <div class="mt-5 text-center">
        <ion-button (click)="onUpload()" *ngIf="!loading">
          Salva
        </ion-button>
        <ion-spinner *ngIf="loading" name="crescent"></ion-spinner>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>-->

<!--<div class="text-center mt-4">
  <ion-button [disabled]="!selectedFiles">Carica</ion-button>
</div>

<image-cropper
  class="m-4 w-75 mx-auto"
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="true"
  [aspectRatio]="1"
  (imageCropped)="imageCropped($event)"
></image-cropper>-->

<ion-header>
  <ion-toolbar class="h-auto" color="primary">
    <ion-buttons slot="end">
      <ion-button slot="icon" (click)="modalCrl.dismiss()">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="montserrat text-white" *ngIf="isSingleImage">
      Carica nuova foto
    </ion-title>
    <ion-title class="montserrat text-white" *ngIf="!isSingleImage">
      Carica nuove immagini
    </ion-title>
  </ion-toolbar>
</ion-header>
<div class="inner-content">
  <div class="modal-body p-3">
    <div class="d-flex justify-content-center"
         [hidden]="(selectedFiles?.length > 0 || croppedImageArray?.length > 0) && isSingleImage">
      <fieldset class="upload_dropZone w-100 text-center p-4 position-relative">
        <p class="small my-2">Trascina qui delle immagini per caricarle</p>
        <small>oppure</small>
        <input id="upload_image_logo" class="w-100 h-100 position-absolute top-0 start-0 opacity-0"
               type="file" [multiple]="!isSingleImage" accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
               (change)="onImageSelect($event)"/>
        <label class="ion-bg-primary fit-rounded text-white bold p-2 m-0 my-3" for="upload_image_logo">Scegli
          file</label>
        <div class="upload_gallery d-flex flex-wrap justify-content-center gap-3 mb-0"></div>
      </fieldset>
    </div>

    <image-cropper
      class="my-3" @fadeIn
      *ngIf="selectedFiles?.length > 0"
      [imageFile]="imageFile"
      [maintainAspectRatio]="aspectRatioKeep"
      [aspectRatio]="aspectRatio"
      format="jpeg"
      backgroundColor=white
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
      output="base64"
    ></image-cropper>

    <div class="d-flex justify-content-center">
      <ion-button (click)="CutImage()" class="ion-btn-secondary mt-2 px-2" *ngIf="selectedFiles?.length > 0">
        Ritaglia immagine
      </ion-button>
    </div>

    <div class="grid-container " [ngClass]="{ 'p-3': croppedImageArray.length > 0}">
      <div class="position-relative" *ngFor="let croppedImage of croppedImageArray">
        <img [src]="croppedImage" class="w-100 h-100" alt="">
        <ion-button type="button" slot="icon-only"
                    class="ion-btn-danger position-absolute top-0 end-0 aspect-ratio-1 m-2 no-padding-button"
                    expand="block" title="Elimina immagine ritagliata" (click)="removeImageFromArray(croppedImage)">
          <ion-icon name="xmark" size="large"></ion-icon>
        </ion-button>
      </div>
    </div>
    <ion-button *ngIf="croppedImageArray?.length > 0 && progressInfos?.length < 1 && isSingleImage"
                class="ion-btn-primary" expand="block"
                (click)="uploadFilesMultiple(this.fileSection)">
      Salva immagine
    </ion-button>
    <ion-button (click)="uploadFilesMultiple(this.fileSection)"
                *ngIf="croppedImageArray?.length > 0 && progressInfos?.length < 1 && !isSingleImage"
                [disabled]="selectedFiles?.length > 0" class="ion-btn-primary"
                expand="block">
      Salva immagini
    </ion-button>
    <div *ngIf="selectedFiles?.length > 0" class="w-100 text-center">
      <small class="bold">
        Ritaglia prima tutte le immagini per caricarle
      </small>
    </div>

    <div *ngFor="let progressInfo of progressInfos" class="my-2">
      <div class="progress">
        <div
          class="progress-bar progress-bar-info ion-bg-primary"
          role="progressbar"
          attr.aria-valuenow="{{ progressInfo.value }}"
          aria-valuemin="0"
          aria-valuemax="100"
          [ngStyle]="{ width: progressInfo.value + '%' }"
        >
          {{ progressInfo.value }}%
        </div>
      </div>
    </div>

  </div>
</div>



