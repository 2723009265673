import {Pipe, PipeTransform, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from "@angular/common";

@Pipe({
  name: 'stripHtml'
})

export class StripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    if (!isPlatformServer(PLATFORM_ID)) {
      const txt = document.createElement("textarea");
      txt.innerHTML = value;
      return txt.value.replace(/<.*?>/g, '');
    }
  }
}
