import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {defineCustomElements} from 'stripe-pwa-elements/loader';
import {AppModule} from './app/app.module';
import {environment} from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => defineCustomElements(window))
    .catch(err => console.log(err));
};


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

