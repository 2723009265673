<div class="fit-card text-center centered-middle p-4">
  <div *ngIf="!sportClub?.check_up_score">
    <h2 class="display-5 bold m-0">Check-up</h2>
    <h5 class="text-wide text-uppercase">digital marketing</h5>
    <p class="text-center mt-5 mb-1">Rispondi al <b>breve questionario</b>, trova la <b>vetrina
      più adatta</b> alle tue esigenze e
    </p>
    <p class="m-0 mb-5">
      <b class="fit-color-danger text-uppercase italic">metti il turbo
        <ion-icon name="rocket"></ion-icon>
      </b>
    </p>

    <div *ngIf="disabled" class="my-3">
      <p class="m-0 bold">
        Completa il profilo società al 100% per poter sbloccare il check-up
      </p>
    </div>

    <ion-button [disabled]="disabled" [routerLink]="navigationRoute.navigate('check-up')" class="ion-btn-primary ">
      Fai il check-up
    </ion-button>
  </div>

  <div *ngIf="sportClub?.check_up_score">
    <p class="bold ion-color-secondary mt-2 display-1">
      {{ this.sportClub.check_up_score }}
    </p>
    <p class="bold ion-color-secondary mt-3 display-5">
      Punteggio digitale
    </p>
    <p class="m-0 mt-4">
      Complimenti! Hai completato tutti i passaggi e il check-up, scopri i pacchetti selezionati per migliorare il
      tuo punteggio digitale!
    </p>

    <ion-button (click)="showPaymentModal()" class="ion-btn-secondary mt-4 mb-2">
      <ion-icon class="me-2" name="rocket"></ion-icon>
      Metti il turbo
    </ion-button>
  </div>
</div>
