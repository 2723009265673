import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'positive_number',
  pure: false
})
export class PositiveNumberPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): any {
    return Math.abs(value);
  }


}
