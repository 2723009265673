<form [formGroup]="form" novalidate>
  <div [ngSwitch]="inputType">
    <div *ngSwitchCase="'checkbox'">
      <div class="form-check my-3">
        <input [formControlName]="formInputName" [id]="name" class="form-check-input" type="checkbox">
        <label *ngIf="isLableVisible" class="form-check-label ms-2 pointer" for="{{name}}">
          {{name}}
          <ion-icon *ngIf="moreInfo" [id]="name" class="opacity-25 pointer" name="circle-info"></ion-icon>
          <span *ngIf="required" class="text-danger"> *</span>
          <ion-popover *ngIf="moreInfo" [trigger]="name" class="centered-popover" mode="md"
                       triggerAction="click">
            <ng-template>
              <ion-content class="ion-padding">
                {{more_info_text}}
              </ion-content>
            </ng-template>
          </ion-popover>
        </label>
      </div>
    </div>
    <div *ngSwitchCase="'textarea'">
      <h5 *ngIf="isLableVisible"
          [ngClass]="{ 'text-danger':  form.get(formInputName).invalid && ( form.get(formInputName).touched ||  form.get(formInputName).dirty)  }"
          class="mb-2 mt-3">
        {{name}}
        <span *ngIf="required" class="text-danger"> *</span>
      </h5>
      <textarea [formControlName]="formInputName" [placeholder]="name" class="form-control my-2" rows="5"></textarea>
    </div>
    <div *ngSwitchCase="'radio'">
      <h5
        [ngClass]="{ 'text-danger':  form.get(formInputName).invalid && ( form.get(formInputName).touched ||  form.get(formInputName).dirty)  }"
        *ngIf="isLableVisible" class="mb-2 mt-3">
        {{name}}
        <span *ngIf="required" class="text-danger"> *</span>
      </h5>
      <div [ngSwitch]="radioDirection">
        <div *ngSwitchCase="'horizontal'">
          <div class="d-flex gap-2 mt-2">
            <div *ngFor="let option of radioOptions" class="form-check">
              <label [for]="option.value" class="form-check-label no-select pointer">
                {{option.name}}
              </label>
              <input [formControlName]="formInputName" [id]="option.value" class="form-check-input no-select"
                     type="radio"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'password'">
      <h5 *ngIf="isLableVisible"
          [ngClass]="{ 'text-danger':  form.get(formInputName).invalid && ( form.get(formInputName).touched ||  form.get(formInputName).dirty)  }"
          class="mb-2 mt-3">
        {{name}}
        <span *ngIf="required" class="text-danger"> *</span>
      </h5>
      <div class="position-relative form-group">
        <input [class.opacity-75]="form.get(formInputName)?.invalid" [formControlName]="formInputName"
               [ngClass]="{'is-invalid':  form.get(formInputName)?.invalid }"
               [placeholder]="name"
               [type]="passwordFieldType"
               class="form-control bg-img-none mt-2"/>
        <ion-icon (click)="changePasswordFieldVisible()" *ngIf="passwordFieldType === 'password'"
                  class="password_icon" name="eye"></ion-icon>
        <ion-icon (click)="changePasswordFieldVisible()" *ngIf='passwordFieldType === "text"' class="password_icon"
                  name="eye-slash"></ion-icon>
      </div>
    </div>
    <div *ngSwitchCase="'re-password'">
      <h5 *ngIf="isLableVisible"
          [ngClass]="{ 'text-danger':  form.get(formInputName).invalid && ( form.get(formInputName).touched ||  form.get(formInputName).dirty)  }"
          class="mb-2 mt-3">
        {{name}}
        <span *ngIf="required" class="text-danger"> *</span>
      </h5>
      <div class="position-relative form-group">
        <input [class.opacity-75]="form.get(formInputName)?.invalid" [formControlName]="formInputName"
               [ngClass]="{'is-invalid':  form.get(formInputName)?.invalid }"
               [placeholder]="name" class="form-control mt-2 bg-img-none" name="password"
               type="{{passwordRepeatFieldType}}"/>
        <ion-icon (click)="changePasswordRepeatFieldVisible()" *ngIf='passwordRepeatFieldType === "password"'
                  class="password_icon"
                  name="eye"></ion-icon>
        <ion-icon (click)="changePasswordRepeatFieldVisible()" *ngIf='passwordRepeatFieldType === "text"'
                  class="password_icon"
                  name="eye-slash"></ion-icon>
      </div>
    </div>
    <div *ngSwitchCase="'address'">
      <h5 *ngIf="isLableVisible"
          [ngClass]="{ 'text-danger':  form.get(formInputName).invalid&& ( form.get(formInputName).touched ||  form.get(formInputName).dirty)  }"
          class="mb-2 mt-3">
        {{name}}
        <span *ngIf="required" class="text-danger"> *</span>
      </h5>
      <input #addresstext (focus)="autocomplete()" [formControlName]="formInputName"
             [ngClass]="{'is-invalid': form.get(formInputName).invalid }" [placeholder]="name"
             class="form-control mt-2"/>
    </div>
    <div *ngSwitchCase="'select'">
      <h5 *ngIf="isLableVisible"
          [ngClass]="{ 'text-danger':  form.get(formInputName).invalid&& ( form.get(formInputName).touched ||  form.get(formInputName).dirty)  }"
          class="mb-2 mt-3">
        {{name}}
        <span *ngIf="required" class="text-danger"> *</span>
      </h5>
      <select (change)="emittData.emit($event.target.value)" [formControlName]="formInputName"
              [ngClass]="{'is-invalid': form.get(formInputName).invalid }" class="form-select mt-2 ">
        <option hidden="hidden" value="">{{placeHolder}}</option>
        <option *ngFor="let option of radioOptions; index as i" [value]="JSON.stringify(option)">
          {{option.name}}
        </option>
      </select>
    </div>
    <div *ngSwitchDefault>
      <h5 *ngIf="isLableVisible"
          [ngClass]="{ 'text-danger':  form.get(formInputName).invalid&& ( form.get(formInputName).touched ||  form.get(formInputName).dirty)  }"
          class="mb-2 mt-3">
        {{name}}
        <span *ngIf="required" class="text-danger"> *</span>
      </h5>
      <input [formControlName]="formInputName" [placeholder]="placeHolder ? placeHolder : name" [type]="inputType"
             [ngClass]="{'is-invalid': form.get(formInputName).invalid }"
             [readonly]="readOnly" class="form-control mt-2"/>
    </div>
  </div>

  <div
    *ngIf="form.get(formInputName).invalid && ( form.get(formInputName).touched ||  form.get(formInputName).dirty) && required && inputType !== 're-password' && inputType !== 'password'">
    <small class="text-danger">
      {{name}} {{'FORM.REQUIRED.' + nameGender | translate}}
    </small>
  </div>
  <div *ngIf="form.get(formInputName).invalid && inputType === 're-password'">
    <small class="text-danger">
      {{'FORM.PASSWORD.MISMATCH' | translate}}
    </small>
  </div>
  <small
    *ngIf="inputType === 'password' && form.get(formInputName).hasError('pattern') && (form.get(formInputName).dirty || form.get(formInputName).touched)"
    class="text-danger p-0 mt-1">
    La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera maiuscola (A-Z),
    una
    lettera minuscola (a-z) un numero (0-9) e un carattere speciale (!_?£$-;.).
  </small>
</form>
