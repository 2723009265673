<div class="gallery-grid">
  <img (click)="setImageOpen(true, image)" *ngFor="let image of imageArray" [src]="image.image"
       alt="Immagine della galleria {{name}}"
       class="object-fit-cover pointer" height="300" width="100%">

  <div *ngFor="let video of videoArray">
    <!--<iframe [src]="video.name | safe:'resourceUrl'" allowfullscreen class="video-iframe"
            height="300"
            width="100%">
    </iframe>-->
    <iframe
      [src]="('https://www.youtube.com/embed/' + getVideoId(video.name) | safe:'resourceUrl')"
      allowfullscreen frameborder="0" type="text/html"
      class="video-iframe"
      height="300"
      width="100%">
    </iframe>
  </div>
  <!--<video width="100%" height="290" controls *ngFor="let video of videoArray">
    <source
      [src]="video.name"
      type="video/*">
  </video>-->
</div>

<ion-popover (didDismiss)="setImageOpen(false)" [isOpen]="isImageModal" cssClass="centered-popover">
  <ng-template>
    <ion-content>
      <div class="position-relative">
        <ion-button (click)="setImageOpen(false)" class="position-absolute top-0 end-0 m-3" fill="clear" slot="icon">
          <ion-icon color="light" name="close"></ion-icon>
        </ion-button>
        <img [src]="imageOpened.image" alt="" class="pointer" height="100%" width="100%">
      </div>
    </ion-content>
  </ng-template>
</ion-popover>

<!--<ion-modal (willDismiss)="setImageOpen(false)" [isOpen]="setImageOpen" mode="ios" cssClass="auto-height">
  <ng-template>
    <div class="inner-content">
      <div class="modal-body m-3 mx-md-0 mt-md-4">
        <div class="d-flex justify-content-end">
          <ion-button (click)="setImageOpen(false)" class="no-padding-button" color="light" fill="clear">
            <ion-icon name="xmark"></ion-icon>
          </ion-button>
        </div>
      </div>
    </div>
  </ng-template>
</ion-modal>-->
