import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {DeviceTokenModel} from "@app/models/device.model";
import {UserModel} from "@app/models/user.model";
import {AuthService} from "@app/services/auth.service";
import {Platform} from "@ionic/angular";
import {NotificationModel} from "@app/models/notification.model";

@Injectable({providedIn: 'root'})
export class NotificationService {

  public notifications = new BehaviorSubject(null);
  notifications_obs = this.notifications.asObservable();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private authService: AuthService,
    private platform: Platform,
  ) {
  }


  checkIfUserHasValidToken(currentToken: string) {
    this.authService.user_obs.pipe().subscribe({
      next: (user: UserModel) => {
        const isAuthenticated = user ? !this.authService.tokenExpirationCheck(user?.token) : false;
        if (isAuthenticated) {
          this.getTokens().subscribe({
            next: devices => {
              if (devices.length > 0) {
                let platform = this.platform.is('android') ? 'android' : (this.platform.is('ios') ? 'ios' : 'browser');
                devices.forEach(device => {
                  if (device.platform !== platform && device.token !== currentToken) {
                    this.onTokenSaving(currentToken);
                  }
                })
              } else {
                this.onTokenSaving(currentToken);
              }
            }
          })
        } else {
          this.onTokenSaving(currentToken);
        }
      }
    })
  }

  onTokenSaving(currentToken: string, id: number = 0) {
    let platform = this.platform.is('android') ? 'android' : (this.platform.is('ios') ? 'ios' : 'browser');
    let body = {
      platform: platform,
      mac_address: '',
      name: '',
      token: currentToken
    };
    this.authService.user_obs.pipe().subscribe({
      next: (user: UserModel) => {
        const isAuthenticated = user ? !this.authService.tokenExpirationCheck(user?.token) : false;
        this.postToken(body, isAuthenticated, id).subscribe()
      }
    })
  }

  getTokens(): Observable<DeviceTokenModel[]> {
    return this.http.get<any>(API_URL + '/devicestoken', this.globalService.setToken()).pipe(map((data: {
      elenco: DeviceTokenModel[]
    }) => {
      return data.elenco;
    }));
  }

  /**
   * @description Salva il token dato da firebase
   * @param body Token e dati associati
   * @param authenticated L'utente è loggato o no ?
   * @param id Id dell'eventuale token
   **/
  postToken(body: any, authenticated: boolean = false, id: number): Observable<number> {
    if (authenticated) {
      return this.http.post<any>(API_URL + '/devicestoken/' + id, {
        body
      }, this.globalService.setToken()).pipe(map((data: any) => {
        return data;
      }));
    } else {
      return this.http.post<any>(API_URL + '/devicestoken/' + id, {
        body
      }).pipe(map((data: any) => {
        return data;
      }));
    }
  }


  /**
   * @description Ottieni tutte le notifiche dell'utente
   **/
  getNotificationRecived(): Observable<NotificationModel[]> {
    return this.http.get<any>(API_URL + '/my_notification', this.globalService.setToken()).pipe(map((data: NotificationModel[]) => {
      return data;
    }));
  }

  /**
   * @description Carica le notifiche
   *
   **/
  loadNotification() {
    this.getNotificationRecived().subscribe(notifications => {
        this.notifications.next(notifications)
      }
    )
  }

  /**
   * @description Cambia lo status delle notifiche da letto a da leggere e viceversa
   * @param id Id della notifica da modificare
   **/
  changeReadNotificationStatus(id: number): Observable<any> {
    return this.http.put<any>(API_URL + '/change_read_notification/' + id, {}, this.globalService.setToken()).pipe(map((data: NotificationModel[]) => {
      return data;
    }));
  }

  /**
   * @description Archivia e cancella la notifica selezionata
   * @param id Id della notifica da eliminare
   **/
  deleteNotification(id: number): Observable<any> {
    return this.http.delete<any>(API_URL + '/notification/' + id, this.globalService.setToken()).pipe(map((data) => {
      return data;
    }));
  }

}
