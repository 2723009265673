import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {OperatorPanelService} from "@app/services/operator-panel.service";
import {SportClubModel} from "@app/models/sport-club.model";
import {fadeInAnimation} from "@app/animations";
import {CommunicationService} from "@app/helper/communication.service";

@Component({
  selector: 'app-choose-active-sport-club',
  templateUrl: './choose-active-sport-club.component.html',
  styleUrls: ['./choose-active-sport-club.component.scss'],
  animations: [fadeInAnimation]
})
export class ChooseActiveSportClubComponent implements OnInit {

  public sportClubArray: SportClubModel[];

  constructor(
    public modalCtrl: ModalController,
    private operatorPanelService: OperatorPanelService,
    private communicationService: CommunicationService
  ) {
  }

  ngOnInit() {
    this.operatorPanelService.mySportClub().subscribe({
      next: (sportClubArray: SportClubModel[]) => {
        this.sportClubArray = sportClubArray
      }
    })
  }

  chooseActiveSportClub(sportClub) {
    this.operatorPanelService.changeActiveSportClub(sportClub).then(() => {
      this.modalCtrl.dismiss().then(() => {
        this.communicationService.successToast('Società cambiata').then()
      })
    }).catch(error => {
      console.error(error)
      this.modalCtrl.dismiss().then(() => {
        this.communicationService.errorToast('Qualcosa è andato storto').then()
      })
    })
  }

}
