import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'square-partner-component',
  templateUrl: './square-partner.component.html',
  styleUrls: ['./square-partner.component.scss'],
})
export class SquarePartnerComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
