<div class="row g-0 d-flex col-auto">
  <div *ngFor="let cta of ctaArray" class="col-auto">
    <div [ngSwitch]="cta?.position">
      <div *ngSwitchCase="'bottom_bar'">
        <ion-button (click)="setIsOpen(true, cta.id)" [ngStyle]="{'background-color': cta.color}" [title]="cta.btn_text"
                    class="bold ms-2"
                    color="none" data-toggle="tooltip" style="border-radius: 4px">
          <ion-icon [name]="cta.icon"></ion-icon>
          <span class="ion-hide-sm-down ms-2">
                {{cta.btn_text}}
            </span>
        </ion-button>
        <ion-modal (willDismiss)="setIsOpen(false, cta.id)" [isOpen]="isOpen.includes(cta.id)"
                   cssClass="auto-height" mode="ios">
          <ng-template>
            <div class="inner-content">
              <ion-icon (click)="modalController.dismiss()"
                        class="fs-2 position-absolute end-0 m-2 opacity-25 z-index-99 pointer" name="xmark">
              </ion-icon>
              <div class="modal-body p-4">
                <div class="d-flex gap-4 align-items-center">
                  <h5 class="m-0 fw-bolder fs-3 ">{{cta.title}}</h5>
                  <ion-icon [name]="cta.icon" [ngStyle]="{'px-3': cta?.title}" [style.color]="cta.color"
                            class="display-2"></ion-icon>
                </div>
                <hr class="bg-dark my-4">
                <p class="fs-6 m-0">{{cta.text}}</p>
                <div class="d-flex justify-content-center justify-content-md-end mt-4 mb-5">
                  <ion-button [href]="cta.link" [ngStyle]="{'background-color': cta.color}"
                              (click)="modalController.dismiss()" [title]="cta.link_text" class="rounded bold"
                              data-toggle="tooltip" mode="md"
                              color="none" target="_blank">
                    {{cta.link_text}}
                  </ion-button>
                </div>
                <newsletter-form>
                </newsletter-form>
              </div>
            </div>
          </ng-template>
        </ion-modal>
      </div>
      <div *ngSwitchCase="'fab'">
        <ion-fab class="mb-3 me-3" data-toggle="tooltip" horizontal="end" slot="fixed"
                 title="Disattiva impersonificazione"
                 vertical="bottom">
          <ion-fab-button>
            <ion-icon name="user-secret"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
    </div>
  </div>
</div>
