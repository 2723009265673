import {Component, OnInit} from '@angular/core';
import {BannerService} from "@app/services/banner.service";
import {BannerModel} from "@app/models/banner.model";
import {ModalController} from "@ionic/angular";
import {Capacitor} from "@capacitor/core";

@Component({
  selector: 'partner-component',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {

  public bannerMediaArray: BannerModel[];
  public bannerArray: BannerModel[];
  public isOpen: number[] = [];

  constructor(
    private bannerService: BannerService,
    public modalController: ModalController
  ) {
  }

  ngOnInit() {
    this.bannerService.getBanner('partner', 'square').subscribe({
      next: bannerArray => {
        this.bannerArray = bannerArray
      }
    })
    this.bannerService.getBanner('media', 'square').subscribe({
      next: bannerArray => {
        this.bannerMediaArray = bannerArray
      }
    })
  }

  setIsOpen(isOpen: boolean, id) {
    if (isOpen) {
      this.isOpen.push(id)
    } else {
      this.isOpen.splice(this.isOpen.indexOf(id), 1)
    }
  }

  protected readonly Capacitor = Capacitor;
}
