// import the required animation functions from the angular animations module
import {trigger, animate, transition, style} from '@angular/animations';

export const fadeInAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('fadeIn', [

    // route 'enter' transition
    transition(':enter', [
      style({opacity: 0}),
      animate('300ms', style({opacity: 1})),
    ])
  ]);
