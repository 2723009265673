import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Geolocation} from "@capacitor/geolocation";
import {ModalController, Platform, ToastController} from "@ionic/angular";
import {CommunicationService} from "@app/helper/communication.service";
import {Capacitor} from "@capacitor/core";
import {RequestPositionComponent} from "@app/components/request-position/request-position.component";
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: 'root'})

export class GeolocationService {

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private communicationService: CommunicationService,
    private modal: ModalController,
    private translateService: TranslateService,
    private toastController: ToastController
  ) {
  }

  getUserPosition(): Promise<GeolocationPosition | null> {
    return new Promise(async (resolve) => {

      if (Capacitor.getPlatform() === 'web') {

        navigator.permissions.query({name: 'geolocation'}).then(async (permission) => {

          if (permission.state == 'granted') {

            navigator.geolocation.getCurrentPosition(
              (position: GeolocationPosition) => {
                resolve(position);
              }, positionError => {
                console.error(positionError)
                resolve(null);
              });

          } else if (permission.state == 'prompt') {

            this.translateService.get('COMMUNICATION.REQUEST_DESKTOP.POSITION').subscribe(async (message: string) => {

              const toast = await this.toastController.create({
                message: message,
                translucent: true,
                duration: 5000,
                position: 'top',
                color: "dark",
                mode: 'ios',
                cssClass: 'toast-vertical-adjust',
                buttons: [
                  {
                    side: 'start',
                    role: 'cancel',
                    icon: 'location-dot'
                  },
                  {
                    role: 'cancel',
                    icon: 'xmark'
                  },
                ]
              });

              toast.onDidDismiss().then(() => {
                navigator.geolocation.getCurrentPosition(
                  (position: GeolocationPosition) => {
                    resolve(position);
                  }, positionError => {
                    console.error(positionError)
                    resolve(null);
                  });
              })

              await toast.present();
            });

          } else {
            resolve(null);
          }
        });

      } else if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {

        Geolocation.checkPermissions().then(async permission => {
          if (permission.location == 'granted') {

            Geolocation.getCurrentPosition().then(position => {
              resolve(position);
            }).catch(() => {
              this.communicationService.notify('PERMITS_REQUEST.POSITION.DENIED', 'danger')
              resolve(null);
            })

          } else if (permission.location == 'prompt' || permission.location == 'prompt-with-rationale') {

            const modal = await this.modal.create({
              component: RequestPositionComponent,
              backdropDismiss: true,
              mode: "ios",
              cssClass: 'auto-height'
            });

            modal.onDidDismiss().then(async () => {
              Geolocation.requestPermissions().then(permission => {
                console.log(permission)
                if (permission.location == 'granted') {
                  Geolocation.getCurrentPosition().then(position => {
                    resolve(position);
                  }).catch(() => {
                    this.communicationService.notify('PERMITS_REQUEST.POSITION.DENIED', 'danger')
                    resolve(null);
                  })
                }
              })
            });

            await modal.present();

          } else {
            resolve(null);
          }
        })

      } else {
        Geolocation.getCurrentPosition().then(position => {
          resolve(position);
        }).catch(() => {
          this.communicationService.errorToast(this.translateService.instant('PERMITS_REQUEST.POSITION.DENIED'))
          resolve(null);
        })
      }
    })
  }

}
