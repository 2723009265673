import {AfterViewInit, Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'signature-component',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {

  @Input() name: string;
  @ViewChild('sigPad') sigPad;
  sigPadElement;
  context;
  isDrawing = false;
  img;

  @Output() signEmitted: EventEmitter<any> = new EventEmitter();

  @Output() modalOpened: EventEmitter<any> = new EventEmitter();
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  public isSignatureOpen: boolean = false;


  constructor(
    private modalControl: ModalController
  ) {
  }

  ngAfterViewInit() {
  }

  onModalPresent() {
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#000000';
    this.modalOpened.emit()
  }


  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }


  onMouseDown(e) {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  clear() {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
  }

  save() {
    const resizedCanvas = document.createElement("canvas");
    const resizedContext = resizedCanvas.getContext("2d");

    const height: number = 75;
    const width: number = 250;
    resizedCanvas.height = height;
    resizedCanvas.width = width;

    resizedContext.drawImage(this.sigPadElement, 0, 0, width, height);
    this.img = resizedCanvas.toDataURL();
    this.signEmitted.emit(this.img);
    this.modalControl.dismiss().then()
    this.modalClosed.emit()
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    let x;
    let y;
    if (event?.touches?.length > 0) {
      x = event.touches[0].clientX - bounds.x;
      y = event.touches[0].clientY - bounds.y;
    } else {
      x = event.clientX - bounds.x;
      y = event.clientY - bounds.y;
    }
    return {x: x, y: y};
  }

  setSignatureOpen(isOpen: boolean) {
    this.isSignatureOpen = isOpen
  }

}
