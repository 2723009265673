<ion-button (click)="setSignatureOpen(true)" class="ion-btn-primary mb-3">
  <ion-icon class="me-2" name="file-pen"></ion-icon>
  Firma
</ion-button>

<ion-modal (ionModalWillDismiss)="setSignatureOpen(false)" (ionModalWillPresent)="onModalPresent()"
           [isOpen]="isSignatureOpen" canDismiss="true"
           cssClass="auto-height" mode="ios">
  <ng-template>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title class="montserrat text-white text-wrap">
          Firma
        </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="setSignatureOpen(false)" slot="icon">
            <ion-icon color="light" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="inner-content">
      <div class="modal-body p-4">
        <div class="d-flex justify-content-center flex-column align-items-center h-100 mt-3">
          <canvas #sigPad (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                  (touchend)="onMouseUp($event)" (touchmove)="onMouseMove($event)" (touchstart)="onMouseDown($event)"
                  height="150" width="500">
          </canvas>
          <div class="d-flex gap-3 my-3">
            <ion-button (click)="clear()" class="ion-btn-light">Pulisci</ion-button>
            <ion-button (click)="save()" class="ion-btn-primary">Salva</ion-button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ion-modal>


