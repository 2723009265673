import {AfterViewInit, Directive, ElementRef, Input, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from "@angular/common";

@Directive({
  selector: '[fitSwiper]'
})
export class FitSwiperDirective implements AfterViewInit {

  private readonly swiperElement: HTMLElement;

  @Input('config')
  config?: any;

  constructor(private el: ElementRef<HTMLElement>) {
    if (!isPlatformServer(PLATFORM_ID)) {
      this.swiperElement = el.nativeElement;
    }
  }

  ngAfterViewInit() {
    if (!isPlatformServer(PLATFORM_ID)) {
      Object.assign(this.el.nativeElement, this.config);
      // @ts-ignore
      /*
            this.el.nativeElement.initialize();
      */
    }
  }
}
