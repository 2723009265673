import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {filter} from "rxjs";
import {UserModel} from "@app/models/user.model";
import {AuthService} from "@app/services/auth.service";
import {UserService} from "@app/services/user.service";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {CtaService} from "@app/services/cta.service";
import {fadeInAnimation} from "@app/animations";

@Component({
  selector: 'newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss'],
  animations: [fadeInAnimation]
})
export class NewsletterFormComponent implements OnInit, OnDestroy {

  @Input() argument: String;

  /*BOOLEAN*/
  public isLoading: boolean = false;
  public isSent: boolean = false;

  /*FORM*/
  public newsletterForm: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private ctaService: CtaService
  ) {
    this.newsletterForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.email])],
    })

  }

  ngOnInit() {
    this.authService.user_obs.pipe(filter(x => x !== null)).subscribe({
      next: (user: UserModel) => {
        const isAuthenticated = !this.authService.tokenExpirationCheck(user?.token);
        if (isAuthenticated) {
          this.userService.getUserData().subscribe({
            next: user => {
              if (user) {
                this.newsletterForm.patchValue(user)
              }
            }
          })
        }
      }
    })
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  saveNewsLetter() {
    this.isLoading = true
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        this.ctaService.subscribeGenoma(this.newsletterForm.get('email').value, token).subscribe({
          next: () => {
            this.isLoading = false;
            this.isSent = true;
            console.log('Fatto')
          },
          error: err => {
            this.isLoading = false;
            console.error(err)
          }
        })
      });
  }

  public ngOnDestroy() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

}
