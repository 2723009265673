import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {CtaModel} from "@app/models/cta.model";

@Injectable({providedIn: 'root'})
export class CtaService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
  ) {
  }

  getCta(type, position, page): Observable<CtaModel[]> {
    return this.http.get  <any>(API_URL + '/cta', this.globalService.setParams({
      type,
      position,
      page
    })).pipe(map((data: CtaModel[]) => {
      return data;
    }));
  }

  subscribeGenoma(email: string, token: string): Observable<CtaModel[]> {
    return this.http.post<any>(API_URL + '/subscribe_genoma_mailing', {
      email,
      token
    }).pipe(map((data: CtaModel[]) => {
      return data;
    }));
  }

}
