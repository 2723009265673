import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {HomeService} from "@app/pages/home/home.service";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {GlobalService} from "@app/services/global.service";
import {RecommendationModel} from "@app/models/recommendation.model";
import {FileService} from "@app/services/file.service";


@Component({
  selector: 'component-recommendation-home',
  templateUrl: './recommendation-home.component.html',
  styleUrls: ['./recommendation-home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecommendationHomeComponent implements OnInit {
  public recommendationArray: RecommendationModel[];
  public isLoading: boolean = false;

  public defaultImage = 'assets/img/default/user.webp';

  constructor(
    private homeService: HomeService,
    public navigationRoute: NavigationRouteService,
    public globalService: GlobalService,
    private fileService: FileService
  ) {
  }

  public config = {
    spaceBetween: 30,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false
    },
    /*loop: true,*/
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
    /*pagination: {
      el: ".swiper-pagination",
    },*/
  }

  ngOnInit() {
    this.isLoading = true
    const sort = '[{"property": "id", "direction": "DESC"}]';
    this.homeService.getLatestRecommendation(sort, '6', '1').subscribe((recommendationArray) => {
      this.recommendationArray = recommendationArray;
      recommendationArray.forEach(recommendation => {
        this.fileService.getImage('thumbnail', 'profile-image', recommendation.user.id).subscribe({
          next: value => {
            if (value) {
              this.recommendationArray[this.recommendationArray.indexOf(recommendation)]['profile_image'] = value
            }
          }
        })
      })
      this.isLoading = false;
    })
  }
}
