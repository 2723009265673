import {Component, Input, OnInit} from '@angular/core';
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {LoginComponent} from "@app/pages/auth/login/login.component";
import {SignupComponent} from "@app/pages/auth/signup/signup.component";
import {AlertController, ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {AuthService} from "@app/services/auth.service";
import {UserService} from "@app/services/user.service";
import {UserModel} from "@app/models/user.model";
import {CommunicationService} from "@app/helper/communication.service";
import {fadeInAnimation} from "@app/animations";
import {filter} from "rxjs";
import {NotificationService} from "@app/services/notification.service";
import {NotificationModel} from "@app/models/notification.model";
import {TranslateService} from "@ngx-translate/core";
import {NotificationModalComponent} from "@app/components/notification-modal/notification-modal.component";
import {ChooseUserTypeModalComponent} from "@app/components/choose-user-type-modal/choose-user-type-modal.component";

@Component({
  selector: 'fit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInAnimation]
})
export class HeaderComponent implements OnInit {

  @Input() userRole: UserModel["role"];

  public isAuthenticated: boolean = false;
  public isLoading: boolean;

  public userData: UserModel;
  public userImage: string;

  /* MODAL */
  public isSearchIsOpen: boolean = false;
  public isHomepage: boolean = false;
  public isHeaderMenuOpen: boolean = false;
  public isNotificationModal: boolean = false;

  public isUnreadNotificationAvaiable: boolean = false;

  public notificationArray: NotificationModel[];


  constructor(
    public navigationRoute: NavigationRouteService,
    public router: Router,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private userService: UserService,
    private communicationService: CommunicationService,
    private notificationService: NotificationService,
    private alertController: AlertController,
    private translateService: TranslateService
  ) {

  }

  ngOnInit() {
    this.userData = null;
    this.isLoading = true;
    this.authService.user_obs.pipe(filter(x => x !== null)).subscribe({
      next: (user: UserModel) => {
        this.isAuthenticated = !this.authService.tokenExpirationCheck(user?.token);
      }
    })
    if (this.isAuthenticated) {
      this.userService.getUserData().subscribe({
        next: (userData: UserModel) => {
          this.userData = userData
          this.userRole = userData?.role
        }
      });
      this.userService.getUserProfileImage('thumbnail', 'profile-image').subscribe();
      this.userService.user_thumb_obs.subscribe({
        next: image => {
          this.userImage = image
        }
      });
      this.notificationService.loadNotification();
      this.notificationService.notifications_obs.subscribe({
        next: notifications => {
          this.notificationArray = notifications;
          this.checkIfUnreadedNotification();
        }
      })
    }
    /*this.showModalSignup().then()*/
  }

  async showModalLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height'
    });
    this.isLoading = true

    modal.onDidDismiss()
      .then(() => {
        this.ngOnInit()
      });

    return modal.present();
  }

  async showModalSignup() {
    const modal = await this.modalCtrl.create({
      component: SignupComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height',
    });
    const modalChooseUserType = await this.modalCtrl.create({
      component: ChooseUserTypeModalComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height',
    });
    this.isLoading = true

    modal.onDidDismiss().then(() => {
      this.isLoading = true;
      this.ngOnInit()
    });

    modalChooseUserType.onDidDismiss().then(data => {
      if (data.role === 'fitter') {
        return modal.present();
      } else if (data.role === 'operator') {
        this.router.navigate(['/registrazione-operatore']).then();
      }
    });

    return modalChooseUserType.present();
  }

  onLogout() {
    this.isLoading = true
    this.authService.logout().then(() => {
      this.isAuthenticated = false;
      this.communicationService.warnToast('Logout effettuato con successo').then(() => {
        this.isLoading = false
      });
    });
  }

  setSearchIsOpen(isOpen: boolean) {
    this.isSearchIsOpen = isOpen
  }

  setHeaderMenuOpen(isOpen: boolean) {
    this.isHeaderMenuOpen = isOpen;
  }

  setNotificationModalOpen(isOpen: boolean) {
    this.isNotificationModal = isOpen;
  }

  async openNotification(notification: NotificationModel) {
    this.setNotificationModalOpen(false)

    if (!notification.readed) {
      this.notificationArray[this.notificationArray.indexOf(notification)].readed = true;
      this.checkIfUnreadedNotification();
      this.notificationService.changeReadNotificationStatus(notification.id).subscribe()
    }

    const modal = await this.modalCtrl.create({
      component: NotificationModalComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'auto-height',
      componentProps: {
        notification: notification
      }
    });

    modal.onDidDismiss().then(() => {
      this.setNotificationModalOpen(true)
    });

    return modal.present();
  }


  /*async openSearch() {
    const modal = await this.modalCtrl.create({
      component: SportSearchbarComponent,
      canDismiss: true,
      mode: "ios",
      cssClass: 'search-bar',
    });
    this.isLoading = true

    modal.onDidDismiss()
      .then(() => {
        this.isLoading = true;
        this.ngOnInit()
      });

    return modal.present();
  }*/

  async swipeNotification(notification: NotificationModel, side?: any, slidingItem?: any) {

    if (side === 'start') {

      this.notificationArray[this.notificationArray.indexOf(notification)].readed = !this.notificationArray[this.notificationArray.indexOf(notification)].readed;
      this.checkIfUnreadedNotification();
      this.notificationService.changeReadNotificationStatus(notification.id).subscribe()

    } else if (side === 'end') {

      const alert = await this.alertController.create({
        header: this.translateService.instant('ALERT.NOTIFICATION.ARCHIVE.HEADER'),
        cssClass: 'custom-alert-success',
        mode: "md",
        buttons: [
          {
            text: 'No',
            role: 'cancel'
          },
          {
            text: this.translateService.instant('ALERT.NOTIFICATION.ARCHIVE.YES_BUTTON'),
            role: 'confirm',
            handler: () => {
              this.notificationArray.splice(this.notificationArray.indexOf(notification), 1);
              this.notificationService.deleteNotification(notification.id).subscribe()
            },
          },
        ],
      });

      await alert.present();
    }

    slidingItem.close()

  }

  checkIfUnreadedNotification() {
    /*this.notificationArray.forEach(notification => {
        this.isUnreadNotificationAvaiable = !notification.readed;
    })*/

    if (this.notificationArray?.length > 0) {

      this.isUnreadNotificationAvaiable = this.notificationArray.some(function (el) {
        return el.readed === false
      });

    }
  }

}
