<ion-header>
  <ion-toolbar class="h-auto" color="primary">
    <ion-buttons slot="end">
      <ion-button slot="icon" (click)="modalCtrl.dismiss()">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="montserrat text-white text-wrap">
      Metti il Turbo!
    </ion-title>
  </ion-toolbar>
</ion-header>
<div class="inner-content">
  <div class="modal-body py-3 px-4">

    <h6 class="text-wide text-uppercase mt-0 text-center">
      Questa è una funzione riservata agli operatori premium, scopri come mettere il turbo:
    </h6>

    <div class="row g-0 gap-3">
      <div class="col-md">

        <div class="w-100 centered-middle-column">
          <lottie-player autoplay loop src="/assets/lottie/rocket.json" style="width: 200px"></lottie-player>
        </div>

        <h1 class="bold ion-color-primary mb-4 text-center">TurboFit<span class="light">™</span></h1>
        <div class="text-start">
          <!--<h5 class="text-center bold ion-color-secondary m-0 my-2 mb-3">
            Migliora la performance della tua vetrina, investendo meno di 0,40 centesimi al giorno
          </h5>-->
          <h6>Diventando campione potrai:</h6>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Migliorare il tuo punteggio digitale
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Aggiungere il tuo logo, sfruttando al 100% il passaparola online fatto dai tuoi stessi corsisti
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Permettere al nostro pubblico di utenti di richiedere una prova sulle tue lezioni inserite
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Mettere in evidenza i tuoi servizi per differenziarti dai tuoi competitor
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Mettere in evidenza le tue migliori foto, video e certificazioni
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Inserire nel tuo palinsesto i corsi svolti online
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Attivare promozioni per raggiungere più velocemente utenti interessati alla tua offerta sportiva
            </p>
          </div>
          <!--<div class="centered-start my-2">
            <ion-icon name="rocket" class="me-2 ion-color-primary"></ion-icon>
            <p class="m-0">
              Accedere a statistiche ulteriori in modalità avanzata per permetterti di analizzare i dati della tua
              vetrina
            </p>
          </div>-->
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Supporto nell’utilizzo dello strumento con gli esperti di Fitadvisor a tua disposizione
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Garanzia soddisfatti o rimborsati
            </p>
          </div>
          <!--<div class="centered-middle my-3">
            <img src="https://ucarecdn.com/76bf98f2-05cb-4a7b-b6e9-b5183228d42d/coccarda50x50.png" alt="">
            <h4 class="text-center bold ion-color-primary m-0">
              10 richieste di prova garantite
            </h4>
            <i class="fa-solid fa-info-circle ms-2 ion-color-primary" id="click-trigger"></i>
          </div>-->
          <div class="centered-middle-column my-4">
            <h5 class="text-center bold ion-color-primary m-0">
              Media recensioni servizio
            </h5>
            <div class="centered-middle">
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <h4 class="bold ion-color-primary m-0 ms-2 mt-2">
                4,9/5
              </h4>
            </div>
          </div>
        </div>
        <div class="text-center">
          <ion-button (click)="showPaymentModal()" class="ion-btn-secondary mt-3" expand="block">
            Scopri i listini dedicati
          </ion-button>
          <!--<ion-button [routerLink]="navigationRoute.navigate('check-up')" class="ion-btn-primary mt-3">
            Vai al check-up
          </ion-button>-->
        </div>
        <ion-popover alignment="center" class="popover-top-fixed" mode="md" side="top" trigger="click-trigger"
                     triggerAction="click">
          <ng-template>
            <ion-content class="ion-padding rounded">
              <p>
                Grazie alla garanzia performance Fitadvisor ti garantisce un minimo di 10
                richieste nei 12 mesi, finchè non raggiungerai tale soglia il pacchetto turbo non avrà scadenza
                temporale.
              </p>
            </ion-content>
          </ng-template>
        </ion-popover>
      </div>
      <div *ngIf="operatorData?.turbo_name !== 'TURBO WEB MEDIA PRIME'" class="col-md">
        <!--<div class="w-100 centered-middle-column">
          <lottie-player autoplay loop src="/assets/lottie/rocket.json" style="width: 200px"></lottie-player>
        </div>-->
        <h1 class="bold ion-color-primary mb-4 text-center">Web Media Prime<span class="light">™</span></h1>
        <div class="text-start">
          <!--<h5 class="text-center bold ion-color-secondary m-0 my-2 mb-3">
            Migliora la performance della tua vetrina, investendo meno di 0,40 centesimi al giorno
          </h5>-->
          <h6>Personalizza l'aspetto del tuo profilo:</h6>

          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Carica un logo personalizzato
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Carica immagini
            </p>
          </div>
          <div class="centered-start my-2">
            <ion-icon class="me-2 ion-color-primary" name="rocket"></ion-icon>
            <p class="m-0">
              Carica video
            </p>
          </div>
          <!--<div class="centered-middle my-3">
            <img src="https://ucarecdn.com/76bf98f2-05cb-4a7b-b6e9-b5183228d42d/coccarda50x50.png" alt="">
            <h4 class="text-center bold ion-color-primary m-0">
              10 richieste di prova garantite
            </h4>
            <i class="fa-solid fa-info-circle ms-2 ion-color-primary" id="click-trigger"></i>
          </div>-->
          <!--<div class="centered-middle-column my-4">
            <h5 class="text-center bold ion-color-primary m-0">
              Media recensioni servizio
            </h5>
            <div class="centered-middle">
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <ion-icon class="fit-color-warning size-30" name="star"></ion-icon>
              <h4 class="bold ion-color-primary m-0 ms-2 mt-2">
                4,9/5
              </h4>
            </div>
          </div>-->
        </div>
        <div class="text-center">
          <ion-button (click)="buyMediaPrime()" class="ion-btn-secondary mt-3" expand="block">
            Acquista al nuovo prezzo promozionare
          </ion-button>
          <!--<ion-button [routerLink]="navigationRoute.navigate('check-up')" class="ion-btn-primary mt-3">
            Vai al check-up
          </ion-button>-->
        </div>
        <ion-popover alignment="center" class="popover-top-fixed" mode="md" side="top" trigger="click-trigger"
                     triggerAction="click">
          <ng-template>
            <ion-content class="ion-padding rounded">
              <p>
                Grazie alla garanzia performance Fitadvisor ti garantisce un minimo di 10
                richieste nei 12 mesi, finchè non raggiungerai tale soglia il pacchetto turbo non avrà scadenza
                temporale.
              </p>
            </ion-content>
          </ng-template>
        </ion-popover>
      </div>

    </div>

  </div>
</div>
