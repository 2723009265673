import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {fadeInAnimation} from "@app/animations";
import {CommunicationService} from "@app/helper/communication.service";
import {AddressService} from "@app/components/address/address.service";
import {SportFacilityModel} from "@app/models/sport-facility.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SportClubModel} from "@app/models/sport-club.model";

@Component({
  selector: 'address-component',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  animations: [fadeInAnimation]
})
export class AddressComponent implements OnInit {

  @Input() sportClub: SportClubModel
  @Input() sportFacility: SportFacilityModel
  @Input() isEditable: boolean = true;

  @Input() showFields: boolean = true;
  @Input() showMap: boolean = true;

  @Output() emittedAddress: EventEmitter<any> = new EventEmitter();

  /* BOOL */
  public isLoading: boolean = false;
  public address: string;

  /* MAPS */
  public center: google.maps.LatLngLiteral = {lat: 43.2693684969088, lng: 12.376572354285495};
  public options: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
    scrollwheel: false,
    zoom: 6,
  };

  /*MARKER*/
  public marker_options: google.maps.MarkerOptions = {
    icon: 'https://ucarecdn.com/d8bf2b59-01e8-4030-b86d-bce457d52bfe/fitmarker.png',
    draggable: true
  };
  markerPositions: google.maps.LatLngLiteral[] = [];
  public isModalOpen: boolean = true;

  /*MODAL*/
  public isSFModalOpen: boolean = false;
  public selectedAddress: google.maps.GeocoderAddressComponent[];
  public searchSportFacilityData: SportFacilityModel[] = [];
  public addressForm: FormGroup;

  private foundPlaces: google.maps.places.PlaceResult;
  private lat: number;
  private lng: number;

  constructor(
    public modalCtrl: ModalController,
    private addressService: AddressService,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
  ) {
    this.addressForm = this.formBuilder.group({
      address: ['', Validators.required],
      cap: [''],
      civic_number: [''],
      state_code: ['', Validators.required],
      city_name: ['', Validators.required],
      country_name: ['', Validators.required],
      lat: [''],
      lng: [''],
    })
  }

  ngOnInit() {
    if (this.sportClub) {
      this.addressForm.patchValue(this.sportClub)
      if (this.sportClub.lat && this.sportClub.lng) {
        this.center = {lat: Number(this.sportClub.lat), lng: Number(this.sportClub.lng)};
        this.options = {zoom: 15}
      }
    }
    if (this.sportFacility) {
      this.addressForm.patchValue(this.sportFacility)

      if (this.sportFacility.lat && this.sportFacility.lng) {
        this.center = {lat: Number(this.sportFacility.lat), lng: Number(this.sportFacility.lng)};
        this.options = {zoom: 15}
      }
    }
  }

  autocomplete(searchBarElement) {
    searchBarElement.getInputElement().then(input => {
      const autocomplete = new google.maps.places.Autocomplete(input,
        {
          fields: ["formatted_address", "geometry", "address_component"],
          componentRestrictions: {country: 'IT'},
          strictBounds: false,
        });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        /*console.log(place)
        this.foundPlaces = place
        this.address = place.formatted_address*/
        this.center = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
        this.options = {zoom: 15}
        /*this.lat = place.geometry.location.lat()
        this.lng = place.geometry.location.lng()*/
        this.onSelectionAddress(place.address_components, place.geometry.location.lat(), place.geometry.location.lng())
      });
    });
  }

  getNewMarkerPosition(e) {
    this.addressService.getAddressFromCoordinates(e.latLng.lat(), e.latLng.lng()).subscribe({
      next: res => {
        this.addressForm.patchValue({
          lat: res.results[0].geometry.location.lat,
          lng: res.results[0].geometry.location.lng
        })
        this.emittedAddress.emit(this.addressForm.value)
      }
    })
  }

  saveAddress() {
    const place = {
      found_place: this.foundPlaces,
      lat: this.lat,
      lng: this.lng
    }
    this.modalCtrl.dismiss(place).then()
  }

  onSelectionAddress(selectedAddress: google.maps.GeocoderAddressComponent[], lat, lng) {
    this.selectedAddress = selectedAddress
    selectedAddress.forEach(sA => {
      if (sA.types[0] === 'street_number') {
        this.addressForm.patchValue({civic_number: sA.short_name})
      } else if (sA.types[0] === 'route') {
        this.addressForm.patchValue({address: sA.short_name})
      } else if (sA.types[0] === 'administrative_area_level_3') {
        this.addressForm.patchValue({city_name: sA.short_name})
      } else if (sA.types[0] === 'administrative_area_level_2') {
        this.addressForm.patchValue({state_code: sA.short_name})
      } else if (sA.types[0] === 'administrative_area_level_1') {
        this.addressForm.patchValue({country_name: sA.short_name})
      } else if (sA.types[0] === 'postal_code') {
        this.addressForm.patchValue({cap: sA.short_name})
      }
    })
    this.addressForm.patchValue({lat: lat, lng: lng})
    this.emittedAddress.emit(this.addressForm.value)
  }

  emitCivic() {
    this.emittedAddress.emit(this.addressForm.value)
  }

  warnUser() {
    /*this.communicationService.warnToast('Cerca l\'indirizzo completo per riempire automaticamente i campi sottostanti').then(() => {
      this.isEditable = true;
    })*/
    this.isEditable = true;
  }
}
