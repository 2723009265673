<!--<div class="container px-0 mb-2">
  <div class="text-center bg-white align-content-center justify-content-center d-flex text-center flex-column">
<a title="Pagina radio bruno" [id]="'open-modal' + banner.id">
          <img alt="sponsor banner" class="object-fit-contain"
               src="assets/img/partner/Nuovo%20progetto.webp">
        </a>

  </div>
</div>-->
<swiper-container [config]="config" class="mb-2 container p-0" fitSwiper>
  <swiper-slide *ngFor="let banner of bannerArray">
    <div [ngSwitch]="banner.interaction_type">
      <div *ngSwitchCase="'pop-up'">
        <a (click)="setIsOpen(true, banner.id)" [title]="banner?.title">
          <img [alt]="banner?.title" [src]="'assets/img/partner/' + banner.image +'.webp'"
               class="object-fit-contain">
        </a>
        <ion-modal (willDismiss)="setIsOpen(false, banner.id)" [isOpen]="isOpen.includes(banner.id)"
                   cssClass="auto-height" mode="ios">
          <ng-template>
            <div class="inner-content">
              <ion-icon (click)="setIsOpen(false, banner.id)"
                        class="fs-2 position-absolute end-0 m-2 opacity-25 z-index-99 pointer"
                        name="xmark"></ion-icon>
              <div class="modal-body p-4">
                <div class="d-flex gap-4 align-items-center">
                  <h5 class="m-0 fw-bolder fs-3 ">{{banner.title}}</h5>
                </div>
                <hr class="bg-dark my-4">
                <p class="fs-6 m-0">{{banner.text}}</p>
                <div class="text-center mt-4 mb-5">
                  <ion-button (click)="modalController.dismiss()" [href]="banner.link" [title]="banner.link_text"
                              class="ion-btn-primary" data-toggle="tooltip" mode="md" target="_blank">
                    {{banner.link_text}}
                  </ion-button>
                </div>

                <newsletter-form>
                </newsletter-form>

              </div>
            </div>
          </ng-template>
        </ion-modal>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
