import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {PagesPageRoutingModule} from './pages-routing.module';

import {PagesPage} from './pages.page';
import {RecaptchaModule} from "ng-recaptcha";
import {NgProgressModule} from "ngx-progressbar";
import {LazyLoadImageModule} from "ng-lazyload-image";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PagesPageRoutingModule,
    RecaptchaModule,
    NgProgressModule,
    LazyLoadImageModule,
    ReactiveFormsModule
  ],
  declarations: [PagesPage],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesPageModule {
}
