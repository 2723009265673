import {ImageModel} from "@app/models/image.model";
import {RecommendationModel} from "@app/models/recommendation.model";
import {ReviewModel} from "@app/models/review.model";
import {SportModel} from "@app/models/sport.model";
import {UserModel} from "@app/models/user.model";

export class FitterModel {

  public id: number;
  public user_id: number;
  public name: string;
  public surname: string;
  public email: string;
  public birth_year: number;
  public sex: string;
  public favorite_exercise: string;
  public country_name: string;
  public state_id: number;
  public state_code: string;
  public city_id: number;
  public city_name: string;
  public country_id: number;
  public location: string;
  public user_image: ImageModel
  public medal: MedalModel[]
  public activity: ActivityFitterModel
  public sport: SportModel

  constructor(data: any = []) {
    if (data) {
      for (const key in data) {
        this[key] = data[key] ?? null;
      }
    }
  }

}

export class MedalModel {

  public id: number;
  public name: string;
  public description: string;
  public image: ImageModel

}

export class ActivityFitterModel {

  public recommendation ?: RecommendationModel[]
  public review ?: ReviewModel[]
  public tot_recommendation: number
  public tot_review: number
  public tot_useful_vote: number

}

export class ClubFollowerModel {

  id: number;
  fitter: FitterModel;
  user: UserModel;
  sport_club_id: number;
  member: boolean;
  membership_expiration: string;

}



