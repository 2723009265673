<footer class="container-fluid ion-bg-primary mt-2 p-0">
  <div class="container p-lg-5 p-md-0 py-md-4 p-0 py-3 text-white">
    <div class="row text-start">
      <div class="col-md-5 my-2 my-md-0 col-12 text-center text-md-start ">
        <img src="assets/img/logo/logo-fitadvisor-neg.svg"
             class="img-fluid logo-fitadvisor d-block mx-auto mx-md-0 mb-3"
             alt="FitAdvisor - Il portale dello sportivo"/>
        <span class="bold">&copy; 2023 Fitadvisor s.r.l. | PMI Innovativa</span><br/>
        Viale Brescia 57/a Mazzano 25080 BS<br/>
        P.IVA 03738100985<br/>REA BS-558975<br/>Cap.Sociale 14.620,15€ I.V.<br/>
        E-mail: <a class="ion-color-primary-contrast bold" href="mailto:info@fitadvisor.it" rel="nofollow">info@fitadvisor.it</a>
        <br/>
        PEC: <a href="mailto:fitadvisor@legalmail.it" rel="nofollow"
                class="ion-color-primary-contrast bold">fitadvisor@legalmail.it</a>
        <br>
        <a class="ion-color-primary-contrast"
           href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx" rel="nofollow"
           target="_blank">REGISTRO NAZIONALE DEGLI AIUTI DI STATO</a>
      </div>
      <div class="col-12 text-center text-sm-start col-sm-6 col-md-3 offset-md-1 footer-link ">
        <h3 class="text-light mb-3 bold">Altre info:</h3>
        <ul class="list-unstyled">
          <li class="mb-2">
            <!--<span  [routerLink]="navigationRoute.navigate('about-us')">
              Chi siamo
            </span>-->
            <a [routerLink]="navigationRoute.navigate('about-us')">
              Chi siamo
            </a>
          </li>
          <li class="mb-2">
            <a [routerLink]="navigationRoute.navigate('media')">
              Media
            </a>
          </li>
          <li class="mb-2">
            <a [routerLink]="'/blog/c/rubrica-eventi'">
              Eventi Sportivi
            </a>
          </li>
          <li class="mb-2">
            <a [routerLink]="'/blog/a/fitadvisor'">
              Blog
            </a>
          </li>
          <li class="mb-2">
            <a [routerLink]="'/blog/c/autori'">
              I nostri Autori
            </a>
          </li>
          <li class="mb-2">
            <a [routerLink]="navigationRoute.navigate('partner')">
              I nostri Partner
            </a>
          </li>
          <li class="mb-2">
            <a [routerLink]="navigationRoute.navigate('legal-therms')">
              Termini di Utilizzo
            </a>
          </li>
          <div *ngIf="footerExtraInfoArray?.length > 0">
            <li *ngFor="let extra of footerExtraInfoArray" class="mb-2">
              <a [href]="extra.link" target="_blank">
                {{extra.title}}
              </a>
            </li>
          </div>
        </ul>
      </div>
      <div class="col-12 text-center text-sm-start col-sm-6 col-md-3 ">
        <h3 class="text-light mb-3 bold">Seguici su:</h3>
        <div class="d-flex gap-3 flex-md-column justify-content-center  flex-wrap">
          <a class="text-light d-flex align-items-center" href="https://www.facebook.com/FitAdvisor.it/" rel="nofollow"
             target="_blank">
            <ion-icon class="me-2" name="facebook"></ion-icon>
            Facebook
          </a>
          <a class="text-light d-flex align-items-center" href="https://www.instagram.com/fitadvisor.it/" rel="nofollow"
             target="_blank">
            <ion-icon class="me-2" name="instagram"></ion-icon>
            Instagram
          </a>
          <a class="text-light d-flex align-items-center" href="https://www.linkedin.com/company/fitadvisor-s-r-l/"
             rel="nofollow"
             target="_blank">
            <ion-icon class="me-2" name="linkedin"></ion-icon>
            Linkedin
          </a>
          <a class="text-light d-flex align-items-center" href="https://twitter.com/fitadvisor_it/" rel="nofollow"
             target="_blank">
            <ion-icon class="me-2" name="twitter"></ion-icon>
            Twitter
          </a>
          <a class="text-light d-flex align-items-center"
             href="https://www.youtube.com/channel/UCVb6Fq03O6veklY-yBbIgFw/" rel="nofollow"
             target="_blank">
            <ion-icon class="me-2" name="youtube"></ion-icon>
            Youtube
          </a>

          <a [routerLink]="navigationRoute.navigate('search-by-sport')"
             class="text-light d-flex align-items-center pointer"
             href="javascript:void(0)">
            Ricerca per sport
          </a>
          <a [routerLink]="navigationRoute.navigate('search-by-goal')"
             class="text-light d-flex align-items-center pointer"
             href="javascript:void(0)">
            Ricerca per obiettivo
          </a>
          <a [routerLink]="navigationRoute.navigate('search-by-category')"
             class="text-light d-flex align-items-center pointer"
             href="javascript:void(0)">
            Ricerca per categoria sportiva
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid text-center ion-bg-tertiary mt-2 px-0 ">
    <p class="py-2 mb-0">
      <span (click)="eraseCookie()" class="ion-color-primary-contrast pointer">
        Gestione cookies
      </span> -
      <a [routerLink]="navigationRoute.navigate('legal-therms')" class="pointer ion-color-primary-contrast"
         rel="nofollow" target="_blank">
        Cookie Policy
      </a>
    </p>
  </div>

</footer>
