import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FileService} from '@app/services/file.service';
import {ImageCroppedEvent, ImageCropperComponent} from "ngx-image-cropper";
import {AlertController, IonModal, ModalController} from "@ionic/angular";
import {CommunicationService} from "@app/helper/communication.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {fadeInAnimation} from "@app/animations";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  animations: [fadeInAnimation]
})
export class FileUploadComponent implements OnInit {

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ViewChild(IonModal) modal: IonModal;

  @Input() isSingleImage: boolean;
  @Input() fileSection: string;
  @Input() id: number;

  public file: string = null; // Variable to store file to Upload
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public selectedFiles: any[] = [];
  public progressInfos: any[] = [];
  public imageFile: any;
  public croppedImageArray: any[] = [];
  public aspectRatio: number;
  public aspectRatioKeep: boolean = false;


  constructor(
    private uploadService: FileService,
    private alertController: AlertController,
    private communicationService: CommunicationService,
    public modalCrl: ModalController
  ) {
  }

  ngOnInit(): void {
    if (this.fileSection == 'profile-image') {
      this.aspectRatio = 1
      this.aspectRatioKeep = true
    }
  }

  onImageSelect(event) {
    this.progressInfos = [];
    this.selectedFiles = Array.from(event.target.files)
    this.selectedFiles.forEach((file: File) => {
      if (file.size == 0) {
        this.communicationService.customErrorToast('FILE_NOT_SUPPORTED', 'danger').then(() => {
          this.imageFile = null;
          this.selectedFiles = [];
          this.modalCrl.dismiss().then()
        })
      } else if (file.size > 2000000) {
        this.communicationService.customErrorToast('FILE_TOO_HEAVY', 'danger').then(() => {
          this.imageFile = null;
          this.selectedFiles = [];
        })
      } else {
        this.moveImagesQueue()
      }
    })
  }

  moveImagesQueue() {
    this.imageFile = this.selectedFiles[0]
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this.communicationService.customErrorToast('FILE_NOT_SUPPORTED', 'danger').then(() => {
      this.imageFile = null;
      this.selectedFiles = [];
    })
  }

  CutImage() {
    this.croppedImageArray.push(this.croppedImage)
    if (this.selectedFiles.length > 0) {
      this.selectedFiles.splice(this.selectedFiles[0], 1);
      this.moveImagesQueue()
    }
  }

  removeImageFromArray(image: string) {
    this.croppedImageArray.splice(this.croppedImageArray.indexOf(image), 1);
  }

  uploadFilesMultiple(section): void {
    if (this.croppedImageArray) {
      this.croppedImageArray.forEach(image => {
        if (this.id) {
          this.upload(this.croppedImageArray.indexOf(image), image, section, this.id).then(() => {
            this.croppedImageArray.slice(image, 1)
          });
        } else {
          this.upload(this.croppedImageArray.indexOf(image), image, section).then(() => {
            this.croppedImageArray.slice(image, 1)
          });
        }
      })
    }
  }

  async upload(index_id: number, file: string, section, id ?: number): Promise<void> {
    this.progressInfos[index_id] = {value: 0};
    if (id) {
      this.uploadService.upload(file, section, id).subscribe({
        next: (event: any) => {
          if (event?.type === HttpEventType.UploadProgress) {
            this.progressInfos[index_id].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            let msg = ''
            if (this.isSingleImage) {
              msg = 'Immagine caricata correttamente';
            } else {
              msg = 'File' + index_id + ' caricato correttamente';
            }
            this.communicationService.successToast(msg).then(() => {
              this.progressInfos = [];
              this.selectedFiles = null;
            })
          }
        },
        error: (err: any) => {
          console.log(err)
          this.progressInfos[index_id].value = 0;
          let msg = ''
          if (this.isSingleImage) {
            msg = 'Impossibile caricare il file, riprova';
          } else {
            msg = 'Impossibile caricare il file' + index_id + ', riprova';
          }
          this.communicationService.errorToast(msg).then(() => {
            this.progressInfos = [];
            this.selectedFiles = null;
            this.modalCrl.dismiss()
          })
        }, complete: () => {
          this.modalCrl.dismiss()
        }
      });
    } else {
      this.uploadService.upload(file, section).subscribe({
        next: (event: any) => {
          if (event?.type === HttpEventType.UploadProgress) {
            this.progressInfos[index_id].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            let msg = ''
            if (this.isSingleImage) {
              msg = 'Immagine caricata correttamente';
            } else {
              msg = 'File' + index_id + ' caricato correttamente';
            }
            this.communicationService.successToast(msg).then(() => {
              this.progressInfos = [];
              this.selectedFiles = null;
            })
          }
        },
        error: (err: any) => {
          this.progressInfos[index_id].value = 0;
          let msg = ''
          if (this.isSingleImage) {
            msg = 'Impossibile caricare il file, riprova';
          } else {
            msg = 'Impossibile caricare il file' + index_id + ', riprova';
          }
          this.communicationService.errorToast(msg).then(() => {
            this.progressInfos = [];
            this.selectedFiles = null;
            this.modalCrl.dismiss()
          })
        }, complete: () => {
          this.modalCrl.dismiss()
        }
      });
    }
  }

}
