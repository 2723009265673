import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.split('/')[4] === 'calendars') {

      const modifiedReq = request.clone({
        headers: request.headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`),
        params: request.params.append('sport_club', localStorage.getItem('activeSportClubId'))
      });

      return next.handle(modifiedReq)

    } else if (request.url.split('/')[4] === 'edit_calendar_lesson') {

      const modifiedReq = request.clone({
        headers: request.headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`),
      });

      return next.handle(modifiedReq)

    } else {
      return next.handle(request)
    }

  }
}

