import {Component, Input, OnInit} from '@angular/core';
import {CtaService} from "@app/services/cta.service";
import {CtaModel} from "@app/models/cta.model";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'cta-btn',
  templateUrl: './cta-btn.component.html',
  styleUrls: ['./cta-btn.component.scss'],
})
export class CtaBtnComponent implements OnInit {

  @Input() page: String;
  @Input() position: String;

  public isOpen: number[] = [];
  public ctaArray: CtaModel[];

  constructor(
    private ctaService: CtaService,
    public modalController: ModalController
  ) {
  }

  ngOnInit() {
    this.ctaService.getCta('pop-up', this.position, this.page).subscribe({
      next: ctaArray => {
        this.ctaArray = ctaArray
      }
    })
  }

  setIsOpen(isOpen: boolean, id) {
    if (isOpen) {
      this.isOpen.push(id)
    } else {
      this.isOpen.splice(this.isOpen.indexOf(id), 1)
    }
  }

}
