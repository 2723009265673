<!--<ion-button color="light" class="my-3" (click)="setModalOpen(true)" expand="block">
  <ion-icon name="magnifying-glass-location" class="me-2"></ion-icon>
  Cerca un indirizzo
</ion-button>-->

<div>
  <label *ngIf="addressForm.get('address').value !== ''" class="m-0 my-3 bold ion-color-primary text-center">
    Cerca l'indirizzo completo per riempire automaticamente i campi sottostanti
  </label>

  <ion-searchbar class="p-0 my-2 custom" [(ngModel)]="address" placeholder="Cerca l'indirizzo completo"
                 (ionFocus)="autocomplete($event.target)">
  </ion-searchbar>
</div>

<ion-button class="ion-btn-light mt-3" expand="block" (click)="warnUser()" @fadeIn *ngIf="!isEditable">
  <ion-icon name="magnifying-glass-location" class="me-2"></ion-icon>
  <span *ngIf="addressForm.get('address').value !== ''">
      Modifica indirizzo
  </span>
  <span *ngIf="addressForm.get('address').value === ''">
      Aggiungi indirizzo
  </span>
</ion-button>

<form [formGroup]="addressForm" novalidate *ngIf="showFields">
  <div (click)="warnUser()" [ngStyle]="{'my-3 mb-4': addressForm.get('address')}">
    <div class="row">
      <div class="col-12 col-sm" @fadeIn *ngIf="addressForm.get('address').value !== ''">
        <label class="my-2 opacity-50"
               [ngClass]="{ 'text-danger': addressForm.get('address').invalid }">
          Indirizzo<span class="text-danger"> *</span>
        </label>
        <input type="text" class="form-control mt-2" formControlName="address"
               placeholder="Indirizzo" [readOnly]="true"/>
        <div
          *ngIf="addressForm.get('address').invalid">
          <small class="text-danger">Indirizzo richiesto</small>
        </div>
      </div>
      <div class="col-12 col-sm-3" @fadeIn *ngIf="addressForm.get('address').value !== ''">
        <label class="my-2 opacity-50"
               [ngClass]="{ 'text-danger': addressForm.get('civic_number').invalid }">
          N°<span class="text-danger"> *</span>
        </label>
        <input class="form-control mt-2" formControlName="civic_number" placeholder="Numero civico"
               (change)="emitCivic()" type="number"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-3" @fadeIn *ngIf="addressForm.get('cap').value !== ''">
        <label class="my-2 mt-3 opacity-50"
               [ngClass]="{ 'text-danger': addressForm.get('cap').invalid}">
          CAP<span class="text-danger"> *</span>
        </label>
        <input type="text" class="form-control mt-2" formControlName="cap"
               placeholder="Cap" [readOnly]="true"/>
      </div>
      <div class="col-12 col-sm" @fadeIn *ngIf="addressForm.get('city_name').value !== ''">
        <label class="my-2 mt-3 opacity-50"
               [ngClass]="{ 'text-danger': addressForm.get('city_name').invalid}">
          Città<span class="text-danger"> *</span>
        </label>
        <input type="text" class="form-control mt-2" formControlName="city_name"
               placeholder="Città" [readOnly]="true"/>
        <div
          *ngIf="addressForm.get('city_name').invalid">
          <small class="text-danger">Nome città richiesta</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-3" @fadeIn *ngIf="addressForm.get('state_code').value !== ''">
        <label class="my-2 mt-3 opacity-50"
               [ngClass]="{ 'text-danger': addressForm.get('state_code').invalid }">
          Provincia<span class="text-danger"> *</span>
        </label>
        <input type="text" class="form-control mt-2" formControlName="state_code"
               placeholder="Provincia" [readOnly]="true"/>
        <div
          *ngIf="addressForm.get('state_code').invalid">
          <small class="text-danger">Provincia richiesta</small>
        </div>
      </div>
      <div class="col-12 col-sm" @fadeIn *ngIf="addressForm.get('country_name').value !== ''">
        <label class="my-2 mt-3 opacity-50"
               [ngClass]="{ 'text-danger': addressForm.get('country_name').invalid}">
          Regione/Stato<span class="text-danger"> *</span>
        </label>
        <input type="text" class="form-control mt-2" formControlName="country_name"
               placeholder="Regione/stato" [readOnly]="true"/>
        <div
          *ngIf="addressForm.get('country_name').invalid">
          <small class="text-danger">Regione/stato richiesto</small>
        </div>
      </div>
    </div>
    <input type="number" formControlName="lat" [hidden]="true"/>
    <input type="number" formControlName="lng" [hidden]="true"/>
  </div>
</form>

<div *ngIf="addressForm.get('address').value !== '' && showMap" @fadeIn>
  <label class="my-2 ion-color-primary text-center">
    Per una maggiore accuratezza trascina il puntatore sul luogo esatto
  </label>
  <google-map
    height="300px"
    width="100%"
    [center]="center"
    [options]="options"
    class="custom">
    <map-marker
      [position]="center"
      [options]="marker_options"
      (mapDragend)="getNewMarkerPosition($event)"
    >
    </map-marker>
  </google-map>
</div>

<!--
<ion-modal [isOpen]="isModalOpen" (ionModalWillDismiss)="onModalDismiss($event)" cssClass="auto-height" mode="ios"
           canDismiss="true"
           class="pb-5">
  <ng-template>
    <div class="inner-content">
      <div class="px-3 pb-3">


        <google-map
          height="300px"
          width="100%"
          [center]="center"
          [options]="options"
          class="custom">
          <map-marker
            [position]="center"
            [options]="marker_options"
            (mapDragend)="getNewMarkerPosition($event)"
          >
          </map-marker>
        </google-map>

        <div class="row g-0 gap-2 mt-3">
          <div class="col">
            <ion-button class="ion-button-primary" color="light" expand="block" (click)="modalCtrl.dismiss()">
              Annulla
            </ion-button>
          </div>
          <div class="col">
            <ion-button type="submit" color="primary" class="ion-button-primary" expand="block" (click)="saveAddress()"
                        [disabled]="address === ''">
              Salva indirizzo
            </ion-button>
          </div>
          <small class="text-danger p-0 text-center" *ngIf="address === ''">
            <i class="fa fa-circle-exclamation me-2"></i>Non hai inserito tutti i campi obbligatori
          </small>
        </div>
      </div>
    </div>
  </ng-template>
</ion-modal>
-->




