// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAd9kOPzkrjiqUW3bpY0tLNmBEz1oOe-1Y",
    authDomain: "fitadvisor-6ce81.firebaseapp.com",
    projectId: "fitadvisor-6ce81",
    storageBucket: "fitadvisor-6ce81.appspot.com",
    messagingSenderId: "897117245974",
    appId: "1:897117245974:web:a7dc1b8da5e3217b2bdb37",
    vapidKey: "BPORSt6e_NLbXe0koQqTxtNoE55jO9CcXl1xiGRdkxn6-pVYGQXUgRAjH-YljjpEyaRl2_iMuhpjtpomsma5saE",
    measurementId: "G-R6BBGS560P"
  },
  stripe: {
    pub_key: "pk_test_51MqxcXApHGqMDyiyZlnUeYqpQJCxt0q6wvdP3LYlHZ1ZAx7fiYRkxfYCmyLPYc8M1ykXrdr927qefoYy7P0az6MD00jsqcuMxW"
  }
};

/*
export const GOOGLE_KEY = 'AIzaSyBLYFoS8o6WRDFESlf755fKfBDVtZs1-EY'
*/
export const GOOGLE_KEY = 'AIzaSyBLYFoS8o6WRDFESlf755fKfBDVtZs1-EY'

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

//export const API_URL = 'https://apicheckup.fitadvisor.it/api';
export const API_URL = 'https://dev.fitadvisor.it/api';
//export const API_URL = 'https://testapi.fitadvisor.it/api';
//export const API_URL = 'https://api.fitadvisor.it/api';
//export const API_URL = 'https://127.0.0.1:8000/api';

export const WP_API_BASE_URL = 'https://wordpress.fitadvisor.it/wp-json/wp/v2';

export const PAYPAL_DATA = {
  base_url: 'https://api-m.sandbox.paypal.com'
  /*
    base_url: 'https://api-m.paypal.com'
  */
};



