import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'shoe-rating-component',
  templateUrl: './shoe-rating.component.html',
  styleUrls: ['./shoe-rating.component.scss'],
})
export class ShoeRatingComponent implements OnInit {


  constructor() {
  }

  @Input() average_vote: number;


  ngOnInit() {
    /*
        console.log(this.average_vote)
    */
  }


}
