import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {WP_API_BASE_URL} from '@environments/environment';
import {GlobalService} from "@app/services/global.service";
import {WPAuthor, WPCategory, WPPostModel, WPTag} from "@app/models/wp_post.model";

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  getPost(per_page, order_by, order): Observable<WPPostModel[]> {
    return this.http.get<any>(WP_API_BASE_URL + '/posts?_embed', this.globalService.setParams({
      per_page,
      order,
      order_by,
    })).pipe(map((data: WPPostModel[]) => {
      return data;
    }));
  }

  getNextOrPreviousPost(per_page: number, after?: Date, before?: Date): Observable<WPPostModel[]> {
    if (after) {
      return this.http.get<any>(WP_API_BASE_URL + '/posts?_embed', this.globalService.setParams({
        per_page,
        after
      })).pipe(map((data: WPPostModel[]) => {
        return data;
      }));
    } else if (before) {
      return this.http.get<any>(WP_API_BASE_URL + '/posts?_embed', this.globalService.setParams({
        per_page,
        before
      })).pipe(map((data: WPPostModel[]) => {
        return data;
      }));
    }
  }

  getPostById(id): Observable<WPPostModel> {
    return this.http.get<any>(WP_API_BASE_URL + '/posts/' + id + '?_embed').pipe(map((data: WPPostModel) => {
      return data;
    }));
  }

  getPostBySlug(slug: string): Observable<WPPostModel> {
    return this.http.get<any>(WP_API_BASE_URL + '/posts?_embed', this.globalService.setParams({
      slug: slug
    })).pipe(map((data: WPPostModel) => {
      return data[0];
    }));
  }

  searchPost(page: number, author?: number, tags?: number, categories?: number): Observable<WPPostModel[]> {
    return this.http.get<any>(WP_API_BASE_URL + '/posts?_embed', this.globalService.setParamsAndObsResponse({
      page,
      author,
      tags,
      categories
    })).pipe(map((res: HttpResponse<any>) => {
      const keys = [];
      res.headers.keys().map(key => keys[key] = res.headers.get(key));
      const data: WPPostModel[] = res.body;
      data['x-wp-total'] = Number(keys['x-wp-total'])
      data['x-wp-totalpages'] = Number(keys['x-wp-totalpages'])
      return data;
    }));
  }

  getTag(per_page = 100, orderby = 'count', order = 'desc'): Observable<WPTag[]> {
    return this.http.get<any>(WP_API_BASE_URL + '/tags', this.globalService.setParams({
      per_page,
      order,
      orderby
    })).pipe(map((data: WPTag[]) => {
      return data;
    }));
  }

  getCategory(per_page = 100, orderby = 'count', order = 'desc'): Observable<WPCategory[]> {
    return this.http.get<any>(WP_API_BASE_URL + '/categories', this.globalService.setParams({
      per_page,
      order,
      orderby
    })).pipe(map((data: WPCategory[]) => {
      return data;
    }));
  }

  getTagById(id): Observable<WPTag> {
    return this.http.get<any>(WP_API_BASE_URL + '/tags/' + id).pipe(map((data: WPTag) => {
      return data;
    }));
  }

  getTagBySlug(slug): Observable<WPTag> {
    return this.http.get<any>(WP_API_BASE_URL + '/tags', this.globalService.setParams({
      slug: slug
    })).pipe(map((data: WPTag) => {
      return data[0];
    }));
  }

  getCategoryById(id): Observable<WPCategory> {
    return this.http.get<any>(WP_API_BASE_URL + '/categories/' + id).pipe(map((data: WPCategory) => {
      return data;
    }));
  }

  getCategoryBySlug(slug): Observable<WPCategory> {
    return this.http.get<any>(WP_API_BASE_URL + '/categories', this.globalService.setParams({
      slug: slug
    })).pipe(map((data: WPCategory) => {
      return data[0];
    }));
  }

  getAuthorById(id): Observable<WPAuthor> {
    return this.http.get<any>(WP_API_BASE_URL + '/users/' + id).pipe(map((data: WPAuthor) => {
      return data;
    }));
  }

  getAuthorBySlug(slug): Observable<WPAuthor> {
    return this.http.get<any>(WP_API_BASE_URL + '/users', this.globalService.setParams({
      slug: slug
    })).pipe(map((data: WPAuthor) => {
      return data[0];
    }));
  }


}
