<!--<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" slot="icon">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="montserrat text-white text-wrap">
      Accedi
    </ion-title>
  </ion-toolbar>
</ion-header>-->
<div class="inner-content p-3">
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button (click)="closeModal()" class="ion-btn-tertiary fit-shadow" size="small">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div *ngIf="!isRecoveryPasswordMode && Capacitor.getPlatform() !== 'ios'" @fadeIn class="row mb-3 gap-2 g-0">
    <div class="col-12 col-sm my-auto">
      <ion-button (click)="onFacebookLogin()" class="facebook-bg-color-button" expand="block"
                  title="Disabilitato momentaneamente">
        <ion-icon class="me-3" name="facebook" slot="start"></ion-icon>
        Facebook
      </ion-button>
    </div>
    <div class="col-12 col-sm my-auto">
      <ion-button (click)="onGoogleLogin()" class="google-bg-color-button" expand="block">
        <ion-icon class="me-3" name="google" slot="start"></ion-icon>
        Google
      </ion-button>
    </div>
  </div>

  <div *ngIf="!isRecoveryPasswordMode && Capacitor.getPlatform() !== 'ios'" @fadeIn class="text-center">
    <em>{{ 'GENERAL.OR' | translate}}</em>
  </div>

  <div class="alert alert-light border-light border-radius-2">
    <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
      <div *ngIf="!isRecoveryPasswordMode" @fadeIn class="text-center">
        Inserisci credenziali per accedere
      </div>
      <p *ngIf="isRecoveryPasswordMode" @fadeIn class="text-center">
        Inserisci l'e-mail dell'account che vuoi recuperare
      </p>
      <div class="row mt-3 form-group">
        <div class="col-md-12">
          <label>E-mail</label>
        </div>
        <div class="col-md-12">
          <input #email="ngModel" [class.is-invalid]="email.invalid && email.touched" class="form-control"
                 name="email"
                 ngModel pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" required
                 type="email"/>
          <div *ngIf="email.errors && (email.invalid || email.touched)" @fadeIn>
            <small *ngIf="email.errors.required" class="text-danger">Email richiesta</small>
            <small *ngIf="email.errors.pattern" class="text-danger">Inserire una email valida</small>
          </div>
        </div>
      </div>
      <div *ngIf="!isRecoveryPasswordMode" class="row form-group">
        <div class="col-md-12 mt-2">
          <label>Password</label>
        </div>
        <div class="col-md-12 position-relative">
          <div class="position-relative">
            <input #password="ngModel" (keyup.enter)="onSubmit(authForm)"
                   [class.is-invalid]="password.invalid && password.touched"
                   class="form-control bg-img-none"
                   name="password" ngModel required type="{{isPasswordVisible}}"/>
            <ion-icon (click)="changeIsPasswordVisible()" *ngIf='isPasswordVisible === "password"'
                      name="eye"></ion-icon>
            <ion-icon (click)="changeIsPasswordVisible()" *ngIf='isPasswordVisible === "text"'
                      name="eye-slash"></ion-icon>
          </div>
          <div *ngIf="password.errors && (password.invalid || password.touched)">
            <small *ngIf="password.errors.required" class="text-danger">Password richiesta</small>
          </div>
        </div>
      </div>
      <div *ngIf="!isRecoveryPasswordMode" class="row mt-3 justify-content-center">
        <div class="col-sm-6">
          <ion-button [disabled]="authForm.invalid || isLoading" class="ion-btn-primary" expand="block"
                      type="submit">
            <span *ngIf="!isLoading">Accedi</span>
            <fit-spinner-light *ngIf="isLoading"></fit-spinner-light>
          </ion-button>
        </div>
      </div>
      <div (click)="setRecoveryPasswordMode(true)" *ngIf="!isRecoveryPasswordMode" @fadeIn
           class="text-center mt-3 size-18 pointer">
        Ho dimenticato la password
      </div>
      <ion-button (click)="onPasswordForgot(authForm)" *ngIf="isRecoveryPasswordMode" @fadeIn
                  [disabled]="email.invalid || isLoading" class="ion-btn-primary mt-3" expand="block"
                  type="button">
        <span *ngIf="!isLoading" @fadeIn>Manda email di recupero password</span>
        <fit-spinner-light *ngIf="isLoading" @fadeIn></fit-spinner-light>
      </ion-button>
      <div class="w-100 d-flex justify-content-center">
        <ion-button (click)="setRecoveryPasswordMode(false)" *ngIf="isRecoveryPasswordMode" @fadeIn
                    class="ion-btn-light mt-3">
          Annulla
        </ion-button>
      </div>
    </form>
  </div>

  <div class="text-center mt-2">
    <em>Se sei nuovo registrati</em>
  </div>
  <div class="row mt-2 g-0">
    <div class="col-md-12">
      <ion-button (click)="onLoginChangeRegister()" class="ion-btn-secondary" expand="block">
        Registrati
      </ion-button>
    </div>
  </div>
</div>
