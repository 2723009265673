export const LANG = {
  "it": {
    "default": {
      "success": "Successo",
      "danger": "Qualcosa è andato storto"
    },
    "communication": {
      "edit": {
        "success": "Dati modificati con successo",
        "danger": "Errore nel salvataggio dei dati",
      },
      "delete": {
        "success": "Eliminazione avvenuta con successo",
        "warning": "Eliminazione annullata",
      },
      "reservation": {
        "success": "Lezione prenotata con successo",
      },
      "fitter": {
        "success": "Benvenuto fra i Fitter!",
      },
      "operator": {
        "success": "Benvenuto fra gli operatori!",
      },
      "welfare": {
        "found_saved_data": "Dati precedentemente salvati trovati, continua l'inserimento",
        "saved_data": "Dati inseriti salvati",
      },
    },
    "alert": {
      "yes_text": "Si",
      "no_text": "No",
      "fitter_lesson": {
        "delete": {
          "header": "Sei sicuro di voler eliminare la prenotazione a questa lezione ?",
        },
      },
      "lesson": {
        "delete": {
          "header": "Sei sicuro di voler eliminare la lezione ",
          "subHeader": "Attenzione, hai dei fitter iscritti alla lezione, se decidi di cancellare comunque la lezione verrà mandata in automatico una e-mail a tutti i fitter iscritti, puoi modificare il contentuto dell'e-mail qui in basso",
          "yes_button": "Si, elimina solo questa lezione",
          "yes_secondary_button": "Si, elimina anche tutte quelle di ",
        },
      },
      "profile-image": {
        "delete": {
          "header": "Sei sicuro di voler eliminare la foto profilo",
        },
      },
      "reservation": {
        "success": "Lezione prenotata con successo",
      },
      "fitter": {
        "success": "Benvenuto fra i Fitter!",
      },
      "operator": {
        "success": "Benvenuto fra gli operatori!",
      },
    },
    "time": {
      "from": " dalle ",
      "to": " alle "
    },
    "generic": {
      "of": " di ",
      "at": " presso "
    }
  },
  "en": {
    "default": {
      "success": "Success",
      "danger": "Something went wrong"
    },
  }
}
