import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {SearchFilterPipe} from './search-filter.pipe';
import {StripHtmlPipe} from "@app/pipes/striphtml.pipe";
import {SafePipe} from './safe.pipe';
import {KwDatePipe} from "@app/pipes/kw-date.pipe";
import {KeyPipe} from "@app/pipes/key.pipe";
import {PositiveNumberPipe} from "@app/pipes/positive_number.pipe";

@NgModule({
  declarations: [
    SearchFilterPipe,
    StripHtmlPipe,
    SafePipe,
    KwDatePipe,
    KeyPipe,
    PositiveNumberPipe
  ],
  providers: [],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    StripHtmlPipe,
    SafePipe,
    KwDatePipe,
    KeyPipe,
    PositiveNumberPipe
  ]
})
export class PipeModule {
}
