<ion-header>
  <ion-toolbar>
    <ion-title class="text-start">
      <img [routerLink]="navigationRoute.navigate( 'home')" alt="FitAdvisor - Il portale dello sportivo"
           class="img-fluid pointer logo-fitadvisor ms-3 ion-hide-sm-down"
           itemprop="logo"
           src="assets/img/logo/logo.png"
      />
      <img [routerLink]="navigationRoute.navigate( 'home')" alt="FitAdvisor - Il portale dello sportivo"
           class="img-fluid pointer logo-fitadvisor ms-3 ion-hide-sm-up"
           itemprop="logo"
           src="assets/img/logo/logo_mobile.png"
      />
    </ion-title>
    <ion-buttons *ngIf="!isAuthenticated && router.url !== '/registrazione-operatore'" @fadeIn slot="end">
      <ion-icon (click)="setSearchIsOpen(true)" *ngIf="this.router.url !== '/'" class="fit-icon-header me-3"
                name="magnifying-glass"></ion-icon>
      <ion-button (click)="showModalLogin()" class="text-white bold montserrat p-1 ion-bg-primary rounded">
        Accedi
      </ion-button>
      <ion-button (click)="showModalSignup()"
                  class="text-white mx-4 bold montserrat p-1 ion-bg-secondary rounded">
        Iscriviti
      </ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="isAuthenticated" @fadeIn slot="end">
      <!--<ion-icon name="message" id="popover-chat-button" class="fit-icon-header me-2"></ion-icon>
      <ion-popover trigger="popover-chat-button" side="bottom" alignment="end" [dismissOnSelect]="true">
        <ng-template>
          <ion-content>
            <ion-list>
              <ion-item-sliding>
                <ion-item>
                  <ion-label>
                    <h2>HubStruck Notifications</h2>
                    <p>A new message in your network</p>
                  </ion-label>
                </ion-item>

                <ion-item-options side="start">
                  <ion-item-option>
                    <ion-icon slot="icon-only" name="heart"></ion-icon>
                  </ion-item-option>
                </ion-item-options>

                <ion-item-options side="end">
                  <ion-item-option color="danger">
                    <ion-icon slot="icon-only" name="trash"></ion-icon>
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
              <ion-item-sliding>
                <ion-item>
                  <ion-label>
                    <h2>HubStruck Notifications</h2>
                    <p>A new message in your network</p>
                  </ion-label>
                </ion-item>

                <ion-item-options side="start">
                  <ion-item-option>
                    <ion-icon slot="icon-only" name="heart"></ion-icon>
                  </ion-item-option>
                </ion-item-options>

                <ion-item-options side="end">
                  <ion-item-option color="danger">
                    <ion-icon slot="icon-only" name="trash"></ion-icon>
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            </ion-list>
          </ion-content>
        </ng-template>
      </ion-popover>-->
      <div [class.unread-notification-dot]="isUnreadNotificationAvaiable">
        <ion-icon class="fit-icon-header ms-1" id="popover-notification-button" name="bell"></ion-icon>
      </div>
      <ion-popover (willDismiss)="setNotificationModalOpen(false)" (willPresent)="setNotificationModalOpen(true)"
                   [isOpen]="isNotificationModal"
                   alignment="end" class="notification-popover"
                   side="bottom" trigger="popover-notification-button">
        <ng-template>
          <ion-content class="p-3">
            <h6 class="fit-card">
              {{'HEADER.NOTIFICATION.TITLE' | translate}}
            </h6>
            <div *ngIf="notificationArray?.length > 0">
              <ion-item-sliding #slidingItem *ngFor="let notification of notificationArray"
                                class="fit-rounded">
                <ion-item-options
                  (ionSwipe)="swipeNotification(notification, $event.detail.side, slidingItem)"
                  side="start">
                  <ion-item-option (click)="swipeNotification(notification, 'start', slidingItem)"
                                   *ngIf="!notification.readed" @fadeIn
                                   [title]="'HEADER.NOTIFICATION.UNREAD_BUTTON.TITLE' | translate"
                                   expandable="true">
                    <ion-icon name="envelope-open" slot="start">
                    </ion-icon>
                  </ion-item-option>
                  <ion-item-option (click)="swipeNotification(notification,'start', slidingItem)"
                                   *ngIf="notification.readed" @fadeIn
                                   [title]="'HEADER.NOTIFICATION.READ_BUTTON.TITLE' | translate"
                                   expandable="true">
                    <ion-icon name="envelope-closed" slot="start">
                    </ion-icon>
                  </ion-item-option>
                </ion-item-options>

                <ion-item (click)="openNotification(notification)"
                          [ngClass]="{'read-notification-bg' : notification.readed}" class="m-0 pointer fit-rounded"
                          lines="inset">
                  <ion-label
                    [ngClass]="{'opacity-75': notification.readed, 'bold': !notification.readed}">
                    {{notification.subject}}
                  </ion-label>
                </ion-item>

                <ion-item-options
                  (ionSwipe)="swipeNotification(notification, $event.detail.side, slidingItem)"
                  [title]="'HEADER.NOTIFICATION.ARCHIVE_BUTTON.TITLE' | translate"
                  side="end">
                  <ion-item-option (click)="swipeNotification(notification, 'end', slidingItem)"
                                   color="success"
                                   expandable="true">
                    <ion-icon name="archive" slot="start"></ion-icon>
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            </div>
            <div *ngIf="notificationArray?.length < 1">
              <ion-item class="m-0 pointer fit-rounded" lines="inset">
                <ion-label class="ion-text-wrap">
                  {{'HEADER.NOTIFICATION.EMPTY' | translate}}
                </ion-label>
              </ion-item>
            </div>
          </ion-content>
        </ng-template>
      </ion-popover>
      <ion-icon (click)="setSearchIsOpen(true)" *ngIf="this.router.url !== '/'" class="fit-icon-header me-3"
                name="magnifying-glass"></ion-icon>
      <div class="vertical-hr"></div>
      <ion-icon class="fit-icon-header-menu" id="menu-popover" name="menu"></ion-icon>

      <ion-popover (willPresent)="ngOnInit()" [dismissOnSelect]="true" [keepContentsMounted]="true"
                   class="menu-popover" trigger="menu-popover">
        <ng-template>
          <ion-content>
            <ion-list *ngIf="userData">
              <ion-item [button]="false" [detail]="false" class="mb-2" lines="none">
                <ion-avatar slot="start">
                  <img *ngIf="userImage" @fadeIn [src]="userImage" alt="Immagine di profilo">
                  <img *ngIf="!userImage" @fadeIn alt="" src="assets/img/default/user.webp">
                </ion-avatar>
                <ion-label class="ion-text-wrap">{{userData.name}}</ion-label>
              </ion-item>
              <ion-item [button]="true" [detail]="false" [routerLink]="['/']" lines="none">
                <ion-icon class="fit-icon-header" name="house" slot="start"></ion-icon>
                Home
              </ion-item>
              <ion-item [button]="true" [detail]="false" [routerLink]="['/profilo/fitter/myprofile']"
                        lines="none" routerLinkActive="active-item">
                <ion-icon class="fit-icon-header" name="user" slot="start"></ion-icon>
                Profilo personale
              </ion-item>
              <ion-item *ngIf="userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_ADMIN'"
                        [button]="true"
                        [detail]="false" [routerLink]="['/staff']" lines="none"
                        routerLinkActive="active-item">
                <ion-icon class="fit-icon-header" name="toolbox" slot="start"></ion-icon>
                Pannello staff
              </ion-item>
              <ion-item
                *ngIf="userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_ADMIN' || userRole=== 'ROLE_OPERATOR'"
                [button]="true" [detail]="false" [routerLink]="['/pannello-operatore/myprofile']"
                lines="none"
                routerLinkActive="active-item">
                <ion-icon class="fit-icon-header" name="chart" slot="start"></ion-icon>
                Pannello operatore
              </ion-item>
              <ion-item (click)="onLogout()" [button]="true" detail="false" lines="none">
                <ion-icon class="fit-icon-header" name="log-out" slot="start"></ion-icon>
                Esci
              </ion-item>
            </ion-list>
            <div *ngIf="!userData" class="p-1">
              <ngx-skeleton-loader
                [theme]="{ height: '56px', margin: 0}"
                class="fit-rounded">
              </ngx-skeleton-loader>
              <ngx-skeleton-loader
                [theme]="{ height: '48px', margin: 0 }"
                class="fit-rounded">
              </ngx-skeleton-loader>
              <ngx-skeleton-loader
                [theme]="{ height: '48px',  margin: 0 }"
                class="fit-rounded">
              </ngx-skeleton-loader>
              <ion-item (click)="onLogout()" [button]="true" class="fit-rounded" detail="false"
                        lines="none">
                <ion-icon class="fit-icon-header" name="log-out" slot="start"></ion-icon>
                Esci
              </ion-item>
            </div>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-buttons>
    <!--
          <ion-buttons slot="end" course="ion-hide-md-up">
            <ion-menu-toggle>
              <i course="fa-solid fa-bars fa-xl me-3"></i>
            </ion-menu-toggle>
          </ion-buttons>
    -->
  </ion-toolbar>
</ion-header>

<ion-modal (willDismiss)="setSearchIsOpen(false)" [isOpen]="isSearchIsOpen" cssClass="search-bar" mode="ios">
  <ng-template>
    <div class="inner-content">
      <div class="modal-body m-3 mx-md-0 mt-md-4">
        <div class="d-flex justify-content-end">
          <ion-button (click)="setSearchIsOpen(false)" class="no-padding-button" color="light" fill="clear">
            <ion-icon name="xmark"></ion-icon>
          </ion-button>
        </div>
        <sport-searchbar-component></sport-searchbar-component>
      </div>
    </div>
  </ng-template>
</ion-modal>
