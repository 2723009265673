const IT_defaultMessage = "Qualcosa è andato storto";
const EN_defaultMessage = "Something went wrong";

export const ERROR_TEXT = {
  "it": {
    "default": IT_defaultMessage,
    "invalid-credentials": "Email o password errate",
    "Invalid credentials.": "Email o password errate",
    "EXPIRED_JWT_TOKEN": "Sessione scaduta, inserisci le tue credenziali",
    "NOT_LOGGED_IN": "Per favore inserisci le tue credenziali",
    "USERNAME_EXIST": "Questo username è già in uso, scegline un'altro",
    "EMAIL_EXIST": "Questa email è già stata utilizzata",
    "MALFORMED_EMAIL": "L'email inserita non sembra essere corretta",
    "SPORT_FACILITY_NOT_FOUND": "Impianto sportivo non trovato",
    "SPORT_CLUB_NOT_FOUND": "Club o società sportiva non trovata",
    "NOT_AUTHORIZED": "Non sei autorizzato",
    "FILE_NOT_SUPPORTED": "Formato del file non supportato",
    "FILE_TOO_HEAVY": "File troppo pesante, usare una foto che pesi meno di 2MB",
    "USER_DENIED_GEOLOCATION": "Geo localizzazione non attiva",
    "FITTER_ALREADY_EXIST": "Sei già un Fitter, contatta l'assistenza",
    "OPERATOR_ALREADY_EXIST": "Sei già un Operatore, contatta l'assistenza",
    "LESSON_TIME_ERROR": "L'ora o il giorno di inizio lezione non può essere dopo l'orario o il giorno di fine",
    "CLASSROOM_NOT_FOUND": "Corso non trovato, riprova",
    "SPORT_FACILITY_SPORT_NOT_FOUND": "Attività non trovata",
    "LESSON_TIME_CONFLICT": "C'è già una lezione in corso a quest'ora, seleziona un altro orario o un altro corso",
    "MUST_LOGGED_IN": "Prima devi accedere o registrarti",
    "NOT_FITTER": "Prima completa il tuo profilo fitter",
    "NOT_OPERATOR": "Prima completa il tuo profilo operatore",
    "INVALID_URL": "Pagina non esistente o rimossa",
    "NO_VALID_TARGET_EMAIL": "Impossibile inoltrare la richiesta, l'attività non ha un contatto",
    "PASSWORD_MISMATCH": "Password e password confermata non coincidono",
    "RESET_TOKEN_EXPIRED": "Il link per reimpostare la password è scaduto, richiedine un altro",
    "USER_NOT_FOUND": "Utente non trovato",
    "RECAPTCHA_ERROR": "Errore nel validare i dati, contattare l'assistenza",
    "ACCOUNT_NOT_CONFIRMED": "Devi attivare l'account confermando prima l'email, controlla la tua casella di posta",
    "ACCOUNT_DELETED": "Account eliminato, contatta l'assistenza",
    "EMAIL_NOT_FOUND": "E-mail non trovata, contatta l'assistenza",
    "LESSON_FULL": "Posti massimi già raggiunti",
    "LESSON_ALREADY_RESERVED": "Lezione già prenotata",
    "NOTHING_TO_DELETE": "Niente da eliminare"
  },
  "en": {
    "default": EN_defaultMessage,
    "invalid-credentials": "Invalid credentials",
    "expired-jwt-token": "Please login again"
  }
}
