import {Injectable, PLATFORM_ID} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {ERROR_TEXT} from "@app/models/error.model";
import {isPlatformServer} from "@angular/common";
import {LANG} from "@app/Lang/lang";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {
  }

  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: 'success',
      mode: 'ios',
      cssClass: 'toast-vertical-adjust',
      buttons: [
        {
          side: 'start',
          icon: 'circle-check',
        }, {
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async toast(type: string, outcome: string, duration: number = 2000) {
    let lang = 'EN'
    if (!isPlatformServer(PLATFORM_ID)) {
      lang = window.navigator.language.slice(0, 2)
    }
    let msg = LANG[lang]['communication'][type][outcome];

    let icon: string;
    let color: string;
    switch (outcome) {
      case "success": {
        icon = "circle-check";
        color = 'success';
        break;
      }
      case "warning": {
        icon = "circle-exclamation";
        color = 'warning';
        break;
      }
      case "danger": {
        icon = "circle-check";
        color = 'danger';
        break;
      }
      default: {
        icon = "close";
        color = 'primary';
        break;
      }
    }
    const toast = await this.toastController.create({
      message: msg,
      duration: duration,
      position: 'top',
      color: color,
      mode: 'ios',
      cssClass: 'toast-vertical-adjust',
      buttons: [
        {
          side: 'start',
          icon: icon,
        }, {
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async successLongToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 4000,
      position: 'top',
      color: 'success',
      mode: 'ios',
      cssClass: 'toast-vertical-adjust',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark',
        }, {
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async warnToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: 'warning',
      mode: 'ios',
      cssClass: 'toast-vertical-adjust',
      buttons: [
        {
          side: 'start',
          icon: 'warning-outline',
        }, {
          role: 'cancel',
          icon: 'warning-outline'
        }
      ]
    });
    await toast.present();
  }

  async errorToast(error) {
    const toast = await this.toastController.create({
      message: error,
      duration: 4000,
      position: 'top',
      color: 'danger',
      mode: 'ios',
      cssClass: 'toast-vertical-adjust',
      buttons: [
        {
          side: 'start',
          icon: 'close',
        }, {
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async primaryToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: 'primary',
      mode: 'ios',
      cssClass: 'toast-vertical-adjust',
    });
    await toast.present();
  }

  async customErrorToast(text, color) {
    let lang = 'EN'
    if (!isPlatformServer(PLATFORM_ID)) {
      lang = window.navigator.language.slice(0, 2)
    }
    let msg = ERROR_TEXT[lang][text];
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: color,
      mode: 'ios',
      cssClass: 'toast-vertical-adjust',
      buttons: [
        {
          side: 'start',
          icon: 'warning-outline',
        }, {
          role: 'cancel',
          icon: 'warning-outline'
        }
      ]
    });
    await toast.present();
  }

  async notify(msg: string, color: string, position: 'top' | 'bottom' | 'middle' = 'top', duration: number = 2000) {

    let icon: string;
    switch (color) {
      case "success": {
        icon = "circle-check";
        color = 'success';
        break;
      }
      case "warning": {
        icon = "circle-exclamation";
        color = 'warning';
        break;
      }
      case "danger": {
        icon = "circle-check";
        color = 'danger';
        break;
      }
      default: {
        icon = "close";
        color = 'primary';
        break;
      }
    }
    const toast = await this.toastController.create({
      message: this.translateService.instant(msg),
      duration: duration,
      translucent: true,
      position: position,
      color: color,
      mode: 'ios',
      cssClass: 'toast-vertical-adjust',
      buttons: [
        {
          side: 'start',
          icon: icon,
        }, {
          icon: 'close',
          role: 'cancel'
        }
      ]
    });

    await toast.present();
  }

}
