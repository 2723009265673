import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FitSpinnerDarkComponent} from "@app/components/fit-loading/fit-spinner-dark.component";
import {FitLoadingComponent} from "@app/components/fit-loading/fit-loading.component";
import {FitSpinnerLightComponent} from "@app/components/fit-loading/fit-spinner-light.component";
import {FitSpinnerPrimaryComponent} from "@app/components/fit-loading/fit-spinner-primary.component";
import {HeaderComponent} from "@app/components/header/header.component";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {SideMenuTriggerComponent} from "@app/components/side-menu-trigger/side-menu-trigger.component";
import {LottieModule} from "ngx-lottie";
import {FitLottieComponent} from "@app/components/fit-lottie/fit-lottie.component";
import {CheckUpWidgetComponent} from "@app/components/check-up-widget/check-up-widget.component";
import {SportCardComponent} from "@app/components/sport-card/sport-card.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AddressComponent} from "@app/components/address/address.component";
import {GoogleMapsModule} from "@angular/google-maps";
import {TurboFunctionComponent} from "@app/components/turbo-function/turbo-function.component";
import {
  ChooseActiveSportClubComponent
} from "@app/components/choose-active-sport-club/choose-active-sport-club.component";
import {RouterModule} from '@angular/router';
import {NgSelectModule} from "@ng-select/ng-select";
import {AngularSplitModule} from "angular-split";
import {PartnerComponent} from "@app/components/banner/partner/partner.component";
import {FooterComponent} from "@app/components/footer/footer.component";
import {WidePartnerComponent} from "@app/components/banner/wide-partner/wide-partner.component";
import {SquarePartnerComponent} from "@app/components/banner/square-partner/square-partner.component";
import {RecommendationHomeComponent} from "@app/components/recommendation-home/recommendation-home.component";
import {NewsHomeComponent} from "@app/components/news-home/news-home.component";
import {DirectivesModule} from "@app/directives/directives.module";
import {PipeModule} from "@app/pipes/pipe.module";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ShoeRatingComponent} from "@app/components/shoe-rating/shoe-rating.component";
import {ReviewAverageComponent} from "@app/components/review-average/review-average.component";
import {ShareComponent} from "@app/components/share/share.component";
import {SportSearchbarComponent} from "@app/components/sport-searchbar/sport-searchbar.component";
import {FitLoadingCustomComponent} from "@app/components/fit-loading/fit-loading-custom.component";
import {FileUploadComponent} from "@app/components/file-upload/file-upload.component";
import {ImageCropperModule} from "ngx-image-cropper";
import {GalleryComponent} from "@app/components/gallery/gallery.component";
import {PromoComponent} from "@app/components/promo/promo.component";
import {CtaBtnComponent} from "@app/components/cta-btn/cta-btn.component";
import {FormInputComponent} from "@app/components/form-input/form-input.component";
import {SignatureComponent} from "@app/components/signature/signature.component";
import {FitterModalComponent} from "@app/components/fitter-modal/fitter-modal.component";
import {OperatorModalComponent} from "@app/components/operator-modal/operator-modal.component";
import {NewsletterFormComponent} from "@app/components/newsletter-form/newsletter-form.component";
import {TranslateModule} from "@ngx-translate/core";
import {RequestNotificationComponent} from "@app/components/request-notification/request-notification.component";
import {RequestPositionComponent} from "@app/components/request-position/request-position.component";
import {NotificationModalComponent} from "@app/components/notification-modal/notification-modal.component";
import {GetAppModalComponent} from "@app/components/get-app-modal/get-app-modal.component";
import {FirstAccessMobileComponent} from "@app/components/first-access-mobile/first-access-mobile.component";
import {PagesPageModule} from "@app/pages/pages.module";
import {LoginComponent} from "@app/pages/auth/login/login.component";
import {SignupComponent} from "@app/pages/auth/signup/signup.component";
import {StripeComponent} from "@app/components/stripe/stripe.component";
import {StripePaymentElementComponent} from "ngx-stripe";
import {DiscoverPlannerComponent} from "@app/components/discover-planner/discover-planner.component";
import {ChooseUserTypeModalComponent} from "@app/components/choose-user-type-modal/choose-user-type-modal.component";
import {TutorialModalComponent} from "@app/components/tutorial-modal/tutorial-modal.component";

@NgModule({
  declarations: [
    FitSpinnerDarkComponent,
    FitSpinnerLightComponent,
    FitLoadingComponent,
    FitSpinnerPrimaryComponent,
    FitLoadingComponent,
    HeaderComponent,
    SideMenuTriggerComponent,
    FitLottieComponent,
    CheckUpWidgetComponent,
    SportCardComponent,
    AddressComponent,
    TurboFunctionComponent,
    ChooseActiveSportClubComponent,
    PartnerComponent,
    FooterComponent,
    WidePartnerComponent,
    SquarePartnerComponent,
    RecommendationHomeComponent,
    NewsHomeComponent,
    ShoeRatingComponent,
    ReviewAverageComponent,
    ShareComponent,
    SportSearchbarComponent,
    FitLoadingCustomComponent,
    FileUploadComponent,
    GalleryComponent,
    PromoComponent,
    CtaBtnComponent,
    FormInputComponent,
    SignatureComponent,
    FitterModalComponent,
    OperatorModalComponent,
    NewsletterFormComponent,
    RequestNotificationComponent,
    RequestPositionComponent,
    NotificationModalComponent,
    GetAppModalComponent,
    FirstAccessMobileComponent,
    LoginComponent, SignupComponent,
    StripeComponent,
    DiscoverPlannerComponent,
    ChooseUserTypeModalComponent,
    TutorialModalComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    IonicModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    LottieModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    FormsModule,
    NgSelectModule,
    AngularSplitModule,
    DirectivesModule,
    PipeModule,
    NgbModule,
    ImageCropperModule,
    TranslateModule,
    PagesPageModule,
    StripePaymentElementComponent
  ],
  exports: [
    FitSpinnerDarkComponent,
    FitSpinnerLightComponent,
    FitSpinnerPrimaryComponent,
    FitLoadingComponent,
    HeaderComponent,
    SideMenuTriggerComponent,
    FitLottieComponent,
    CheckUpWidgetComponent,
    SportCardComponent,
    AddressComponent,
    TurboFunctionComponent,
    ChooseActiveSportClubComponent,
    PartnerComponent,
    FooterComponent,
    WidePartnerComponent,
    SquarePartnerComponent,
    RecommendationHomeComponent,
    NewsHomeComponent,
    ShoeRatingComponent,
    ReviewAverageComponent,
    ShareComponent,
    SportSearchbarComponent,
    FitLoadingCustomComponent,
    FileUploadComponent,
    GalleryComponent,
    PromoComponent,
    CtaBtnComponent,
    FormInputComponent,
    SignatureComponent,
    FitterModalComponent,
    OperatorModalComponent,
    NewsletterFormComponent,
    RequestNotificationComponent,
    RequestPositionComponent,
    NotificationModalComponent,
    GetAppModalComponent,
    FirstAccessMobileComponent,
    LoginComponent, SignupComponent,
    StripeComponent,
    DiscoverPlannerComponent,
    ChooseUserTypeModalComponent,
    TutorialModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {
}
