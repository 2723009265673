<div class="inner-content p-3">
  <div class="d-flex justify-content-center gap-3 flex-column mh-100">
    <img alt="" class="align-self-center" src="assets/img/request/push_position.svg" width="400">
    <div class="mx-4">
      <h1 class="m-0 bold "> {{'PERMITS_REQUEST.POSITION.TITLE' | translate}} </h1>
    </div>
    <ion-button (click)="accept()" class="ion-btn-primary m-4 mb-0" expand="block">
      {{'PERMITS_REQUEST.POSITION.ACCEPT' | translate}}
    </ion-button>
    <small class="opacity-50 text-center">{{'PERMITS_REQUEST.POSITION.ADVISE' | translate}}</small>
  </div>
</div>
