import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "@app/guards/auth.guard";

export const routes: Routes = [

  {
    path: 'home',
    redirectTo: '',
    pathMatch: "full"
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'profilo/operatore/:operator_id',
    loadChildren: () => import('./pages/sport-club/sport-club.module').then(m => m.SportClubPageModule)
  },
  {
    path: 'pannello-operatore/myprofile',
    loadChildren: () => import('./operator-panel/operator-panel.module').then(m => m.OperatorPanelPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'profilo/fitter/myprofile',
    loadChildren: () => import('./pages/auth/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },*/
  {
    path: 'profilo/fitter/myprofile',
    loadChildren: () => import('./fitter-panel/fitter-panel.module').then(m => m.FitterPanelPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'impianti/:id/:slug',
    loadChildren: () => import('./pages/sport-facility/sport-facility.module').then(m => m.SportFacilityPageModule)
  },
  {
    path: 'impianti/:slug/:id/:name',
    loadChildren: () => import('./pages/sport/sport.module').then(m => m.SportPageModule)
  },
  {
    path: 'blog/:slug',
    loadChildren: () => import('./pages/blog/single-article/single-article.module').then(m => m.SingleArticlePageModule)
  },
  {
    path: 'blog/:type/:slug',
    loadChildren: () => import('./pages/blog/search-article/search-article.module').then(m => m.SearchArticlePageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/search-article/search-article.module').then(m => m.SearchArticlePageModule)
  },
  {
    path: 'ricerca/sport',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'ricerca/sport/:place',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'ricerca/:sport',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'profilo/fitter/:id',
    loadChildren: () => import('./pages/fitter/fitter.module').then(m => m.FitterPageModule)
  },
  {
    path: 'promozioni',
    loadChildren: () => import('./pages/all-promo/all-promo.module').then(m => m.AllPromoPageModule)
  },
  {
    path: 'check-up',
    children: [
      {
        path: '',
        loadChildren: () => import('./outer-pages/check-up/check-up.module').then(m => m.CheckUpPageModule),
      },
      {
        path: 'domande',
        loadChildren: () => import('./outer-pages/check-up/check-up-1st-page/check-up-questions.module').then(m => m.CheckUpQuestionsPageModule)
      },
      {
        path: 'importanti',
        loadChildren: () => import('./outer-pages/check-up/check-up-2nd-page/check-up-ending.module').then(m => m.CheckUpEndingPageModule)
      },
      {
        path: 'fine',
        loadChildren: () => import('./outer-pages/check-up/check-up-3rd-page/check-up-payment.module').then(m => m.CheckUpPaymentPageModule)
      }
    ]
  },
  {
    path: 'info/chi-siamo',
    loadChildren: () => import('./pages/info/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'info/media',
    loadChildren: () => import('./pages/info/media/media.module').then(m => m.MediaPageModule)
  },
  {
    path: 'info/i-nostri-partner',
    loadChildren: () => import('./pages/info/partner/partner.module').then(m => m.PartnerPageModule)
  },
  {
    path: 'info/note-legali',
    loadChildren: () => import('./pages/info/legal-therms/legal-therms.module').then(m => m.LegalThermsPageModule)
  },
  {
    path: 'info/press-kit',
    loadChildren: () => import('./pages/info/presskit/presskit.module').then(m => m.PresskitPageModule)
  },
  {
    path: 'grazie',
    loadChildren: () => import('./outer-pages/utils/thank-you/thank-you.module').then(m => m.ThankYouPageModule)
  },
  {
    path: 'ricerca-per-sport',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/search-sport/search-sport.module').then(m => m.SearchSportPageModule)
      },
      {
        path: ':state',
        loadChildren: () => import('./pages/search-sport/step-one/step-one.module').then(m => m.StepOnePageModule)
      },
      {
        path: ':sport/:state',
        loadChildren: () => import('./pages/search-sport/step-two/step-two.module').then(m => m.StepTwoPageModule)
      },
    ],
  },
  {
    path: 'ricerca-per-obiettivo',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/search-goal/search-goal.module').then(m => m.SearchGoalPageModule)
      },
      {
        path: ':state',
        loadChildren: () => import('./pages/search-goal/goal-step-one/goal-step-one.module').then(m => m.GoalStepOnePageModule)
      },
      {
        path: ':goal/:state',
        loadChildren: () => import('./pages/search-goal/goal-step-two/goal-step-two.module').then(m => m.GoalStepTwoPageModule)
      }
    ]
  },
  {
    path: 'ricerca-per-categoria-sportiva',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/search-category/search-category.module').then(m => m.SearchCategoryPageModule)
      },
      {
        path: ':state',
        loadChildren: () => import('./pages/search-category/category-step-one/category-step-one.module').then(m => m.CategoryStepOnePageModule)
      },
      {
        path: ':category/:state',
        loadChildren: () => import('./pages/search-category/category-step-two/category-step-two.module').then(m => m.CategoryStepTwoPageModule)
      }
    ]
  },
  {
    path: 'cerca/sport',
    pathMatch: "prefix",
    redirectTo: 'ricerca-per-sport'
  },
  {
    path: 'cerca/obiettivo',
    pathMatch: "prefix",
    redirectTo: 'ricerca-per-obiettivo'
  },
  {
    path: 'cerca/categoria-sportiva',
    pathMatch: "prefix",
    redirectTo: 'ricerca-per-categoria-sportiva'
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./outer-pages/utils/password-reset/password-reset.module').then(m => m.PasswordResetPageModule)
  },
  {
    path: 'benvenuto',
    loadChildren: () => import('@app/pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'staff',
    loadChildren: () => import('./admin/staff.module').then(m => m.StaffPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'errore',
    loadChildren: () => import('./outer-pages/utils/error/error-page.module').then(m => m.ErrorPagePageModule)
  },
  {
    path: 'registrazione-operatore',
    loadChildren: () => import('./outer-pages/operator-landing/operator-landing.module').then(m => m.OperatorLandingPageModule)
  },
  {
    path: 'site-checkup',
    loadChildren: () => import('./outer-pages/site-checkup/site-checkup.module').then(m => m.SiteCheckupPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./outer-pages/utils/error/error-page.module').then(m => m.ErrorPagePageModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', initialNavigation: 'enabledNonBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
