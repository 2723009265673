import {GOOGLE_KEY} from "@environments/environment";

interface Scripts {
  name: string;
  src: string;
}


export const ScriptStore: Scripts[] = [
  /*{name: 'lordicon', src: 'https://cdn.lordicon.com/fudrjiwc.js'},*/
  {
    name: 'paypal',
    src: 'https://www.paypal.com/sdk/js?client-id=AQnTuGZaI2YRROJx3ozcSB9m_Rx_ijsHW0CwGzc3563GMSbo_EabtVXHiO4Rat2n4-5aKaLKV817pQHa'
  },
  /*{
    name: 'tag_manager',
    src: 'https://www.googletagmanager.com/ns.html?id=GTM-TNR887&ngsw-bypass=true'
  },*/
  {
    name: 'lottie',
    src: 'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js'
  },
  {
    name: 'maps',
    src: 'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_KEY + '&libraries=places&callback=Function.prototype'
  },
  {
    name: 'tag_manager_script',
    src: '../assets/js/tag_manager.js'
  },
];
