import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {API_URL} from "@environments/environment";
import {map} from "rxjs/operators";
import {GlobalService} from "@app/services/global.service";
import {SfsModel} from "@app/models/sfs.model";
import {RangeValue} from "@ionic/core";
import {MostRecModel} from "@app/pages/home/home.page";

@Injectable({
  providedIn: 'root'
})
export class SportFacilitySportService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  getSportFacilitySport(id: number): Observable<SfsModel> {
    return this.http.get<any>(API_URL + '/sportfacilitysport/' + id).pipe(map((data: SfsModel) => {
      return data;
    }));
  }

  getMySFS(not_mine: boolean = false): Observable<SfsModel[]> {
    return this.http.get<any>(API_URL + '/get_my_sfs', this.globalService.setTokenAndParams({
      not_mine
    })).pipe(map((data: SfsModel[]) => {
      return data;
    }));
  }

  saveSportFacilitySport(sportFacilitySportId: number, body: Record<any, any>): Observable<any> {
    return this.http.post<any>(API_URL + '/sportfacilitysport/' + sportFacilitySportId, {
      body
    }).pipe(map(data => {
      return data;
    }));
  }

  deleteSportFacilitySport(sportFacilitySportId: number): Observable<any> {
    return this.http.delete<any>(API_URL + '/sportfacilitysport/' + sportFacilitySportId, this.globalService.setToken());
  }

  sfsSearch(max_results?: number, page?: number, property?: string, direction?: string, promo?: boolean, lat?: number, lng?: number, radius?: RangeValue, sport_array?: number[], sport_facility_array?: number[], sport_club_array?: number[], recommendation_id ?: number): Observable<any> {
    let sort = '[{"property":"' + property + '", "direction":"' + direction + '"}]'
    return this.http.get(API_URL + '/sportfacilitysport', this.globalService.getParams({
      max_results,
      page,
      sort,
      sport_array,
      promo,
      lat,
      lng,
      radius,
      sport_facility_array,
      sport_club_array,
      recommendation_id
    })).pipe(map(data => {
      return data;
    }));
  }

  nearSFS(sport_array: number, max_results?: number, page?: number, property?: string, direction?: string, lat?: number, lng?: number): Observable<SfsModel[]> {
    let sort = '[{"property":"' + property + '", "direction":"' + direction + '"}]'
    return this.http.get(API_URL + '/sportfacilitysport', this.globalService.getParams({
      max_results,
      page,
      sort,
      sport_array,
      lat,
      lng
    })).pipe(map(data => {
      return data['elenco'];
    }));
  }


  /* USED IN HOMEPAGE */
  getTopSFS(limit, property = 'rank_position', direction = 'ASC'): Observable<SfsModel[]> {
    let sort = '[{"property":"' + property + '", "direction":"' + direction + '"}]'
    return this.http.get(API_URL + '/top_SFS', this.globalService.getParams({
      limit,
      sort
    })).pipe(map((data: SfsModel[]) => {
      return data;
    }));
  }

  /* USED IN HOMEPAGE */
  mostRecSFS(lat?, lng?, limit?, rec_id?, radius?): Observable<MostRecModel[]> {
    return this.http.get(API_URL + '/most_recommended_SFS', this.globalService.getParams({
      lat,
      lng,
      limit,
      rec_id,
      radius
    })).pipe(map((data: MostRecModel[]) => {
      return data;
    }));
  }

  getAllCityWhereSFS(): Observable<any> {
    return this.http.get(API_URL + '/state_where_sport_facility_sport').pipe(map((data: String[]) => {
      return data;
    }));
  }

  getAllSportWhereState(state: string, sport?: string, city?: number): Observable<SfsModel[] | string[]> {
    return this.http.get(API_URL + '/sport_by_state/' + state, this.globalService.getParams({
      sport,
      city
    })).pipe(map((data: SfsModel[] | string[]) => {
      return data;
    }));
  }

  getAllSportWhereStateGoal(state: string, goal?: string, city?: number): Observable<SfsModel[] | string[]> {
    return this.http.get(API_URL + '/sport_by_state_goal/' + state, this.globalService.getParams({
      goal,
      city
    })).pipe(map((data: SfsModel[] | string[]) => {
      return data;
    }));
  }

  getAllSportWhereStateCategory(state: string, sport?: string, city?: number): Observable<SfsModel[] | string[]> {
    return this.http.get(API_URL + '/sport_by_state_category/' + state, this.globalService.getParams({
      sport,
      city
    })).pipe(map((data: SfsModel[] | string[]) => {
      return data;
    }));
  }

  getAllCityWhereRecommendedSFS(): Observable<any> {
    return this.http.get(API_URL + '/state_where_recommended').pipe(map((data) => {
      return data;
    }));
  }

}
