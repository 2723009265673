import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {TurboFunctionComponent} from "@app/components/turbo-function/turbo-function.component";

@Component({
  selector: 'app-discover-planner',
  templateUrl: './discover-planner.component.html',
  styleUrls: ['./discover-planner.component.scss'],
})
export class DiscoverPlannerComponent implements OnInit {


  imageArray = [
    {
      id: 1,
      image: 'assets/img/planner/1.png'
    },
    {
      id: 2,
      image: 'assets/img/planner/2.png'
    },
    {
      id: 3,
      image: 'assets/img/planner/3.png'
    }
  ]

  constructor(
    public modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
  }

  showTurboFunctionModal() {
    this.modalCtrl.dismiss().then(async () => {
      const modal = await this.modalCtrl.create({
        component: TurboFunctionComponent,
        canDismiss: true,
        mode: "ios",
        cssClass: 'auto-height',
        componentProps: {
          /*
                  sportClubData: this.spor
          */
        }
      });

      modal.onDidDismiss().then(() => {
      });

      return modal.present();
    })
  }

}
