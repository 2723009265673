import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CommunicationService} from "@app/helper/communication.service";
import {Subscription} from "rxjs";
import {AuthService} from "@app/services/auth.service";
import {UserService} from "@app/services/user.service";
import {SportClubModel} from "@app/models/sport-club.model";
import {PaymentService} from "@app/services/payment.service";
import {fadeInAnimation} from "@app/animations";
import {format} from "date-fns";
import {StripePaymentElementComponent, StripeService} from "ngx-stripe";
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-payment',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
  animations: [fadeInAnimation]
})
export class StripeComponent implements OnInit {

  @Input() sportClubData: SportClubModel;
  @Input() isBuyingTurbo: boolean = false;
  @Input() itemForSale: any;

  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;

  /* USER */
  isAuthenticated: boolean;
  userSub: Subscription;
  public userInfo: any;

  public selectedTurboPackage: turboPackageModel;

  public turboPackageData = [];

  stripePaymentForm: FormGroup

  /* TABS */
  public segment: string = 'subscription';
  public isPaymentAdviseOn: boolean = Boolean(localStorage.getItem('isPaymentAdviseOn'));
  public isPaymentLoading: boolean = false;
  public clientSecret: string;

  //PAYPAL
  public isLoading: boolean = false;
  protected readonly JSON = JSON;

  constructor(
    private modalCtrl: ModalController,
    private communicationService: CommunicationService,
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private stripeService: StripeService,
    private reCaptchaV3Service: ReCaptchaV3Service,
  ) {
    if (this.isBuyingTurbo) {
      this.stripePaymentForm = this.formBuilder.group({
        name: [null, Validators.required],
        email: [null, [Validators.compose([Validators.required, Validators.email])]],
        sport_club_name: [null, Validators.required],
        vat_number: ['', Validators.required],
        address: ['', Validators.required],
        proprietor: ['', Validators.required],
        item: ['', Validators.required]
      })
    } else {
      this.stripePaymentForm = this.formBuilder.group({
        name: [null, Validators.required],
        email: ['damokop235@jybra.com', [Validators.compose([Validators.required, Validators.email])]],
        address: ['', Validators.required],
        item: ['', Validators.required]
      })
    }
  }

  ngOnInit() {

    if (this.isBuyingTurbo) {

      this.paymentService.getAllTurboPackage().subscribe(turboPackageArrayElenco => {
        const turboPackageArray: turboPackageModel[] = turboPackageArrayElenco['elenco']
        turboPackageArray.forEach(turboPackage => {
          if (turboPackage.promo_expiration) {
            const date = format(new Date(), 'dd/MM/yyyy');
            const expiration_date = format(new Date(turboPackage.promo_expiration), 'dd/MM/yyyy')

            if (expiration_date >= date) {
              this.turboPackageData.push(turboPackage)
            }
          } else {
            this.turboPackageData.push(turboPackage)
          }

          this.turboPackageData = [...this.turboPackageData]
        })
      })

    }

    if (this.authService.checkIfUserLoggedIn) {
      this.userService.getUserData().subscribe({
        next: user => {
          this.stripePaymentForm.patchValue({
            name: user.name,
            email: user.email
          })
        }
      })
    }

    if (this.itemForSale) {
      this.stripePaymentForm.patchValue({
        item: JSON.stringify(this.itemForSale)
      })
      this.paymentService.createPaymentIntent(this.itemForSale.value, Number((this.itemForSale.price * 100).toFixed(1))).subscribe({
        next: paymentIntent => {
          this.clientSecret = paymentIntent.client_secret
        }
      })
    }

    let sportClub = JSON.parse(localStorage.getItem('sportClub'))
    if (!sportClub) {
      sportClub = this.sportClubData
    }
    if (sportClub) {
      this.stripePaymentForm.get('sport_club_name').setValue(sportClub.name)
      this.stripePaymentForm.get('vat_number').setValue(sportClub.vat_number)
      this.stripePaymentForm.get('sportClubProprietor').setValue(sportClub.proprietor)
      if (sportClub?.cap) {
        this.stripePaymentForm.get('sportClubAddress').setValue(sportClub.address + ' ' + sportClub.civic_number + ', ' + sportClub.cap + ' ' + sportClub.city_name + ' ' + sportClub.state_code)
      } else {
        this.stripePaymentForm.get('sportClubAddress').setValue(sportClub.address)
      }
    }
  }

  pay() {
    this.reCaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        if (this.stripePaymentForm.valid) {
          this.isLoading = true;
          this.stripeService.confirmPayment({
            elements: this.paymentElement.elements,
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  name: this.stripePaymentForm.get('name').value,
                  email: this.stripePaymentForm.get('email').value,
                  address: {
                    line1: this.stripePaymentForm.get('address').value || ''
                  }
                }
              }
            },
            redirect: 'if_required'
          }).subscribe(result => {
            this.isLoading = false;
            if (result.error) {
              this.communicationService.notify('COMMUNICATION.PAYMENT.ERROR', 'danger').then(() => {
                this.paymentService.onStipePayment(
                  token,
                  this.itemForSale.value,
                  this.itemForSale.price,
                  false,
                  String(result.paymentIntent.payment_method),
                  this.stripePaymentForm.get('name').value,
                  this.stripePaymentForm.get('email').value,
                  this.stripePaymentForm.get('address').value
                ).subscribe()
              })
            } else {
              this.communicationService.notify('COMMUNICATION.PAYMENT.SUCCESS', 'success').then(() => {
                this.paymentService.onStipePayment(
                  token,
                  this.itemForSale.value,
                  this.itemForSale.price,
                  true,
                  String(result.paymentIntent.payment_method),
                  this.stripePaymentForm.get('name').value,
                  this.stripePaymentForm.get('email').value,
                  this.stripePaymentForm.get('address').value,
                  result.paymentIntent.id
                ).subscribe()
                this.modalCtrl.dismiss().then();
              })
            }
          });
        } else {
          this.communicationService.notify('COMMUNICATION.FORM.ERROR', 'danger').then()
        }
      });
  }

  choosePackage(object: string) {
    this.itemForSale = JSON.parse(object)
    this.paymentService.createPaymentIntent(this.itemForSale.name, Number((this.itemForSale.price * 100).toFixed(1))).subscribe({
      next: paymentIntent => {
        this.clientSecret = paymentIntent.client_secret
      }
    })
  }

  /*onModalDismiss() {
    /!*if (this.selectedTurboPackage) {
      localStorage.setItem('selectedTurboPackage', JSON.stringify(this.selectedTurboPackage))
    }
    localStorage.setItem('stripePaymentForm', JSON.stringify(this.stripePaymentForm.value))
    this.paymentService.savePaymentData(this.selectedTurboPackage, false)*!/
    /!*this.paymentService.onStipePayment(
      token,
      this.itemForSale.name,
      this.itemForSale.price,
      false,
      null,
      this.stripePaymentForm.get('name').value,
      this.stripePaymentForm.get('email').value,
      this.stripePaymentForm.get('address').value,
      result.paymentIntent.id
    ).subscribe()
    this.modalCtrl.dismiss().then(() => {
      this.communicationService.warnToast('Ordine annullato').then()
    })*!/
  }*/

  cancelPayment() {
    this.reCaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        this.communicationService.notify('COMMUNICATION.PAYMENT.ERROR', 'danger').then(() => {
          this.paymentService.onStipePayment(
            token,
            this.itemForSale ? this.itemForSale.value : null,
            this.itemForSale ? this.itemForSale.price : null,
            false,
            null,
            this.stripePaymentForm.get('name') ? this.stripePaymentForm.get('name').value : null,
            this.stripePaymentForm.get('email') ? this.stripePaymentForm.get('email').value : null,
            this.stripePaymentForm.get('address') ? this.stripePaymentForm.get('address').value : null
          ).subscribe()
        })
      });
    this.modalCtrl.dismiss().then();
  }
}

export interface turboPackageModel {
  id: number,
  name: string,
  label: string,
  price: number,
  plan_id: string,
  promo_expiration: string
}
