import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {API_URL} from "@environments/environment";
import {GlobalService} from "@app/services/global.service";
import {ActivityFitterModel, ClubFollowerModel, FitterModel} from "@app/models/fitter.model";

@Injectable({
  providedIn: 'root'
})
export class FitterService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  getFitterData(id: number): Observable<FitterModel> {
    return this.http.get(API_URL + '/fitter/' + id).pipe(map((data: FitterModel) => {
      return data;
    }));
  }

  getFitterDataByUser(id: number): Observable<FitterModel> {
    return this.http.get(API_URL + '/fitter_by_user/' + id).pipe(map((data: FitterModel) => {
      return data;
    }));
  }

  getFollowerBySportClub(id: number): Observable<ClubFollowerModel[]> {
    return this.http.get(API_URL + '/club_follower_by_sport_club/' + id).pipe(map((data: ClubFollowerModel[]) => {
      return data;
    }));
  }

  saveFollowSportClub(sport_club_id: number): Observable<any> {
    return this.http.post(API_URL + '/club_follower/' + sport_club_id, {}, this.globalService.setToken()).pipe(map((data: any) => {
      return data;
    }));
  }

  removeFollowSportClub(sport_club_id: number): Observable<any> {
    return this.http.delete<any>(API_URL + '/club_follower/' + sport_club_id, this.globalService.setToken()).pipe(map(res => {
      return res;
    }));
  }

  getActivityByFitter(id: number): Observable<ActivityFitterModel> {
    return this.http.get(API_URL + '/fitter_activity/' + id).pipe(map((data: ActivityFitterModel) => {
      return data;
    }));
  }

  saveFitter(id: number, body): Observable<ActivityFitterModel> {
    if (id > 0) {
      return this.http.put(API_URL + '/fitter/' + id, {
        body
      }, this.globalService.setToken()).pipe(map((data: ActivityFitterModel) => {
        return data;
      }));
    } else {
      return this.http.post(API_URL + '/fitter/' + id, {
        body
      }, this.globalService.setToken()).pipe(map((data: ActivityFitterModel) => {
        return data;
      }));
    }
  }

  modifyFollowerSportClub(id: number, body: ClubFollowerModel): Observable<any> {
    return this.http.put(API_URL + '/edit_club_follower/' + id, {
      body
    }, this.globalService.setToken()).pipe(map((data: any) => {
      return data;
    }));
  }

}
