import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {Promo_sportFacilitySportModel} from "@app/models/promo_sport-facility-sport.model";

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  getAllPromoTemplate() {
    return this.http.get(API_URL + '/promo_template').pipe(map(data => {
      return data['elenco'];
    }));
  }

  getPromoBySportFacilitySport(id: number) {
    return this.http.get(API_URL + '/promo_sfs_by_sport_facility_sport/' + id, this.globalService.setToken()).pipe(map(data => {
      return data;
    }));
  }

  savePromoSportFacilityPromo(body, id: number) {
    if (id > 0) {
      return this.http.put(API_URL + '/promo_sfs/' + id, {
        body
      }, this.globalService.setToken()).pipe(map(data => {
        return data;
      }));
    } else {
      return this.http.post(API_URL + '/promo_sfs/' + id, {
        body
      }, this.globalService.setToken()).pipe(map(data => {
        return data;
      }));
    }
  }

  getLatestPromo(lat, lng, limit, page): Observable<Promo_sportFacilitySportModel[]> {
    /*const sort = '[{"property": "id", "direction": "DESC"}]';*/
    return this.http.get(API_URL + '/promo_sfs', this.globalService.getParams({
      limit, page, lat, lng
    })).pipe(map((data: { totale: number, elenco: Promo_sportFacilitySportModel[], max_page: number }) => {
      return data.elenco;
    }));
  }

  getAllPromo(sort, limit, page): Observable<{
    totale: number,
    elenco: Promo_sportFacilitySportModel[],
    max_page: number
  }> {
    return this.http.get(API_URL + '/promo_sfs', this.globalService.getParams({
      sort, limit, page
    })).pipe(map((data: { totale: number, elenco: Promo_sportFacilitySportModel[], max_page: number }) => {
      return data;
    }));
  }


  deletePromo(id: number): Observable<any> {
    return this.http.delete<any>(API_URL + '/promo_sfs/' + id, this.globalService.setToken()).pipe(map(res => {
      return res;
    }));
  }

}
