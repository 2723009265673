import {Pipe, PipeTransform} from '@angular/core';
import {KeyValue} from "@angular/common";

@Pipe({
  name: 'key',
  pure: false
})
export class KeyPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): any {
    let keys = [];
    for (let key in value) {
      keys.push({key: key, value: value[key]});
    }
    Object.keys(value)
    return keys;
  }


}
