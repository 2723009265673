<!--<ion-header>
  <ion-toolbar class="h-auto" color="primary">
    <ion-buttons slot="end">
      <ion-button slot="icon" (click)="onModalDismiss()">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="montserrat text-white">
      Inserisci i dati della tua società
    </ion-title>
  </ion-toolbar>
</ion-header>-->
<div class="inner-content">
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button (click)="cancelPayment()" class="ion-btn-tertiary fit-shadow" size="small">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="modal-body p-3">

    <p class="fw-light m-0">
      Acquista
    </p>
    <h4 class="fs-4 fw-bold my-2">
      {{ itemForSale.name }}
    </h4>
    <p class="fw-light m-0 mb-4">
      {{ JSON.parse(stripePaymentForm.get('item').value).price }} € IVA inclusa
    </p>

    <form [formGroup]="stripePaymentForm" novalidate>
      <form-input [form]="stripePaymentForm" [name]="'FORM.OPERATOR.NAME.NAME' | translate"
                  [placeHolder]="'FORM.OPERATOR.NAME.PLACEHOLDER' | translate"
                  formInputName="name" required="true"></form-input>

      <form-input [form]="stripePaymentForm" [name]="'FORM.OPERATOR.EMAIL.NAME' | translate"
                  [placeHolder]="'FORM.OPERATOR.EMAIL.PLACEHOLDER' | translate"
                  formInputName="email" required="true"></form-input>

      <form-input *ngIf="isBuyingTurbo" [form]="stripePaymentForm" [name]="'FORM.SPORT_CLUB.NAME.NAME' | translate"
                  [placeHolder]="'FORM.SPORT_CLUB.NAME.PLACEHOLDER' | translate"
                  formInputName="sport_club_name" required="true"></form-input>

      <form-input *ngIf="isBuyingTurbo" [form]="stripePaymentForm" [name]="'FORM.SPORT_CLUB.VAT.NAME' | translate"
                  [placeHolder]="'FORM.SPORT_CLUB.VAT.PLACEHOLDER' | translate"
                  formInputName="vat_number" required="true"></form-input>

      <form-input [form]="stripePaymentForm" [name]="'FORM.ADDRESS.NAME' | translate"
                  [placeHolder]="'FORM.ADDRESS.PLACEHOLDER' | translate"
                  formInputName="address"
                  (emittData)="stripePaymentForm.get('address').setValue($event.formatted_address)"
                  inputType="address" required="true"></form-input>

      <form-input *ngIf="isBuyingTurbo" [form]="stripePaymentForm"
                  [name]="'FORM.SPORT_CLUB.PROPRIETOR.NAME' | translate"
                  [placeHolder]="'FORM.SPORT_CLUB.PROPRIETOR.PLACEHOLDER' | translate"
                  formInputName="proprietor" required="true"></form-input>

      <form-input (emittData)="choosePackage($event)" *ngIf="isBuyingTurbo" [form]="stripePaymentForm"
                  [name]="'FORM.SPORT_CLUB.TURBO_PACKAGE.NAME' | translate"
                  [placeHolder]="'FORM.SPORT_CLUB.TURBO_PACKAGE.PLACEHOLDER' | translate"
                  [radioOptions]="turboPackageData"
                  formInputName="item"
                  inputType="select" required="true"></form-input>

      <div *ngIf="clientSecret" @fadeIn class="pb-3">
        <h5 class="mb-2 mt-3">
          {{ 'FORM.PAYMENT_H5' | translate }}
        </h5>
        <ngx-stripe-payment [clientSecret]="clientSecret">
        </ngx-stripe-payment>
      </div>

      <div class="row mt-3 centered-middle">
        <div class="col-md order-0 order-md-1">
          <ion-button (click)="pay()" [disabled]="stripePaymentForm?.invalid" class="ion-btn-primary" expand="block">
            <span *ngIf="!isLoading">
            {{ 'FORM.BUTTONS.PAY' | translate }}
            </span>
            <fit-spinner-light *ngIf="isLoading"></fit-spinner-light>
          </ion-button>
        </div>
        <div class="col-md order-1 order-md-0">
          <ion-button (click)="cancelPayment()" class="ion-btn-light" expand="block">
            {{ 'FORM.BUTTONS.CANCEL' | translate }}
          </ion-button>
        </div>
      </div>
    </form>
  </div>
</div>
