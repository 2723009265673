import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PagesPage} from "@app/pages/pages.page";


const routes: Routes = [
  {
    path: '',
    component: PagesPage,
  },


];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesPageRoutingModule {
}
