<swiper-container fitSwiper [config]="config">
  <swiper-slide *ngFor="let homepageNews of homepageNewsArray">
    <div class="d-flex justify-content-center w-100">
      <a [routerLink]="'/blog/' + homepageNews.slug">
        <img [alt]="homepageNews?._embedded['wp:featuredmedia']['0'].title.rendered"
             [src]="homepageNews?._embedded['wp:featuredmedia']['0'].source_url"
             class="img-fluid aspect-ratio-1 object-fit-cover"
             data-toggle="tooltip" height="300" title="Immagine articolo"
             width="300">
      </a>

    </div>

    <div class="my-2 text-start">
      <a [routerLink]="'/blog/' + homepageNews.slug" class="text-dark">
        <h3 class="bold size-18 mb-3 mt-4" data-toggle="tooltip"
            title="Titolo articolo">{{homepageNews.title.rendered | stripHtml}}</h3>
      </a>
      <div class="col-md-auto gap-2 d-flex my-2">
        <ion-chip class="m-0 px-3" color="primary" data-toggle="tooltip" title="Data pubblicazione articolo">
          <ion-icon name="calendar-days"></ion-icon>
          <ion-label>{{homepageNews.date | date: 'dd.MM.yyyy'}}</ion-label>
        </ion-chip>
        <ion-chip class="m-0 px-3 ion-bg-secondary-tint" color="secondary" data-toggle="tooltip"
                  title="Autore articolo"
                  [routerLink]="'/blog/a/' + homepageNews?._embedded['author']['0'].slug ">
          <ion-icon name="pen-nib"></ion-icon>
          <ion-label>{{homepageNews?._embedded['author']['0'].name}}</ion-label>
        </ion-chip>
      </div>
      <p class="size-16 max-five-row-text mb-2 mt-3" data-toggle="tooltip" title="Anteprima articolo">
        {{homepageNews.content.rendered | stripHtml}}
      </p>
      <div class="d-flex justify-content-center">
        <ion-button class="mt-3" color="secondary" data-toggle="tooltip" fill="outline" title="Leggi l'articolo"
                    [routerLink]="'/blog/' + homepageNews.slug" rel="follow">
          Continua a leggere
        </ion-button>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
