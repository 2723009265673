<ion-header>
  <ion-toolbar class="h-auto" color="primary">
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss()" slot="icon">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="montserrat text-white text-wrap">
      Scopri il nuovo planner lezioni
    </ion-title>
  </ion-toolbar>
</ion-header>
<div class="inner-content">
  <div class="modal-body py-3 px-4">

    <h6 class="text-wide text-uppercase mt-0 mb-4 text-center fw-bold">
      Caratteristiche del nuovo calendario:
    </h6>

    <div class="centered-start my-2">
      <ion-icon class="me-2 ion-color-primary" name="chevron-right"></ion-icon>
      <p class="m-0">
        Migliorata veste grafica
      </p>
    </div>
    <div class="centered-start my-2">
      <ion-icon class="me-2 ion-color-primary" name="chevron-right"></ion-icon>
      <p class="m-0">
        Possibilità di cambiare tra diverse viste: "agenda", "settimana", "mese" ecc.
      </p>
    </div>
    <div class="centered-start my-2">
      <ion-icon class="me-2 ion-color-primary" name="chevron-right"></ion-icon>
      <p class="m-0">
        Interfaccia semplificata e migliorata da mobile
      </p>
    </div>
    <div class="row mt-4 g-0 gap-3">
      <img alt="" class="fit-shadow" src="assets/img/planner/1.png">
      <img alt="" class="fit-shadow" src="assets/img/planner/3.png">
    </div>
    <img alt="" class="fit-shadow my-3" src="assets/img/planner/2.png">
  </div>
</div>
<ion-footer class="bg-white p-3">
  <div class="row g-0 gap-3 w-100 justify-content-center align-items-center ">
    <div class="col-md-auto">
      <ion-button (click)="showTurboFunctionModal()"
                  @fadeIn class="ion-btn-secondary only-turbo-button" expand="block"
                  title="Attiva il turbo per poter sbloccare la prenotazione dei corsi"
                  type="button">
        <ion-icon class="me-2" name="rocket"></ion-icon>
        Ottienilo con
        <label class="ion-hide-sm-down">turbo</label>
      </ion-button>
    </div>
    <div class="col-md-auto">
      <ion-button (click)="showTurboFunctionModal()"
                  @fadeIn class="ion-btn-tertiary" expand="block"
                  type="button">
        Ottieni solo il calendario
      </ion-button>
    </div>
  </div>
</ion-footer>
