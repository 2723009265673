import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {fadeInAnimation} from "@app/animations";
import {CommunicationService} from "@app/helper/communication.service";
import {Capacitor} from "@capacitor/core";
import {Clipboard} from '@capacitor/clipboard';

@Component({
  selector: 'app-share',
  template: `
    <div class="d-flex ion-bg-primary flex-column h-100 p-5">
      <span data-toggle="tooltip" title="Chiudi"
            class="position-absolute pointer top-0 end-0 ion-color-primary-contrast no-padding-button aspect-ratio-1 m-3 m-md-5"
            (click)="modalController.dismiss()">
        <ion-icon name="xmark" class="icons"></ion-icon>
      </span>
      <div class="my-auto text-center">
        <h1 class="bold display-4 mb-5 ion-color-primary-contrast">
          {{title}}
        </h1>
        <div class="d-flex gap-3 gap-md-5 justify-content-center align-items-center flex-wrap">
          <a class="ion-color-primary-contrast pointer icons"
             (click)="copyLinkToClipBoard()" data-toggle="tooltip" title="Copia link">
            <ion-icon name="link" class="icons" @fadeIn *ngIf="!linkCopied"></ion-icon>
            <ion-icon name="check" class="icons" @fadeIn *ngIf="linkCopied"></ion-icon>
          </a>
          <a class="ion-color-primary-contrast" target="_blank" rel="nofollow" data-toggle="tooltip"
             title="Condividi su Facebook"
             href="https://www.facebook.com/sharer/sharer.php?u={{link}}">
            <ion-icon name="facebook" class="icons"></ion-icon>
          </a>
          <a class="ion-color-primary-contrast" target="_blank" rel="nofollow" data-toggle="tooltip"
             title="Condividi su Twitter"
             href="https://twitter.com/intent/tweet?url={{link}}&text={{textLink}}">
            <ion-icon name="twitter" class="icons"></ion-icon>
          </a>
          <a class="ion-color-primary-contrast" target="_blank" rel="nofollow" data-toggle="tooltip"
             title="Condividi su Linkedin"
             href="https://www.linkedin.com/shareArticle?mini=true&url={{link}}">
            <ion-icon name="linkedin" class="icons"></ion-icon>
          </a>
          <a class="ion-color-primary-contrast" target="_blank" rel="nofollow" data-toggle="tooltip"
             title="Condividi su Whatsapp"
             [href]="'https://api.whatsapp.com/send?text=' + textLink + ' ' + link">
            <ion-icon name="whatsapp" class="icons"></ion-icon>
          </a>
          <a class="ion-color-primary-contrast" target="_blank" rel="nofollow" data-toggle="tooltip"
             title="Condividi su Telegram"
             href="https://t.me/share/url?text={{textLink}}&url={{link}}">
            <ion-icon name="telegram" class="icons"></ion-icon>
          </a>
          <a class="ion-color-primary-contrast" target="_blank" rel="nofollow" data-toggle="tooltip"
             title="Condividi per e-mail"
             href="mailto:?subject={{textLink}}&body={{textLink}}%20visitabile%20al%20link:%20{{link}}">
            <ion-icon name="envelope" class="icons"></ion-icon>
          </a>
        </div>
      </div>
    </div>
  `,
  styles: [`
    a:hover {
      color: #e5e5e5 !important;
    }

    .icons {
      font-size: 5rem;
      z-index: 999 !important;
    }

    @media (max-width: 767px) {
      .icons {
        font-size: 3rem;
        z-index: 999 !important;
      }
    }


  `],
  animations: [fadeInAnimation]
})

export class ShareComponent implements OnInit {

  @Input() textLink: string
  @Input() title: string
  @Input() link: string;

  public linkCopied: boolean = false;
  protected readonly location = location;

  constructor(
    public modalController: ModalController,
    private communicationService: CommunicationService
  ) {
    if (!this.title) {
      this.title = 'Condividi questa pagina';
    }
    if (!this.link) {
      this.link = 'https://fitadvisor.it' + location.pathname;
    }
    if (!this.textLink) {
      this.textLink = 'Guarda%20questa%20pagina%20che%20ho%20trovato%20su%20Fitadvisor.it';
    }
  }

  ngOnInit() {
  }

  copyLinkToClipBoard() {
    Capacitor.getPlatform()
    if (Capacitor.getPlatform() === 'web') {
      navigator.clipboard.writeText(this.link).then(() => {
        this.linkCopied = true;
        this.communicationService.successToast('Link della pagina copiato negli appunti').then(() => {
          setInterval(() => {
            this.linkCopied = false;
          }, 2000);
        })
      })
    } else {
      Clipboard.write({
        string: this.link
      }).then(() => {
        this.communicationService.successToast('Link della pagina copiato negli appunti').then(() => {
          setInterval(() => {
            this.linkCopied = false;
          }, 2000);
        })
      });
    }
  }
}
