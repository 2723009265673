import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {NotificationService} from "@app/services/notification.service";

@Component({
  selector: 'request-notification',
  templateUrl: './request-notification.component.html',
  styleUrls: ['./request-notification.component.scss'],
})
export class RequestNotificationComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  dismissModal() {
    this.modalController.dismiss().then()
    localStorage.setItem('notificationAccepted', String(false));
  }

  accept() {
    localStorage.setItem('notificationAccepted', String(true));
    this.modalController.dismiss().then()
  }
}
