import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FitSwiperDirective} from "@app/directives/fit-swiper.directive";

@NgModule({
  declarations: [
    FitSwiperDirective
  ],
  providers: [],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    FitSwiperDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DirectivesModule {
}
