import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {API_URL} from "@environments/environment";
import {GlobalService} from "@app/services/global.service";
import {OperatorModel} from "@app/models/operator.model";
import {OperatorSportFacility} from "@app/services/operator-panel.service";

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  getOperator(query ?: string, sort?, group?, limit?, page?): Observable<{ elenco: OperatorModel[], totale: number }> {
    return this.http.get<any>(API_URL + '/operator', this.globalService.setTokenAndParams({
      query,
      sort,
      group,
      limit,
      page
    })).pipe(map((data: { elenco: OperatorModel[], totale: number }) => {
      return data;
    }));
  }

  getOperatorData(): Observable<OperatorModel> {
    return this.http.get<any>(API_URL + '/operator_data', this.globalService.setToken()).pipe(map(data => {
      return data;
    }));
  }

  saveOperator(id: number = 0, body: OperatorModel): Observable<OperatorModel> {
    if (id > 0) {
      return this.http.put(API_URL + '/operator/' + id, {
        body
      }, this.globalService.setToken()).pipe(map((data: OperatorModel) => {
        return data;
      }));
    } else {
      return this.http.post(API_URL + '/operator/' + id, {
        body
      }, this.globalService.setToken()).pipe(map((data: OperatorModel) => {
        return data;
      }));
    }
  }

  getOperatorIdBySportClub(id: number): Observable<any> {
    return this.http.get<any>(API_URL + '/operator_by_sport_club/' + id).pipe(map((id: number) => {
      return id;
    }));
  }

  getOperatorSportFacility(query ?: string, sort?, group?, limit?, page?): Observable<{
    elenco: OperatorSportFacility[],
    totale: number
  }> {
    return this.http.get<any>(API_URL + '/operator_sport_facility', this.globalService.setTokenAndParams({
      query,
      sort,
      group,
      limit,
      page
    })).pipe(map((data: { elenco: OperatorSportFacility[], totale: number }) => {
      return data;
    }));
  }

  saveOperatorSportFacility(id, body): Observable<any> {
    if (id > 0) {
      return this.http.put<any>(API_URL + '/operator_sport_facility/' + id, {body}, this.globalService.setToken()).pipe(map(res => {
        return res;
      }));
    } else {
      return this.http.post<any>(API_URL + '/operator_sport_facility/' + id, {body}, this.globalService.setToken()).pipe(map(res => {
        return res;
      }));
    }
  }

  setOperatorTurbo(turbo_id, payment_id, email, res_token): Observable<any> {
    const body = {
      turbo_id,
      payment_id,
      email,
      res_token
    }
    return this.http.put<any>(API_URL + '/set_operator_turbo', {body}).pipe(map(res => {
      return res;
    }));
  }

  landingOperatorRegistration(body: any, rec_token: string): Observable<any> {
    return this.http.post<any>(API_URL + '/landing_operator', {body, rec_token}).pipe(map(res => {
      return res;
    }));
  }

}
