export class UserModel {

  id: number;
  email: string;
  token: string;
  name: string;
  role: string;
  user: string;
  refresh_token: string;
  staff_id: number;
  active: boolean;
  confirmed: boolean;
  show_username: boolean;
  roles: string;
  type_id: number;

  constructor(data: any = []) {
    if (data) {
      for (const key in data) {
        this[key] = data[key] ?? null;
      }
    }
  }
}

