import {Component, ViewEncapsulation} from '@angular/core';
import {ModalController, Platform, ToastController} from '@ionic/angular';
import {ScriptService} from "@app/services/script.service";
import {initializeApp} from 'firebase/app';
import {register} from 'swiper/element/bundle';
import {AuthService} from "@app/services/auth.service";
import {Router} from "@angular/router";
import {fadeInAnimation} from "@app/animations";
import {environment} from "@environments/environment";
import {NotificationService} from "@app/services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {RequestNotificationComponent} from "@app/components/request-notification/request-notification.component";
import {Capacitor} from "@capacitor/core";
import {setDefaultOptions} from 'date-fns'
import {it} from 'date-fns/locale'

import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {PushNotifications} from "@capacitor/push-notifications";
import {FirstAccessMobileComponent} from "@app/components/first-access-mobile/first-access-mobile.component";
import {Stripe} from "@capacitor-community/stripe";
import {PaymentService} from "@app/services/payment.service";

initializeApp(environment.firebase);
register();
setDefaultOptions({locale: it});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInAnimation]
})
export class AppComponent {

  protected readonly localStorage = localStorage;

  public clientSecret: string;

  constructor(
    private platform: Platform,
    private scriptService: ScriptService,
    private authService: AuthService,
    public router: Router,
    private notificationService: NotificationService,
    private modal: ModalController,
    public translateService: TranslateService,
    private toastController: ToastController,
    public paymentService: PaymentService,
  ) {
    this.platform.ready().then(() => {
      this.initializeApp();

    })
  }

  acceptCookie() {
    localStorage.setItem('cookiePreferences', String(true));
    this.scriptService.loadAnalyticsScript();
  }

  acceptPermission() {
    localStorage.setItem('notificationPermissionAsked', String(true));

    if (Notification.permission == 'granted') {

      this.onGrantedNotificationWeb();

    } else if (Notification.permission == 'default') {

      this.translateService.get('COMMUNICATION.REQUEST_DESKTOP.NOTIFICATION').subscribe(async (message: string) => {

          Notification.requestPermission().then(() => {
            if (Notification.permission == 'granted') {
              this.onGrantedNotificationWeb();
            }
          })

        }
      );

    }

  }

  denyCookie() {
    localStorage.setItem('cookiePreferences', String(false));
  }

  onImpersonationStop() {
    localStorage.removeItem('impersonateUser')
    localStorage.removeItem('activeSportClubId')
    this.authService.revertNextUser()
    this.router.navigate(['/']).then();
  }

  initializeApp() {
    this.scriptService.loadBaseScript();
    this.scriptService.loadAnalyticsScript();
    this.chooseLanguage();

    Stripe.initialize({

      publishableKey: environment.stripe.pub_key,

    }).then();

    this.showPopUp().then(() => {

      if (!localStorage.getItem('firstAccess') && Capacitor.isNativePlatform()) {
        this.showLogin().then();
      }

    })


  }

  async openAppMarket() {
    /*const modal = await this.modal.create({
      component: GetAppModalComponent,
      mode: "ios",
      cssClass: 'auto-height'
    });

    modal.onDidDismiss().then()

    await modal.present();*/
  }

  async showLogin() {
    localStorage.setItem('firstAccess', String(false))

    const modal = await this.modal.create({
      component: FirstAccessMobileComponent,
      mode: "ios",
      cssClass: 'auto-height'
    });

    modal.onDidDismiss().then(() => {

    })

    await modal.present();
  }

  /**
   * @description Attiva o richiedi notifiche
   **/
  async showPopUp() {

    if (Capacitor.getPlatform() === 'web') {

      if (Notification.permission == 'granted') {

        localStorage.setItem('notificationPermissionAsked', String(true));
        this.onGrantedNotificationWeb();

      }

    } else {

      PushNotifications.checkPermissions().then(async permStatus => {

        if (permStatus.receive === 'granted') {

          this.onGrantedNotificationMobile();

        } else if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') {

          const modal = await this.modal.create({
            component: RequestNotificationComponent,
            backdropDismiss: false,
            mode: "ios",
            cssClass: 'auto-height'
          });

          modal.onDidDismiss().then((data) => {
            console.log(data.role)
            console.log(data.data)
            /*PushNotifications.requestPermissions().then(result => {
              if (result.receive === 'granted') {
                this.onGrantedNotificationMobile();
              }
            });*/
          })

          await modal.present();

        }
      });

    }

    if (this.platform.platforms().indexOf('capacitor') === -1 && this.platform.platforms().indexOf('desktop') === -1 && !localStorage.getItem('appDownload')) {
      await this.openAppMarket();
    }
  }

  /**
   * @description Se ho le notifiche attivate
   * **/
  onGrantedNotificationWeb() {
    const messaging = getMessaging();

    getToken(messaging, {vapidKey: environment.firebase.vapidKey}).then(
      (currentToken) => {
        if (currentToken) {
          this.notificationService.checkIfUserHasValidToken(currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

    onMessage(messaging, async (payload) => {
      const header: string = payload.notification.title;
      const toast = await this.toastController.create({
        message: header,
        duration: 2000,
        position: 'top',
        color: 'primary',
        mode: 'ios',
        cssClass: 'toast-vertical-adjust',
      });

      toast.onDidDismiss().then(() => {
        this.notificationService.loadNotification();
      })

      await toast.present();
    });
  }

  onGrantedNotificationMobile() {
    PushNotifications.register().then(() => {
      PushNotifications.addListener('registration', token => {
        this.notificationService.checkIfUserHasValidToken(token.value);
      });
      PushNotifications.addListener('pushNotificationReceived', () => {
        this.notificationService.loadNotification();
      });
    });
  }

  /**
   * @description Imposta la lingua di default
   **/
  chooseLanguage() {
    this.translateService.addLangs(['it', 'en']);
    this.translateService.setDefaultLang('it');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/it|en/) ? browserLang : 'it');
  }
}

