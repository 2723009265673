import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fit-loading-custom',
  template: `
    <lottie-player src="{{source}}" style="width:70%;margin:0 auto;" autoplay loop></lottie-player>
    <h6 class="display-6 bold text-white text-center mt-3">
      {{phrase}}
    </h6>`,
  styles: [''],
})
export class FitLoadingCustomComponent implements OnInit {

  @Input() src: string;
  @Input() phrase: string;

  public source: string;

  constructor() {
  }

  ngOnInit() {
    this.source = '/assets/lottie/' + this.src + '.json';
  }
}
